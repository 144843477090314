import { ImCancelCircle } from 'react-icons/im';

import Modal from '../Modal/Modal';
import './CardPrompt.css';
import UserProfilePic from '../UserProfilePic/UserProfilePic';
import DownloadApp from '../DownloadApp/DownloadApp';
import { Dispatch, SetStateAction } from 'react';

const CardPrompt = ({
  setClosePrompt,
  setRestartQuiz,
  score,
}: {
  setClosePrompt: Dispatch<SetStateAction<boolean>>;
  setRestartQuiz: Dispatch<SetStateAction<boolean>>;
  score: number;
}) => {
  // For test mimic logged in user
  const isUser: boolean = false;

  const modelHandle = () => {
    setClosePrompt(false);
    setRestartQuiz(false);
  };
  return (
    <Modal extraFunc={modelHandle}>
      <div className="cardPrompt">
        <div className="cardPrompt__close" onClick={() => modelHandle()}>
          <ImCancelCircle />
        </div>
        <div className="cardPrompt__header">
          <h3>Your score</h3>
        </div>

        <div className="cardPrompt__profile-details">
          <div className="cardPrompt__profile">
            <UserProfilePic />
          </div>

          <p className="cardPrompt__profile-detail__name">
            {isUser ? 'Rose A.' : 'Guest'}
          </p>

          <h4 className="cardPrompt__profile-score">{score}</h4>
        </div>

        <div className="cardPrompt__buttons">
          <DownloadApp />
        </div>
      </div>
    </Modal>
  );
};

export default CardPrompt;
