export const FAQData = [
  {
    title: 'What is MedScroll?',
    body: 'MedScroll is an innovative AI-powered medical education platform designed for the healthcare community. It offers a range of interactive learning tools including Medical Trivia, customizable quizzes, and exciting AI-powered games. MedScroll is crafted to make medical education engaging and effective for students, educators, and professionals. Our platform enhances medical knowledge, clinical skills, and communication while fostering a strong community of learners.',
  },
  {
    title: 'Who can benefit from using MedScroll?',
    body: "MedScroll is suited for medical students, educators, and professionals at any stage of their career. Whether you're looking to refresh your knowledge, prepare for exams, or incorporate engaging content into presentations, MedScroll offers valuable resources.",
  },
  {
    title: 'What makes MedScroll different from other medical education apps?',
    body: 'MedScroll sets itself apart with its customizable quiz platform, comprehensive Medical Trivia covering over 1000 questions, and AI-powered scenarios focused on improving communication and clinical skills.',
  },
  {
    title: 'How do I start a multiplayer game?',
    body: "You can start a multiplayer game by clicking the 'Multiplayer' button on the home screen. From there, you can choose between Friends Mode and share a game code with your friends to invite them to your game.",
  },
  {
    title: 'How many players can join a multiplayer game?',
    body: 'In Friends Mode, you can have between 2 to 10 players. More than 10 players require a subscription.',
  },
  {
    title: 'How long does a typical multiplayer match last?',
    body: 'The duration can vary based on the number of questions, but each match is timed.',
  },
  {
    title: 'How is scoring calculated in multiplayer mode?',
    body: 'Players get 1 point for each correct answer, plus potential speed bonuses. A speed bonus of 2 points is awarded for correctly answering within 8 seconds.',
  },
  {
    title: 'Can I review questions after a multiplayer game?',
    body: 'Yes, after the game, you can review all questions, including missed, incorrect, and correct ones, along with explanations and references.',
  },
  {
    title: 'Can I rematch with the same players?',
    body: 'Yes, after a game, you have the option to play again with the same players.',
  },
  {
    title: 'How do I join a game my friend has created?',
    body: "Use the 'Join game' button on the home screen and enter the game code your friend shared with you.",
  },
  {
    title: 'Are there different question banks for multiplayer mode?',
    body: 'Yes, in Friends Mode, you can choose between your question bank and MedScroll’s question bank.',
  },
  {
    title: 'What is Case Recall?',
    body: 'Case Recall is an interactive medical quiz game designed to enhance medical knowledge and diagnostic skills through gamified case vignettes. Artificial Intelligence (AI) is an advanced feature that uses AI to generate unlimited, personalized clinical scenarios and questions. Unlike the regular game with pre-set scenarios, the AI feature provides a never-ending, dynamic experience tailored to your preferences and skill level. Some scenarios are pre-created and added to the database. Newly AI-created cases are also saved to the database.',
  },
  {
    title: 'How do I access the Case Recall AI-generated content?',
    body: 'AI-generated content becomes available from Level 4 onward. You need to have a paid subscription to access these higher levels and the AI feature.',
  },
  {
    title:
      'Are the AI-generated cases as accurate and realistic as the pre-set scenarios?',
    body: 'The AI-generated cases are designed to be highly accurate and realistic. They are created using a comprehensive medical knowledge base and good engineering prompting to ensure the cases are accurate.',
  },
  {
    title:
      'How does the difficulty progression work with Case Recall AI-generated content?',
    body: 'The complexity of the clinical scenarios increases as the levels progress with many more data points to recall. As you progress, the cases become more challenging.',
  },
  {
    title: 'Can I still track my performance with AI-generated content?',
    body: "Yes, your performance on AI-generated content is tracked similarly to other preset content. You'll see summary scores after each sequence of cases, including correct answers, total points, and reading speed where applicable. You can also review questions to see the answers to questions you answered incorrectly or missed.",
  },
  {
    title: 'How often are new AI-generated cases added?',
    body: 'The AI can generate new cases instantly and indefinitely. The quality of the generated content is dependent on the quality of your prompting. The more specific and precise you are regarding the topic or subtopic, the better the results.',
  },
  {
    title:
      'Can I choose specific medical specialties or topics for the AI-generated cases?',
    body: "Yes, you can input specific prompts for tailored scenarios. If you don't provide a prompt, the AI will generate random scenarios across various specialties.",
  },
  {
    title: 'Is there a limit to how many AI-generated cases I can play?',
    body: "There's no limit. The AI can generate cases indefinitely, providing a never-ending game experience.",
  },
  {
    title: 'Are there any privacy concerns with using the AI feature?',
    body: 'We take privacy seriously. The platform is for educational purposes. Case scenarios are made-up cases and do not represent actual patients. Ensure you have the required permission to upload documents and do not include any identifying patient information. All data is handled securely in accordance with our privacy policy.',
  },
  {
    title: 'What are credits in MedScroll?',
    body: 'Credits are units that measure your usage of AI-powered features on MedScroll, such as AI interactions and advanced quiz creation tools.',
  },
  {
    title: 'How much credit do I get with each plan?',
    body: 'The Starter (Free) plan offers 50,000 credits for a 7-day free trial, the Pro plan provides 200,000 credits per month, and the Premium plan gives 500,000 credits per month.',
  },
  {
    title: 'What happens if I use up all my credit before the month ends?',
    body: "Once you've used all your allocated credit, you'll need to upgrade your plan or purchase additional token bundles to continue using AI-powered features.",
  },
  {
    title: 'Do unused credits roll over to the next month?',
    body: 'No, token allocation is for one month and does not carry over to the next month.',
  },
  {
    title: 'How do I know how much credit I have left?',
    body: 'Your remaining token balance should be visible on your account dashboard.',
  },
  {
    title: 'Can I buy more tokens if I run out?',
    body: 'Yes, you can purchase additional token bundles. Options include 50,000 tokens for $3, 100,000 tokens for $5, and 200,000 tokens for $10.',
  },
  {
    title: 'Can I upgrade or downgrade my plan?',
    body: 'Yes, you can change your plan at any time.',
  },
  {
    title: 'Is there a limit to how many quizzes I can create with AI?',
    body: "There's no specified limit on quiz creation, but your ability to use AI features in quiz creation will depend on your available tokens and subscription plan.",
  },
  {
    title: 'What happens if I need more storage?',
    body: 'The Starter plan includes 1GB, Pro plan 5GB, and Premium plan 10GB of storage. If you need more, you may need to upgrade your plan.',
  },
  {
    title: 'What is the Instant Quiz feature?',
    body: 'Instant Quiz is a feature that allows you to quickly generate a set of up to 10 quiz questions based on your input, such as a prompt, PDF, or link.',
  },
  {
    title: 'How do I access the Instant Quiz feature?',
    body: 'You can access the Instant Quiz feature from your home feed. Click on it to be taken to a chat interface.',
  },
  {
    title: 'How many questions does an Instant Quiz generate?',
    body: 'Each Instant Quiz generates a set of 10 questions. However, you can click ‘Continue’ to keep generating more questions based on the initial prompt or refine your prompt to modify the topic or line of questioning.',
  },
  {
    title: 'Can I use voice commands to create an Instant Quiz?',
    body: 'Yes, you can use the microphone to speak to our AI and create quizzes without typing.',
  },
  {
    title: 'What file type can I upload to create an Instant Quiz?',
    body: 'Currently, the system accepts PDF files for uploads.',
  },
  {
    title: 'Is there a file size limit for uploads?',
    body: 'No, the file size for uploads is unlimited.',
  },
  {
    title: 'Can I review the questions after taking an Instant Quiz?',
    body: "Yes, you can review your quiz by clicking on the 'review question' button.",
  },
  {
    title: 'How can I generate more questions on the same topic?',
    body: "You can click the 'continue' button to generate another set of 10 questions based on your previous input.",
  },
  {
    title: 'What is MedSynopsis?',
    body: 'MedSynopsis is a feature designed to enhance your clinical documentation skills through a series of timed tasks based on realistic clinical scenarios.',
  },
  {
    title: 'How does MedSynopsis work?',
    body: "You'll be presented with case details in various clinical contexts. Then given various tasks that test your clinical synthesis and summarization skills. After completing the timed activity, you will be able to compare your summary with an AI-generated one and receive feedback.",
  },
  {
    title: 'How is my performance evaluated?',
    body: 'Your performance is evaluated across five domains: Accuracy, Clarity and Conciseness, Relevance, Organization and Structure, and Time to Completion.',
  },
  {
    title: 'Are there different difficulty levels?',
    body: 'Yes, scenarios are categorized into minor, moderate, and high complexity levels. You will be provided with a mix of cases rather than progress in difficulty levels.',
  },
  {
    title: 'Can I upload my own case details for practice?',
    body: "Yes, there's a feature to upload documents with case details for the AI to summarize.",
  },
  {
    title: 'How does the scoring system work?',
    body: 'Each of the five domains (Accuracy, Clarity, Relevance, Organization, and Time) is worth 20 points, for a total possible score of 100.',
  },
  {
    title: 'What is the Case Presentation feature?',
    body: 'The Case Presentation feature is a tool designed to help medical professionals improve their case presentation skills. It provides AI-generated feedback on written and verbal presentations.',
  },
  {
    title: 'What types of presentations can I submit for feedback?',
    body: 'You can submit written texts (such as PDFs) and audio recordings of your case presentations.',
  },
  {
    title: 'How does the AI feedback work?',
    body: 'The AI analyzes your presentation and provides feedback on various aspects, including conciseness, organization, clinical detail, relevance, and clarity.',
  },
  {
    title: 'Can I record my presentation directly in the app?',
    body: 'Yes, the app includes audio and video recording functionality, allowing you to record your presentation directly within MedScroll.',
  },
  {
    title: 'Is there a chat interface for interacting with the AI?',
    body: "Yes, there's a chat interface where you can seek feedback, ask questions, and request sample presentations.",
  },
  {
    title: 'How can I access the Case Presentation feature?',
    body: "You can find the Case Presentation feature in the 'Learn with AI' section of the MedScroll platform.",
  },
  {
    title: 'What file formats are supported for uploading presentations?',
    body: 'The platform supports PDFs for written presentations and all audio formats.',
  },
  {
    title: 'Can the AI transcribe my audio presentation?',
    body: 'Yes, the AI can transcribe your uploaded audio presentations to provide textual feedback.',
  },
  {
    title: 'How can I use MedScroll for presentations?',
    body: 'Our quiz platform allows users to create customized interactive quizzes that can be integrated into presentations. This feature not only enhances the learning experience but also engages your audience more effectively.',
  },
  {
    title: 'Is there a cost to using MedScroll?',
    body: 'MedScroll offers both free and premium content. While many features are available for free, certain premium features require a subscription. Please refer to our subscription plan on the mobile app and website for details.',
  },
  {
    title: 'How often is new content added to MedScroll?',
    body: 'We regularly update MedScroll with new questions, quizzes, and features to ensure our users have access to the most current and comprehensive medical content.',
  },
  {
    title: 'Can I share my custom quizzes with others?',
    body: 'Yes, MedScroll encourages collaborative learning. You can share your custom quizzes with peers and engage in a community-driven learning process.',
  },
  {
    title: 'How can I provide feedback or suggest new features for MedScroll?',
    body: 'We welcome your feedback and suggestions to improve MedScroll. Please reach out to us via the contact form on our website or directly within the app.',
  },
  {
    title: 'Is MedScroll available on all devices?',
    body: 'MedScroll is available for download on iOS and Android devices and is also available on the Web, ensuring you can learn and engage wherever you are.',
  },
];
