export const pricingThData = ['Feature', 'Starter', 'Pro', 'Premium'];

export const priceThDataMonthly = ['', 'Free', '$19.99', '$29.99'];

export const priceFeatureDataMonthly: string[][] = [
  ['Medical Trivia', 'unlimited', 'unlimited', 'unlimited'],
  ['General Trivia', 'unlimited', 'unlimited', 'unlimited'],
  ['Multiplayer Capacity', '10 people', '50 people', '100 people'],
  ['Maximum number of Question', '100', 'Unlimited', 'Unlimited'],
  ['Image Upload Per Question', 'N/A', '4', 'Unlimited'],
  ['Storage', '1GB', '5GB', '10GB'],
  ['Case Recall', `Up to level 3`, 'Full Access', 'Full Access'],
  ['MedSynopsis', `Limited  access`, 'Full Access', 'Full Access'],
  ['Case Presentation', `Limited  access`, 'Full Access', 'Full Access'],
  ['Create quiz with AI', `Limited  access`, 'Full Access', 'Full Access'],
  ['Credit', `50,000 credits`, '1,000,000 credits', '2,000,000 credits'],
];

export const priceThDataYearly = ['', 'Free', '$190.99', '$290.99'];

export const priceFeatureDataYearly: string[][] = [
  ['Medical Trivia', 'unlimited', 'unlimited', 'unlimited'],
  ['General Trivia', 'unlimited', 'unlimited', 'unlimited'],
  ['Multiplayer Capacity', '10 people', '50 people', '100 people'],
  ['Maximum number of Question', '1000', 'Unlimited', 'Unlimited'],
  ['Image Upload Per Question', 'N/A', '4', 'Unlimited'],
  ['Storage', '1GB', '5GB', '10GB'],
  ['Case Recall', `Up to level 3`, 'Full Access', 'Full Access'],
  ['MedSynopsis', `Limited  access`, 'Full Access', 'Full Access'],
  ['Case Presentation', `Limited  access`, 'Full Access', 'Full Access'],
  ['Create quiz with AI', `Limited  access`, 'Full Access', 'Full Access'],
  ['Credit', `600,000 credits`, '120,000,000 credits', '240,000,000 credits'],
];
