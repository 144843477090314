import React, { useEffect, useState } from 'react';

import './MultiplerCodeView.css';
import {
  BackArrowSvg,
  dropdownIconSvg,
} from '../../components/Svgs/svgsComponents';
import BackDrop from '../../components/BackDrop/BackDrop';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GetSubcategoryI, SubcategoryRes } from '../../types/GetSubCategory';
import { GET_SUB_CATEGORY } from '../../graphql/queries/getDashboardSubCategory';
import SpinnerLoader from '../../components/SpinnerLoader/SpinnerLoader';
import { toast } from 'react-toastify';
import Modal from '../../components/Modal/Modal';
import { FaCheck } from 'react-icons/fa6';
import { CREATE_GAME } from '../../graphql/mutatons/createGame';
import client from '../../graphql/client';
import { useSocketContext } from '../../context/socketContext';

const questionNumbers: number[] = [10, 30, 50, 70, 100, 120];
const categoryData = [
  {
    title: 'Medscroll quiz',
    value: 'medscroll',
  },
  {
    title: 'My question bank',
    value: 'question-bank',
  },
];

interface IsMedQuesI {
  subcategory: string[];
  status: boolean;
}

interface FormDataI {
  topic: string;
  totalQuestion: number;
  questionUUIDs: string[] | null;
  questionSelection: string;
  isMedQues: IsMedQuesI;
}

const initQuestionnView = 'Question selection';

const MultiplerCodeView = () => {
  const navigate = useNavigate();
  const [showNumbers, setShowNubers] = useState(false);

  const [showCategory, setShowCategory] = useState(false);

  const [showSubCategory, setShowSubCategory] = useState(false);

  const [subCategories, setSubCategories] = useState<SubcategoryRes[] | []>([]);

  const { isSocketConnected, disConnectSocket } = useSocketContext();

  // Mutation
  const [createGame, { loading: createGameLoading }] = useMutation(
    CREATE_GAME,
    { client }
  );

  const [getSubCategory, { loading }] =
    useLazyQuery<GetSubcategoryI>(GET_SUB_CATEGORY);

  // Check if there is a state passed from
  // question bank selection componet
  const location = useLocation();

  const { pathname, state } = location;

  const [formData, setFormData] = useState<FormDataI>({
    topic: '',
    totalQuestion: 10,
    questionUUIDs: [],
    questionSelection: initQuestionnView,
    isMedQues: { subcategory: [], status: false },
  });

  const handleGetSubCategory = async () => {
    await getSubCategory()
      .then((res) => {
        setSubCategories(res?.data!.getSubategory);
      })
      .catch((err) => {
        toast.error(err?.message || ' Unexpecteed error occured');
      });
  };

  const handleChecker = (subCat: string) => {
    // Remove from list
    if (formData?.isMedQues?.subcategory?.includes(subCat)) {
      const newsubCat = formData?.isMedQues?.subcategory.filter(
        (catItem) => catItem !== subCat
      );

      setFormData((prev) => ({
        ...prev,
        isMedQues: {
          ...prev.isMedQues,
          subcategory: newsubCat,
        },
      }));
    } else {
      // Add to list
      const newAddded = [...formData?.isMedQues?.subcategory, subCat];
      setFormData((prev) => ({
        ...prev,
        isMedQues: {
          ...prev.isMedQues,
          subcategory: newAddded,
        },
      }));
    }
  };

  const handleCreateGame = async () => {
    const { questionSelection, ...payload } = formData;

    if (questionSelection === 'Question selection')
      return toast.warn('Please select a question type');

    await createGame({
      variables: {
        gameInput: {
          ...payload,

          questionUUIDs:
            payload?.questionUUIDs?.length === 0
              ? null
              : payload?.questionUUIDs,
        },
      },
    })
      .then((res) => {
        navigate('/multiplayer-generate-code', {
          state: {
            inviteCode: res?.data?.createGame?.inviteCode,
          },
        });
      })
      .catch((err) => {
        toast.error(err?.message || 'Unexpected error occured');
      });
  };

  useEffect(() => {
    if (state) {
      setFormData((prev) => ({
        ...prev,
        topic: state?.topic,
        totalQuestion: state?.totalQuestion,
        questionUUIDs: state?.questionUUIDs,
        questionSelection: state?.questionSelection,
        isMedQues: { subcategory: [], status: false },
      }));
    }

    if (isSocketConnected) {
      disConnectSocket();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  if (loading || createGameLoading)
    return <SpinnerLoader loading={loading || createGameLoading} />;

  return (
    <div className="multipler-con">
      <div onClick={() => navigate(-1)}>{BackArrowSvg()}</div>

      <div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleCreateGame();
          }}
        >
          <div className="auth-input-con">
            <p>
              <span className="red">* </span> Topic
            </p>

            <input
              type="text"
              placeholder="type here"
              className="input-text "
              value={formData?.topic}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,

                  topic: e.target.value,
                }))
              }
              required
            />
          </div>

          <div className="speccialty-con">
            <div
              className="select-input-custom"
              onClick={() => setShowNubers((prev) => !prev)}
            >
              <div>{formData?.totalQuestion}</div>

              <div>{dropdownIconSvg()}</div>
            </div>

            {showNumbers && (
              <div className="specialty-drop">
                {questionNumbers.map((numberVal, index) => (
                  <p
                    key={index}
                    onClick={() => {
                      setFormData((prev) => ({
                        ...prev,
                        totalQuestion: numberVal,
                      }));
                      setShowNubers(false);
                    }}
                  >
                    {numberVal}
                  </p>
                ))}
              </div>
            )}
          </div>

          <div className="speccialty-con">
            <div
              className="select-input-custom"
              onClick={() => {
                setShowCategory((prev) => !prev);
              }}
            >
              <div>{formData?.questionSelection}</div>

              <div>{dropdownIconSvg()}</div>
            </div>

            {showCategory && (
              <div className="specialty-drop">
                {categoryData.map(({ value, title }, index) => (
                  <p
                    key={index}
                    onClick={() => {
                      // to allow user select  from medscroll quiz
                      if (value === 'medscroll') {
                        handleGetSubCategory();

                        setFormData((prev) => ({
                          ...prev,
                          questionUUIDs: [],
                        }));

                        setShowSubCategory(true);
                      } else if (value === 'question-bank') {
                        setFormData((prev) => ({
                          ...prev,
                          questionSelection: title,
                          isMedQues: {
                            subcategory: [],
                            status: false,
                          },
                        }));
                        navigate('/multiplayer-bank-selection', {
                          state: {
                            topic: formData?.topic,
                            totalQuestion: formData?.totalQuestion,
                            questionUUIDs: formData?.questionUUIDs,
                            questionSelection: title,
                          },
                        });
                      }

                      setFormData((prev) => ({
                        ...prev,
                        questionSelection: title,
                        isMedQues: {
                          ...prev.isMedQues,
                          status: value === 'medscroll' ? true : false,
                        },
                      }));

                      setShowCategory(false);
                    }}
                  >
                    {title}
                  </p>
                ))}
              </div>
            )}
          </div>

          <button type="submit" className="multipler-con__btn ">
            {' '}
            Invite player
          </button>
        </form>
      </div>

      {showSubCategory && (
        <Modal extraFunc={() => {}}>
          {/* Show the subcateegories */}

          <div className="subcategory-modal">
            <div className="subcategory-modal__header">
              <span
                onClick={() => {
                  setShowSubCategory(false);

                  // Set form back to default
                  setFormData((prev) => ({
                    ...prev,
                    questionSelection: initQuestionnView,
                    isMedQues: {
                      subcategory: [],
                      status: false,
                    },
                  }));
                }}
              >
                {BackArrowSvg()}
              </span>

              <button
                className="btn-multipler-view"
                onClick={() => setShowSubCategory(false)}
              >
                Done
              </button>
            </div>

            <div className="subcategory-modal__main">
              {subCategories.map(({ subcategory, totalNumber }, index) => (
                <div
                  className="subcategory-modal__item"
                  key={index}
                  onClick={() => {}}
                >
                  <div className="subcategory-modal__item-header">
                    <div
                      className="subcateegory-check"
                      onClick={() => {
                        handleChecker(subcategory?.id);
                      }}
                    >
                      {formData?.isMedQues?.subcategory?.includes(
                        subcategory?.id
                      ) && <FaCheck />}
                    </div>

                    <p className="text-title">{subcategory?.subcat}</p>
                  </div>
                  <p className="totalnumber">{totalNumber} questions</p>
                </div>
              ))}
            </div>
          </div>
        </Modal>
      )}

      <BackDrop
        isShow={showCategory || showNumbers}
        extraFunc={() => {
          setShowCategory(false);
          setShowNubers(false);
        }}
      />
    </div>
  );
};

export default MultiplerCodeView;
