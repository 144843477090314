import React, { useEffect, useState } from 'react';
import PrivateRoute from '../../Utils/PrivateRoute/PrivateRoute';
import { CloseSvg } from '../../components/Svgs/svgsComponents';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../state';
import { UserI } from '../../types/AuthResponse';
import { useSelector } from 'react-redux';
import { updateQuizzer } from '../../state/userSlice';
import './MultiplayerTrivia.css';
import MultiplayerQuiz from '../MultiplayerQuiz/MultiplayerQuiz';
import { useSocketContext } from '../../context/socketContext';
import {
  PlayerScoreI,
  ReplayI,
  SocketCountDown,
  SocketQuestionsI,
} from '../../types/socket';
import Modal from '../../components/Modal/Modal';
import MultiplayerQuizResult from '../../components/MultiplayerQuizResult/MultiplayerQuizResult';

const MultiplayerTrivia = () => {
  const [questions, setQuestions] = useState<SocketQuestionsI[] | []>([]);

  const [usersDetails, setUsersDetails] = useState<PlayerScoreI[] | []>([]);

  const [countDown, setCountDown] = useState<SocketCountDown | null>(null);

  const [isGameStop, setIsGameStop] = useState<boolean>(false);

  const [isGameStarted, setIsGameStarted] = useState(false);

  const { emit, on, off, disConnectSocket } = useSocketContext();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [showResult, setShowResult] = useState<boolean>(false);

  const user = useSelector((state: RootState) => state.user) as UserI;

  const [canClickReply, setCanClickReply] = useState(true);

  const [replyData, setReplayData] = useState<ReplayI | null>(null);

  const handleUpdatePoint = (score: number) => {
    if (score > 0) {
      const newScore = user.quizzer.totalPoints + score;

      dispatch(updateQuizzer({ totalPoints: newScore }));
    }
  };

  // Critical function

  const handleListenGetQuestion = (questionData: SocketQuestionsI[]) => {
    setQuestions(questionData);

    // To know when gamee has started
    // Meaning question has come

    setIsGameStarted(true);
  };

  const handleListenFetchGame = (gameData: any) => {
    const userData: PlayerScoreI[] = Object.values(gameData?.playerScores);

    // setUsersJoined(usersData);
    setUsersDetails(userData);

    if (gameData?.gameState === 'COMPLETED') {
      setIsGameStop(true);

      setTimeout(() => {
        // Open modal to show result
        setShowResult(true);
      }, 2000);
    }
  };

  const handleListenCountDown = (countDownData: any) => {
    //Emit fetch game state on each count down changee
    // so as to geet updated scores and user game detatial
    emit('fetch-game', {});

    setCountDown(countDownData);
  };

  const hanldleListenReplyGame = (
    data: React.SetStateAction<ReplayI | null>
  ) => {
    // To reply the gamee, 'code is gotten here andd set to user accept
    // modal n view results

    setCanClickReply(false);

    setReplayData(data);
  };

  // listen
  useEffect(() => {
    if (!isGameStarted) {
      // Get question
      // Queestion shoild be gotton once,
      // wheenn game the game starts
      on('getQuestions', (questionData) =>
        handleListenGetQuestion(questionData)
      );
    }

    if (!isGameStop) {
      on('fetchGame', (gameData) => handleListenFetchGame(gameData));

      // Get count down  of current question number and time
      on('getCountdown', (countDownData) =>
        handleListenCountDown(countDownData)
      );
    }

    on('replayGame', (data) => hanldleListenReplyGame(data));

    return () => {
      off('getQuestions', handleListenGetQuestion);
      off('fetchGame', handleListenFetchGame);
      // off('getCountdown', handleListenCountDown);
      off('replayGame', hanldleListenReplyGame);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [on]);

  useEffect(() => {
    emit('fetch-questions', {});
    // emit('start-count-down', {});

    emit('fetch-game', {});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PrivateRoute>
      <div className="container multiplayer-trivia">
        <div className="multiplayer-trivia-points">
          <div className="multiplayer-trivia-points__items">
            {usersDetails.length > 0 &&
              usersDetails.map(({ firstName, score, url, userUUID }) => (
                <div key={userUUID} className="multiplayer-trivia-points__item">
                  <p>{firstName}</p>

                  <img
                    src={url || '/images/guest.svg'}
                    alt="user "
                    className="multiplayer-trivia-points__item-img"
                  />
                  <p>{score}</p>
                </div>
              ))}
          </div>

          <div
            className="multiplayer-trivia-close"
            onClick={() => {
              disConnectSocket();
              navigate('/dashboard');
            }}
          >
            {CloseSvg()}
          </div>
        </div>

        <div className="multiplayer-trivia-quiz">
          <MultiplayerQuiz
            questionData={questions}
            countDown={countDown}
            updatePointUI={handleUpdatePoint}
          />
        </div>
      </div>

      {showResult && (
        <Modal extraFunc={() => {}}>
          <MultiplayerQuizResult
            usersDetails={usersDetails}
            totalQuestion={questions.length}
            setCanClickReply={setCanClickReply}
            canClickReply={canClickReply}
            replyData={replyData!}
          />
        </Modal>
      )}
    </PrivateRoute>
  );
};

export default MultiplayerTrivia;
