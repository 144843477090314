import React, { useEffect, useState } from 'react';

import './DashboardCreate.css';
import { useNavigate, useParams } from 'react-router-dom';
import ImageDragDrop from '../../components/ImageDragDrop/ImageDragDrop';
import { isTwoDigitNumber } from '../../Utils/isTwoDigit';
import { useMutation, useQuery } from '@apollo/client';
import {
  ADD_QUIZ_CATEGORY,
  CREATE_QUIZ,
  UPDATE_QUIZ,
} from '../../graphql/mutatons/createQuiz';
import client from '../../graphql/client';
import SpinnerLoader from '../../components/SpinnerLoader/SpinnerLoader';
import { toast } from 'react-toastify';
import { FileWithPreviewI } from '../../types/GlobalType';
import {
  GET_QUESTION_BANK_CAT,
  GET_QUIZ,
} from '../../graphql/queries/getQuestionBankData';
import { QuestionBankCategoryI } from '../../types/QuestionBankTypes';

const DashboardCreate = () => {
  const navigate = useNavigate();

  // To navigate betwween Ai creation
  // and Non ai creation of Quiz
  const [navigateTo, setNavigateTo] = useState('');

  // Get params
  const { quizUuid } = useParams();

  const [quizCategories, setQuizCategory] = useState<string[] | []>([]);

  const [showSelectQuizCat, setShowSelectQuizCat] = useState(false);

  const [showAddCatQuiz, setShowAddCatQuiz] = useState(false);

  const [newCategory, setNewCategory] = useState('');

  // Query
  const {
    error: quizError,
    loading: quizLoading,
    data: quizData,
  } = useQuery(GET_QUIZ, {
    variables: { quizUuid: quizUuid },
    skip: !quizUuid, // Skip the query if quizUUID is not available
  });

  const [fileUpload, setFileUpload] = useState<
    FileWithPreviewI | File | null | undefined | FormData
  >(null);

  const iniitCreateQuizData = {
    createQuizInput: {
      topic: '',
      description: '',
      durationMin: 1,
      durationSec: 0,
      point: 1,
      quizCategory: '',
    },
    file: null,
  };
  const [createQuizData, setCreateQuizData] = useState(iniitCreateQuizData);

  // Mutation
  const [createQuiz, { data, loading, error }] = useMutation(CREATE_QUIZ, {
    client,
  });

  const [updateQuiz, { loading: updateQuizLoading }] = useMutation(
    UPDATE_QUIZ,
    { client }
  );

  const [addQuizCattegory, { loading: addQuizCateegory }] = useMutation(
    ADD_QUIZ_CATEGORY,
    { client }
  );

  // Query
  // Query get question Bank Categories
  const {
    loading: questionCatLoading,
    data: questionCatData,
    error: questionCatError,
    refetch: questionCatRefeatch,
  } = useQuery(GET_QUESTION_BANK_CAT);

  const getMoodTime = () => {
    const modDurationSec =
      !createQuizData.createQuizInput.durationSec &&
      !createQuizData.createQuizInput.durationMin
        ? '30'
        : isTwoDigitNumber(createQuizData.createQuizInput.durationSec)
        ? createQuizData.createQuizInput.durationSec
        : '0' + createQuizData.createQuizInput.durationSec.toString();

    const modDurationMin = !createQuizData.createQuizInput.durationMin
      ? '00'
      : isTwoDigitNumber(createQuizData.createQuizInput.durationMin)
      ? createQuizData.createQuizInput.durationMin
      : '0' + createQuizData.createQuizInput.durationMin.toString();

    return { modDurationMin, modDurationSec };
  };

  const handleCreateQuiz = async () => {
    const { modDurationMin, modDurationSec } = getMoodTime();

    const { durationMin, durationSec, ...otherData } =
      createQuizData.createQuizInput;

    const dataSubmit = {
      createQuizInput: {
        ...otherData,
        duration: `${modDurationMin}:${modDurationSec}`,
      },
      file: fileUpload,
    };

    await createQuiz({
      variables: { ...dataSubmit },
    })
      .then((res) => {})
      .catch((error) => {});
  };

  const handleUpdateQuiz = async () => {
    const { modDurationMin, modDurationSec } = getMoodTime();

    // Remove not needed data

    const { durationMin, durationSec, ...otherData } =
      createQuizData.createQuizInput;

    const updateQuizPayload = {
      updateQuizInput: {
        ...otherData,
        duration: `${modDurationMin}:${modDurationSec}`,
        quizUUID: quizUuid,
      },
      file: createQuizData?.file,
    };

    await updateQuiz({
      variables: { ...updateQuizPayload },
    })
      .then((res) => {
        toast.success('Quiz updated successfully');

        const quizId = res?.data?.updateQuiz?.quizUUID;

        navigate(`/add-questions/${quizId}`);
      })
      .catch((err) => {
        toast.error(err?.message || 'Unexpected error ocurred');
      });
  };

  // Reset Seletion
  const handleRseetSelection = () => {
    setShowSelectQuizCat(false);
    setShowAddCatQuiz(false);

    setNewCategory('');
  };

  // handle quiz cat  add
  const handleCategorySelected = (quizCatItem: string) => {
    setCreateQuizData((prev) => ({
      ...prev,
      createQuizInput: {
        ...prev.createQuizInput,
        quizCategory: quizCatItem,
      },
    }));
  };

  const handleAddCatToUserQuiz = async () => {
    if (newCategory.trim() === '')
      return toast.warning('Category cannot be empty');

    // Server call add category
    await addQuizCattegory({
      variables: {
        addCusCatInput: {
          customCat: newCategory,
        },
      },
    })
      .then((res) => {
        toast.success(res?.data?.addQBQuizCategory?.message);

        handleCategorySelected(newCategory);
        setQuizCategory([newCategory, ...quizCategories]);
        questionCatRefeatch();
      })
      .catch((err) => {
        toast.error(err?.message || 'Unexpected Error Occcured');
      });
  };

  useEffect(() => {
    if (error) {
      toast.error(error?.message || 'unexpected error occured');
    }

    if (data) {
      toast.success('Quiz created successfully');

      navigate(navigateTo, {
        state: {
          quizUuidState: data?.createQuiz?.quizUUID,
        },
      });
    }

    if (quizData) {
      const quizDataMod = {
        createQuizInput: {
          topic: quizData?.getQuiz?.topic,
          description: quizData?.getQuiz?.description,
          durationMin: (quizData?.getQuiz?.duration).split(':')[0],
          durationSec: (quizData?.getQuiz?.duration).split(':')[1],
          point: quizData?.getQuiz?.point,
          quizCategory: quizData?.getQuiz?.quizCategory?.customCat,
        },
        file: null,
      };

      setCreateQuizData(quizDataMod);
    }

    if (questionCatData) {
      const categoriesData = questionCatData?.getQBQuizCategory.map(
        (cat: QuestionBankCategoryI) => cat?.customCat
      );

      setQuizCategory([...categoriesData]);
    }

    // Handle errors
    if (quizError) {
      toast.error(quizError?.message || 'unexpected error occured');
    }

    if (questionCatError) {
      toast.error(questionCatError?.message || 'unexpected error occured');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, quizData]);

  useEffect(() => {
    questionCatRefeatch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    loading ||
    quizLoading ||
    updateQuizLoading ||
    questionCatLoading ||
    addQuizCateegory
  )
    return (
      <SpinnerLoader
        loading={
          loading ||
          quizLoading ||
          updateQuizLoading ||
          questionCatLoading ||
          addQuizCateegory
        }
      />
    );

  return (
    <div className="dashboard-create-container container">
      <div className="dashboard-create__image-upload">
        <div className="dashboard-create__image-upload-content">
          <ImageDragDrop
            multiple={false}
            setFileUpload={setFileUpload}
            imageUrl={quizData?.getQuiz?.coverImage || null}
          />
        </div>
      </div>

      <div className="dashboard-create__form-area">
        <form
          onSubmit={(e) => {
            e.preventDefault();

            handleCreateQuiz();
          }}
        >
          <div className="dashboard-create__form-item">
            <div>
              <span className="red-dot">*</span>
              <span> Topic</span>
            </div>
            <input
              type="text"
              placeholder="Type here"
              required
              value={createQuizData.createQuizInput.topic}
              onChange={(e) => {
                setCreateQuizData((prev) => ({
                  ...prev,
                  createQuizInput: {
                    ...prev.createQuizInput,
                    topic: e.target.value,
                  },
                }));
              }}
            />
          </div>

          <div className="dashboard-create__form-item">
            <div>
              <span> Description</span>
            </div>
            <input
              type="text"
              placeholder="Description"
              required
              value={createQuizData.createQuizInput.description}
              onChange={(e) => {
                setCreateQuizData((prev) => ({
                  ...prev,
                  createQuizInput: {
                    ...prev.createQuizInput,
                    description: e.target.value,
                  },
                }));
              }}
            />
          </div>

          <div className="dashboard-create__form-item">
            <div>
              <span className="red-dot">*</span>
              <span> Category</span>
            </div>
            <div>
              <div className="selection-input">
                <input
                  type="text"
                  placeholder="Select category"
                  required
                  value={createQuizData.createQuizInput.quizCategory}
                  readOnly={true}
                  onClick={() => {
                    setShowSelectQuizCat(true);
                  }}
                />
              </div>

              {showSelectQuizCat && (
                <div className="category-quiz">
                  {/* Show For adding New Cateegory */}
                  <div className="category-quiz__add-cat">
                    {showAddCatQuiz && (
                      <input
                        type="text"
                        placeholder="Add category"
                        required
                        value={newCategory}
                        onChange={(e) => setNewCategory(e.target.value)}
                      />
                    )}
                    <p
                      className=""
                      onClick={() => {
                        if (showAddCatQuiz) {
                          // Call Add Function cateegory

                          handleAddCatToUserQuiz();
                          handleRseetSelection();
                          return null;
                        }
                        setShowAddCatQuiz(true);
                      }}
                    >
                      {showAddCatQuiz ? 'Create' : '+ Add'}{' '}
                    </p>
                  </div>

                  {/* For Showing preevious Categories to select  from */}
                  {quizCategories.map((quizCatItem, index) => (
                    <p
                      className="category-quiz__item"
                      key={index}
                      onClick={() => {
                        handleCategorySelected(quizCatItem);

                        // Reset
                        handleRseetSelection();
                      }}
                    >
                      {quizCatItem}
                    </p>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="dashboard-create__form-item-joint">
            <div className="dashboard-create__form-item">
              <div>
                <span> Points (per question)</span>
              </div>
              <input
                type="number"
                min={1}
                placeholder="Type here"
                required
                value={createQuizData.createQuizInput.point}
                onChange={(e) => {
                  setCreateQuizData((prev) => ({
                    ...prev,
                    createQuizInput: {
                      ...prev.createQuizInput,
                      point: parseInt(e.target.value),
                    },
                  }));
                }}
              />
            </div>

            <div className="dashboard-create__form-item">
              <div>
                <span> Duration (per question)</span>
              </div>
              <div className="dashboard-create__form-item-timer">
                <input
                  type="number"
                  min={0}
                  placeholder="Mins"
                  value={createQuizData.createQuizInput.durationMin.toString()}
                  onChange={(e) => {
                    setCreateQuizData((prev) => ({
                      ...prev,
                      createQuizInput: {
                        ...prev.createQuizInput,
                        durationMin: parseInt(e.target.value),
                      },
                    }));
                  }}
                />
                <input
                  type="number"
                  min={0}
                  placeholder="Secs"
                  value={createQuizData.createQuizInput.durationSec.toString()}
                  onChange={(e) => {
                    setCreateQuizData((prev) => ({
                      ...prev,
                      createQuizInput: {
                        ...prev.createQuizInput,
                        durationSec: parseInt(e.target.value),
                      },
                    }));
                  }}
                />
              </div>
            </div>
          </div>

          {!quizData && (
            <div className="dashboard-create__btns">
              <div className="btn" typeof="button">
                <button
                  className="create-btn"
                  onClick={() => setNavigateTo('/add-questions')}
                >
                  Create
                </button>
              </div>

              <button
                className="btn ai-btn"
                onClick={() => {
                  setNavigateTo('/dashboard/add-questions/ai');
                }}
              >
                <span className="gradiat-border">AI Assistant</span>
              </button>
            </div>
          )}
        </form>

        {quizData && (
          <div
            onClick={() => {
              handleUpdateQuiz();
            }}
            className="dashboard-create__update-quiz"
          >
            <span>Update quiz</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardCreate;
