import React, { useEffect, useState } from 'react';

const useCustomInVew = (
  refElement: React.RefObject<HTMLDivElement | null>,

  customThreshold?: number
) => {
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    const refData = refElement.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const isInterSecting = entry.isIntersecting;

          if (isInterSecting !== isInView && !isInView) {
            setIsInView(isInterSecting);
          }
        });
      },
      { threshold: customThreshold || 0.2 }
    );

    if (refData) {
      observer.observe(refData);
    }

    return () => {
      if (refData) {
        observer.unobserve(refData);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refElement, isInView]);

  return isInView;
};

export default useCustomInVew;
