import React from 'react';

import './MultipleAnswer.css';

const MultipleAnswer = () => {
  const NummberOfOptions = 4;

  return (
    <div className="multiple-options">
      {Array(NummberOfOptions)
        .fill('0')
        .map((_, index) => (
          <div
            className="multiple-options__item options__input-item"
            key={index}
          >
            <input
              type="text"
              placeholder={`${
                index !== 1 ? 'Answer ' + (index + 1) : 'Correct Answer'
              }`}
              className="remove-default-input  options__item-text "
              required
            />

            <input type="checkbox" className="multiple-options__checkbox" />
          </div>
        ))}
    </div>
  );
};

export default MultipleAnswer;
