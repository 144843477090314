import React, { useState } from 'react';
import './Pricing.css';
import NavBar from '../../components/NavBar/NavBar';
import {
  priceFeatureDataMonthly,
  priceThDataMonthly,
  priceFeatureDataYearly,
  priceThDataYearly,
  pricingThData,
} from './PricingData';
import LazyLoad from 'react-lazyload';
import Pitch from '../../components/Pitch/Pitch';
import Footer from '../../components/Footer/Footer';
import SEO from '../../components/SEO/SEO';

const Pricing = () => {
  const [isYearlyPrice, setIsYearlyPrice] = useState(false);
  return (
    <section className="pricing">
      <NavBar />

      <SEO
        title="Pricing"
        description="Empower Your Learning Journey with MedScroll Choose Your Plan Today"
        name={'medscroll'}
        type="article"
      />

      <div className="container pricing__header">
        <h1>
          Empower Your Learning Journey with MedScroll Choose Your Plan Today
        </h1>
        <p>
          Unlock a world of knowledge, engagement, and continuous improvement
          with MedScroll. Choose your plan and start your journey today.
        </p>
      </div>

      <div className="pricing__con">
        <div className="container pricing__main">
          <div className="pricing__main-header">
            <h3>Select your preferred plan </h3>
          </div>
          <div className="pricing__indicator">
            <span
              onClick={() => setIsYearlyPrice(false)}
              className={`${!isYearlyPrice ? 'pricing__indicator-active' : ''}`}
            >
              Monthly
            </span>
            <span
              onClick={() => setIsYearlyPrice(true)}
              className={`${isYearlyPrice ? 'pricing__indicator-active' : ''}`}
            >
              Yearly
            </span>
          </div>

          {/* Table */}
          <section className="pricing__table">
            <p className="caption">Best Value</p>
            <div className="pricing__table-header">
              {pricingThData.map((value, index) => (
                <div
                  key={index}
                  className={`

                ${index === 1 ? 'start_header-bg' : null}

                 ${index === 2 ? 'pro_header-bg' : null}

                  ${index === 3 ? 'premium_header-bg' : null}

                pricing__table-header__item`}
                >
                  <p className="value">{value}</p>
                </div>
              ))}
            </div>

            {/* Price Category */}
            <div className="pricing__table-price ">
              {(isYearlyPrice
                ? [...priceThDataYearly]
                : [...priceThDataMonthly]
              ).map((value, index) => (
                <div key={index} className="pricing__table-price__item">
                  {value}
                </div>
              ))}
            </div>

            {/* Feature */}
            <div className="pricing__table-features">
              {(isYearlyPrice
                ? [...priceFeatureDataYearly]
                : [...priceFeatureDataMonthly]
              ).map((initailValue, rowIndex) => (
                <div key={rowIndex} className="pricing__table-feature">
                  {initailValue.map((value, colIndex) => (
                    <div
                      key={colIndex}
                      className={'pricing__table-feature__item'}
                    >
                      {value === 'unlimited' ? (
                        <LazyLoad offset={100}>
                          <img src="/images/check2.png" alt="check icon" />
                        </LazyLoad>
                      ) : (
                        <span className=" feature__item-text">
                          {value}

                          {rowIndex === priceFeatureDataMonthly.length - 1 &&
                          colIndex === 1 ? (
                            <span className="feature__item-text-span">
                              ( 7days free trial)
                            </span>
                          ) : null}
                        </span>
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </section>
        </div>
      </div>

      <Pitch />
      <Footer />
    </section>
  );
};

export default Pricing;
