import React, { useEffect, useState } from 'react';

import './DashboardQuestion.css';
import PrivateRoute from '../../Utils/PrivateRoute/PrivateRoute';
import { BackArrowSvg } from '../../components/Svgs/svgsComponents';
import QuestionBankCard from '../../components/QuestionBankCard/QuestionBankCard';
import SearchBar from '../../components/SearchBar/SearchBar';
import { useQuery } from '@apollo/client';
import {
  GET_QUESTION_BANK,
  GET_QUESTION_BANK_CAT,
} from '../../graphql/queries/getQuestionBankData';
import {
  QuestionBankBaseStrucCustomI,
  QuestionBankCatDetailI,
  QuestionBankCategoryI,
} from '../../types/QuestionBankTypes';
import SpinnerLoader from '../../components/SpinnerLoader/SpinnerLoader';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import QuestionBankMenu from '../../components/QuestionBankMenu/QuestionBankMenu';

const DashboardQuestion = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // Note: cat is short for category
  const [catWithNoQuestionData, setCatWithNoQuestionData] = useState<string[]>(
    []
  );

  const [categoryData, setCategoryData] = useState<
    QuestionBankCategoryI[] | []
  >([]);

  // For Ref purpose to know the init stat of All QB list
  // Does not update after initial update
  const [initCategoryDetails, setInitCategoryDetails] = useState<
    QuestionBankBaseStrucCustomI[] | []
  >([]);

  // For question bank question list
  // Updates bas on cat selected
  const [categoryDetails, setCategoryDetails] = useState<
    QuestionBankBaseStrucCustomI[] | []
  >([]);

  // Query get question Bank Categories
  const { loading, data, error, refetch } = useQuery(GET_QUESTION_BANK_CAT);

  // Query get all bank questions
  const {
    loading: qbLoading,
    data: qbData,
    error: qbError,
    refetch: refetchQbData,
  } = useQuery(GET_QUESTION_BANK);

  const hanldeActiveCategory = (catId: string | number) => {
    const newCategoryState = categoryData.map(({ customCatId, ...others }) => {
      if (customCatId === catId) {
        return {
          customCatId,
          ...others,
          isActive: true,
        };
      } else
        return {
          customCatId,
          ...others,
          isActive: false,
        };
    });

    // Filter category Details
    let newCategoreDetails = initCategoryDetails;

    if (catId !== 'all') {
      newCategoreDetails = initCategoryDetails.filter(
        (catItem) => catItem?.catId === catId
      );
    }

    setCategoryDetails(newCategoreDetails);
    setCategoryData(newCategoryState);
  };

  const updateUiAfterDelete = (quizId: string) => {
    const filterOutDeleted = [];
    const catWithoutQuestionFilter: string[] = [];

    for (const initCat of initCategoryDetails) {
      let catDetailsFilter = [];
      for (let catItmem of initCat?.catDetails) {
        if (catItmem?.quizUUID !== quizId) {
          catDetailsFilter.push(catItmem);
        }
      }
      filterOutDeleted.push({ ...initCat, catDetails: catDetailsFilter });
    }

    filterOutDeleted.forEach((qbItem: QuestionBankBaseStrucCustomI) => {
      if (qbItem.catDetails.length === 0) {
        return catWithoutQuestionFilter.push(qbItem.catName);
      } else return null;
    });

    setCatWithNoQuestionData(catWithoutQuestionFilter);
    setInitCategoryDetails(filterOutDeleted);
    setCategoryDetails(filterOutDeleted);
    hanldeActiveCategory('all');
  };

  // IN use Effect set the category select state
  useEffect(() => {
    if (data) {
      // For selection of all Question List to show
      // Default is true
      const showAllCat = {
        __typename: 'CustomCat',
        customCat: 'All',
        customCatId: 'all',
        isActive: true,
      };

      const catRes = data?.getQBQuizCategory
        .map((catValue: QuestionBankCategoryI) => ({
          ...catValue,
          isActive: false,
        }))
        .reverse();

      setCategoryData([showAllCat, ...catRes]);
    }

    // Modify Question Bank Response
    if (qbData && data) {
      const baseQBQuizzStructure = data?.getQBQuizCategory
        .map((catValue: QuestionBankCategoryI) => ({
          catName: catValue?.customCat,
          catId: catValue?.customCatId,
          catDetails: [],
        }))
        .reverse();

      // Update baseStruture to have Quiz list Details arranged based on category name
      qbData.getAllQuiz.forEach((qbItem: QuestionBankCatDetailI) => {
        baseQBQuizzStructure.forEach(
          (baseItem: QuestionBankBaseStrucCustomI) => {
            if (baseItem.catName === qbItem?.quizCategory?.customCat) {
              baseItem.catDetails.push(qbItem);
            }
          }
        );
      });

      // Get all cat that has no question
      // So there dont show up on ui not untill it has question

      let catWithNoQuestion: string[] = [];

      baseQBQuizzStructure.forEach((qbItem: QuestionBankBaseStrucCustomI) => {
        if (qbItem.catDetails.length === 0) {
          return catWithNoQuestion.push(qbItem.catName);
        } else return null;
      });

      setCatWithNoQuestionData(catWithNoQuestion);

      setInitCategoryDetails(baseQBQuizzStructure);
      setCategoryDetails(baseQBQuizzStructure);
    }

    // To reload each time comes to this page
    refetchQbData();
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, qbData, pathname, refetchQbData, refetch]);

  // Spinner
  if (loading || qbLoading)
    return <SpinnerLoader loading={loading || qbLoading} />;

  if (error) toast.error(error?.message || 'An unexpected error occured');

  if (qbError) toast.error(error?.message || 'An unexpected error occured');

  return (
    <PrivateRoute>
      <div className="container question-bank">
        <div className="question-bank__headers">
          <div className="question-bank__header-left">
            <div onClick={() => navigate('/dashboard')}>{BackArrowSvg()}</div>

            <h3 className="question-title">My Question Bank</h3>
          </div>
          <div className="not-for-current-sprint question-bank__header-right">
            <button className="btn random-btn">Random quiz</button>

            <button className="btn multiselect-btn">
              <span className="gradiat-blue-border">Multiselect</span>
            </button>
          </div>
        </div>

        <div className="question-bank__search-filter not-for-current-sprint ">
          <QuestionBankMenu />

          <div className="question-bank__search">
            <SearchBar />
          </div>

          <div className="question-bank__sorts-filters">
            <div className="question-bank__sort-filter">
              <p>Sort by</p>
              <div>
                <img src="/images/sort-by.png" alt="sort by" />
              </div>
            </div>

            <div className="question-bank__sort-filter ">
              <p>Filter</p>

              <div>
                <img src="/images/filter-icon.png" alt="filter icon" />
              </div>
            </div>
          </div>
        </div>

        <div className="question-bank__content">
          <div className="question-bank__content-selction">
            {categoryData.map(
              ({ customCatId, customCat, isActive }, index) =>
                !catWithNoQuestionData.includes(customCat) && (
                  <div
                    key={index}
                    className={` ${
                      isActive && 'question-bank__active-selection'
                    } question-bank__content-selction__item`}
                    onClick={() => hanldeActiveCategory(customCatId)}
                  >
                    {customCat}
                  </div>
                )
            )}
          </div>

          <div className="question-bank__cards-categories">
            {categoryDetails.map(
              (questionDetails, index) =>
                questionDetails?.catDetails.length > 0 && (
                  <>
                    <div className="question-bank__cards-category" key={index}>
                      <p>{questionDetails?.catName}</p>
                      {questionDetails?.catDetails.length > 0 ? (
                        <div className="question-bank__cards">
                          {questionDetails?.catDetails.map(
                            (
                              {
                                coverImage,
                                description,

                                topic,
                                quizUUID,
                                point,
                                totalDuration,
                                totalQuestion,
                                duration,
                              },
                              index
                            ) => (
                              <React.Fragment key={quizUUID}>
                                <QuestionBankCard
                                  coverImage={coverImage}
                                  description={description}
                                  topic={topic}
                                  cardId={quizUUID}
                                  point={point}
                                  totalDuration={totalDuration}
                                  totalQuestion={totalQuestion}
                                  duration={duration}
                                  updateUiAfterDelete={updateUiAfterDelete}
                                />
                              </React.Fragment>
                            )
                          )}
                        </div>
                      ) : (
                        <p>No Question currently </p>
                      )}
                    </div>
                  </>
                )
            )}
          </div>
        </div>

        <button
          className="btn question-bank-btn"
          onClick={() => navigate('/dashboard/create')}
        >
          Continue
        </button>
      </div>
    </PrivateRoute>
  );
};

export default DashboardQuestion;
