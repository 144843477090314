import { useState } from 'react';
import ConfirmOtp from '../../components/ConfirmOtp/ConfirmOtp';
import { useLocation, useNavigate } from 'react-router-dom';
import './ResetPassord.css';
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';
import { useMutation } from '@apollo/client';
import { RESET_PASSWORD } from '../../graphql/mutatons/authentication';
import client from '../../graphql/client';
import { toast } from 'react-toastify';
import SpinnerLoader from '../../components/SpinnerLoader/SpinnerLoader';

const ResetPassord = () => {
  const navigate = useNavigate();

  const [formPassword, setFormPassword] = useState('');

  const [showPassword, setShowPassword] = useState(false);

  const [enterOtp, setEnterOtp] = useState('');

  const location = useLocation();

  const email = location.state.email;

  const [resetpassword, { loading }] = useMutation(RESET_PASSWORD, { client });

  const handleResetPasswword = async () => {
    if (enterOtp.trim() === '') {
      toast.warn('Otp can not be empty');
      return;
    }

    if (formPassword === '') {
      toast.warn('password can not be empty');
      return;
    }

    await resetpassword({
      variables: {
        resetPasswordInput: {
          email: email,
          newPassword: formPassword,
          otp: enterOtp,
        },
      },
    })
      .then((res) => {
        toast.success(res?.data?.resetPassword?.message);

        // Navigate to home
        navigate('/login');
      })
      .catch((error) => {
        toast.error(error?.mssage || 'Unexpected errorr occured');
      });
  };

  if (loading) return <SpinnerLoader loading={loading} />;

  return (
    <div className="new-passwword-con">
      <div className="new-password-main">
        <div className="new-password-main__header">
          <h3>Reset Password</h3>
          <p>An Email with an OTP has been sent to your email</p>
        </div>
      </div>

      <form
        className="auth-form"
        onSubmit={(e) => {
          e.preventDefault();

          handleResetPasswword();
        }}
      >
        <div className="reset-otp">
          <h3>Enter OTP</h3>

          <ConfirmOtp isOtpEnter={false} setEnterOtp={setEnterOtp} />
        </div>

        <div className="auth-input-con">
          <p>Password</p>

          <div className="password">
            <input
              type={showPassword ? 'text' : 'password'}
              placeholder="password"
              value={formPassword}
              onChange={(e) => setFormPassword(e.target.value)}
              className="input-password"
              required
            />
            {showPassword ? (
              <IoEyeOutline
                onClick={() => setShowPassword((prevState) => !prevState)}
              />
            ) : (
              <IoEyeOffOutline
                onClick={() => setShowPassword((prevState) => !prevState)}
              />
            )}
          </div>
        </div>
        <button className="auth-btn" type="submit">
          Continue
        </button>
      </form>
    </div>
  );
};

export default ResetPassord;
