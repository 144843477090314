import { gql } from '@apollo/client';

export const REGISTER_USER = gql`
  mutation RegisterUser($signUpInput: SignUpInput!) {
    registerUser(signUpInput: $signUpInput) {
      message
    }
  }
`;

export const LOGIN_USER = gql`
  mutation LoginUser($loginInput: LoginInput!) {
    loginUser(loginInput: $loginInput) {
      access_token
      message
      user {
        country {
          code
          country
        }
        username
        userUUID
        specialty
        role
        quizzer {
          totalPoints
          ranking
          dailyStreak
        }
        subscription {
          credits
          generalTrivia
          identifier
          imageUploadPerQues
          isActive
          isTrialPeriod
          maxNumQuestion
          medicalTrivia
          multiplayerCapacity
          plan
          productId
          storage
          tokenBalance
          topUpCredits
          txtLimitPerOption
          txtLimitPerQuestion
        }
        stripeCustomer {
          stripeCustomerId
          stripeSub {
            stripeSubId
            stripeSubItemId
            stripeSubPlanName
            stripeSubStatus
          }
        }
        lastName
        isVerified
        firstName
        email
        profileImage
        phoneNumber
        state_city
      }
    }
  }
`;

export const VALIDATE_LOGIN_USER_OTP = gql`
  mutation ValidateLoginUserOtp($validateOtpInput: ValidateOtpInput!) {
    validateLoginUserOtp(validateOtpInput: $validateOtpInput) {
      access_token
      message
      user {
        country {
          code
          country
        }
        username
        userUUID
        specialty
        role
        quizzer {
          totalPoints
          ranking
          dailyStreak
        }
        subscription {
          credits
          generalTrivia
          identifier
          imageUploadPerQues
          isActive
          isTrialPeriod
          maxNumQuestion
          medicalTrivia
          multiplayerCapacity
          plan
          productId
          storage
          tokenBalance
          topUpCredits
          txtLimitPerOption
          txtLimitPerQuestion
        }
        stripeCustomer {
          stripeCustomerId
          stripeSub {
            stripeSubId
            stripeSubItemId
            stripeSubPlanName
            stripeSubStatus
          }
        }
        lastName
        isVerified
        firstName
        email
        profileImage
        phoneNumber
        state_city
      }
    }
  }
`;

export const GOOGLE_LOG_IN = gql`
  mutation GoogleLoginMobile($googleLoginInput: GoogleLoginInput!) {
    googleLoginMobile(googleLoginInput: $googleLoginInput) {
      access_token
      message
      user {
        username
        lastName
        isVerified
        email
        firstName
        country {
          code
          country
        }
        profileImage
        phoneNumber
        quizzer {
          dailyStreak
          ranking
          totalPoints
        }
        subscription {
          credits
          generalTrivia
          identifier
          imageUploadPerQues
          isActive
          isTrialPeriod
          maxNumQuestion
          medicalTrivia
          multiplayerCapacity
          plan
          productId
          storage
          tokenBalance
          topUpCredits
          txtLimitPerOption
          txtLimitPerQuestion
        }
        stripeCustomer {
          stripeCustomerId
          stripeSub {
            stripeSubId
            stripeSubItemId
            stripeSubPlanName
            stripeSubStatus
          }
        }
        role
        subspecialty
        state_city
      }
    }
  }
`;

export const FORGET_PASSWORD = gql`
  mutation ForgotPassword($getOtpInput: GetOtpInput!) {
    forgotPassword(getOtpInput: $getOtpInput) {
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($resetPasswordInput: ResetPasswordInput!) {
    resetPassword(resetPasswordInput: $resetPasswordInput) {
      message
    }
  }
`;
