export const countryList: string[] = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas (the)',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia (Plurinational State of)',
  'Bonaire, Sint Eustatius and Saba',
  'Bosnia and Herzegovina',
  'Botswana',
  'Brazil',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cayman Islands (the)',
  'Central African Republic (the)',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos (Keeling) Islands (the)',
  'Colombia',
  'Comoros (the)',
  'Congo (the Democratic Republic of the)',
  'Congo (the)',
  'Cook Islands (the)',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Curaçao',
  'Cyprus',
  'Czechia',
  "Côte d'Ivoire",
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic (the)',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Falkland Islands (the) [Malvinas]',
  'Faroe Islands (the)',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'Gabon',
  'Gambia (the)',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Holy See (the)',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran (Islamic Republic of)',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  "Korea (the Democratic People's Republic of)",
  'Korea (the Republic of)',
  'Kuwait',
  'Kyrgyzstan',
  "Lao People's Democratic Republic (the)",
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands (the)',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia (Federated States of)',
  'Moldova (the Republic of)',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands (the)',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger (the)',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Northern Mariana Islands (the)',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine, State of',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines (the)',
  'Pitcairn',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Republic of North Macedonia',
  'Romania',
  'Russian Federation (the)',
  'Rwanda',
  'Réunion',
  'Saint Barthélemy',
  'Saint Helena, Ascension and Tristan da Cunha',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Martin (French part)',
  'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten (Dutch part)',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan (the)',
  'Suriname',
  'Svalbard and Jan Mayen',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic',
  'Taiwan',
  'Tajikistan',
  'Tanzania, United Republic of',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates (the)',
  'United Kingdom of Great Britain and Northern Ireland (the)',
  'United States of America (the)',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela (Bolivarian Republic of)',
  'Viet Nam',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe',
];

export const countryStateCites: { [key: string]: string[] } = {
  Afghanistan: ['Kabul', 'Herat', 'Kandahar', 'Mazar-i-Sharif'],
  Albania: ['Tirana', 'Durrës', 'Vlorë', 'Shkodër'],
  Algeria: ['Algiers', 'Oran', 'Constantine', 'Annaba'],
  'American Samoa': ['Pago Pago', 'American Samoa'],
  Andorra: ['Andorra la Vella', 'Escaldes-Engordany'],
  Angola: ['Luanda', 'Benguela', 'Huambo', 'Lobito'],
  Anguilla: ['The Valley'],
  Antarctica: ['McMurdo Station', 'Amundsen-Scott South Pole Station'],
  'Antigua and Barbuda': ["St. John's", 'Antigua'],
  Argentina: ['Buenos Aires', 'Córdoba', 'Rosario', 'Mendoza'],
  Armenia: ['Yerevan', 'Gyumri', 'Vagharshapat', 'Vanadzor'],
  Aruba: ['Oranjestad'],
  Australia: ['Sydney', 'Melbourne', 'Brisbane', 'Perth'],
  Austria: ['Vienna', 'Graz', 'Linz', 'Salzburg'],
  Azerbaijan: ['Baku', 'Ganja', 'Lankaran', 'Mingachevir'],
  'Bahamas (the)': ['Nassau', 'Freeport', 'Abaco'],
  Bahrain: ['Manama', 'Riffa', 'Muharraq'],
  Bangladesh: ['Dhaka', 'Chittagong', 'Khulna', 'Rajshahi'],
  Barbados: ['Bridgetown'],
  Belarus: ['Minsk', 'Gomel', 'Brest', 'Vitebsk'],
  Belgium: ['Brussels', 'Antwerp', 'Ghent', 'Bruges'],
  Belize: ['Belmopan', 'Belize City'],
  Benin: ['Cotonou', 'Porto-Novo', 'Parakou'],
  Bermuda: ['Hamilton'],
  Bhutan: ['Thimphu', 'Punakha', 'Paro'],
  'Bolivia (Plurinational State of)': [
    'Sucre',
    'La Paz',
    'Santa Cruz de la Sierra',
    'Cochabamba',
  ],
  'Bonaire, Sint Eustatius and Saba': ['Kralendijk'],
  'Bosnia and Herzegovina': ['Sarajevo', 'Banja Luka', 'Tuzla', 'Zenica'],
  Botswana: ['Gaborone', 'Francistown', 'Maun'],
  'Bouvet Island': [],
  Brazil: ['São Paulo', 'Rio de Janeiro', 'Salvador', 'Belo Horizonte'],
  'British Indian Ocean Territory (the)': [],
  'Brunei Darussalam': ['Bandar Seri Begawan'],
  Bulgaria: ['Sofia', 'Plovdiv', 'Varna', 'Burgas'],
  'Burkina Faso': ['Ouagadougou', 'Bobo-Dioulasso'],
  Burundi: ['Gitega', 'Bujumbura'],
  'Cabo Verde': ['Praia', 'Mindelo'],
  Cambodia: ['Phnom Penh', 'Siem Reap', 'Battambang'],
  Cameroon: ['Yaoundé', 'Douala', 'Bamenda', 'Garoua'],
  Canada: ['Toronto', 'Vancouver', 'Montreal', 'Calgary'],
  'Cayman Islands (the)': ['George Town'],
  'Central African Republic (the)': ['Bangui'],
  Chad: ["N'Djamena"],
  Chile: ['Santiago', 'Valparaíso', 'Concepción'],
  China: ['Beijing', 'Shanghai', 'Guangzhou', 'Shenzhen'],
  'Christmas Island': ['Flying Fish Cove'],
  'Cocos (Keeling) Islands (the)': ['West Island'],
  Colombia: ['Bogotá', 'Medellín', 'Cali', 'Barranquilla'],
  'Comoros (the)': ['Moroni'],
  'Congo (the Democratic Republic of the)': ['Kinshasa', 'Lubumbashi', 'Goma'],
  'Congo (the)': ['Brazzaville', 'Pointe-Noire'],
  'Cook Islands (the)': ['Avarua'],
  'Costa Rica': ['San José', 'Alajuela', 'Cartago'],
  Croatia: ['Zagreb', 'Split', 'Dubrovnik', 'Rijeka'],
  Cuba: ['Havana', 'Santiago de Cuba', 'Camagüey'],
  Curaçao: ['Willemstad'],
  Cyprus: ['Nicosia', 'Limassol', 'Larnaca'],
  Czechia: ['Prague', 'Brno', 'Ostrava'],
  "Côte d'Ivoire": ['Abidjan', 'Yamoussoukro', 'Bouaké'],
  Denmark: ['Copenhagen', 'Aarhus', 'Odense', 'Aalborg'],
  Djibouti: ['Djibouti'],
  Dominica: ['Roseau'],
  'Dominican Republic (the)': ['Santo Domingo', 'Santiago', 'Punta Cana'],
  Ecuador: ['Quito', 'Guayaquil', 'Cuenca'],
  Egypt: ['Cairo', 'Alexandria', 'Giza'],
  'El Salvador': ['San Salvador', 'Santa Ana', 'San Miguel'],
  'Equatorial Guinea': ['Malabo', 'Bata'],
  Eritrea: ['Asmara'],
  Estonia: ['Tallinn', 'Tartu', 'Narva'],
  Eswatini: ['Mbabane', 'Manzini', 'Lobamba'],
  Ethiopia: ['Addis Ababa', 'Dire Dawa', 'Mekelle'],
  'Falkland Islands (the) [Malvinas]': ['Stanley'],
  'Faroe Islands (the)': ['Tórshavn', 'Klaksvík', 'Runavík'],
  Fiji: ['Suva', 'Nadi', 'Lautoka'],
  Finland: ['Helsinki', 'Espoo', 'Tampere'],
  France: ['Paris', 'Marseille', 'Lyon', 'Toulouse'],
  'French Guiana': ['Cayenne'],
  'French Polynesia': ['Papeete', "Faa'a", 'Moorea'],
  'French Southern Territories (the)': [],
  Gabon: ['Libreville', 'Port-Gentil', 'Franceville'],
  'Gambia (the)': ['Banjul', 'Serrekunda'],
  Georgia: ['Tbilisi', 'Kutaisi', 'Batumi'],
  Germany: ['Berlin', 'Munich', 'Frankfurt', 'Hamburg'],
  Ghana: ['Accra', 'Kumasi', 'Takoradi'],
  Gibraltar: ['Gibraltar'],
  Greece: ['Athens', 'Thessaloniki', 'Patras'],
  Greenland: ['Nuuk', 'Sisimiut', 'Ilulissat'],
  Grenada: ["St. George's"],
  Guadeloupe: ['Basse-Terre', 'Pointe-à-Pitre'],
  Guam: ['Hagatna'],
  Guatemala: ['Guatemala City', 'Antigua Guatemala', 'Quetzaltenango'],
  Guernsey: ['St. Peter Port'],
  Guinea: ['Conakry', 'Kankan', 'Nzérékoré'],
  'Guinea-Bissau': ['Bissau'],
  Guyana: ['Georgetown'],
  Haiti: ['Port-au-Prince', 'Cap-Haïtien', 'Jacmel'],
  'Heard Island and McDonald Islands': [],
  'Holy See (the)': ['Vatican City'],
  Honduras: ['Tegucigalpa', 'San Pedro Sula', 'La Ceiba'],
  'Hong Kong': ['Hong Kong'],
  Hungary: ['Budapest', 'Debrecen', 'Szeged'],
  Iceland: ['Reykjavík', 'Akureyri'],
  India: ['New Delhi', 'Mumbai', 'Bengaluru', 'Chennai'],
  Indonesia: ['Jakarta', 'Bali', 'Surabaya', 'Medan'],
  'Iran (Islamic Republic of)': ['Tehran', 'Isfahan', 'Shiraz', 'Tabriz'],
  Iraq: ['Baghdad', 'Basra', 'Erbil', 'Mosul'],
  Ireland: ['Dublin', 'Cork', 'Limerick', 'Galway'],
  'Isle of Man': ['Douglas'],
  Israel: ['Jerusalem', 'Tel Aviv', 'Haifa'],
  Italy: ['Rome', 'Milan', 'Naples', 'Florence'],
  Jamaica: ['Kingston', 'Montego Bay', 'Ocho Rios'],
  Japan: ['Tokyo', 'Osaka', 'Kyoto', 'Nagoya'],
  Jersey: ['St. Helier'],
  Jordan: ['Amman', 'Irbid', 'Aqaba'],
  Kazakhstan: ['Almaty', 'Nur-Sultan', 'Shymkent'],
  Kenya: ['Nairobi', 'Mombasa', 'Kisumu', 'Eldoret'],
  Kiribati: ['Tarawa'],
  "Korea (the Democratic People's Republic of)": ['Pyongyang', 'Kaesong'],
  'Korea (the Republic of)': ['Seoul', 'Busan', 'Incheon', 'Daegu'],
  Kuwait: ['Kuwait City'],
  Kyrgyzstan: ['Bishkek', 'Osh', 'Jalal-Abad'],
  "Lao People's Democratic Republic (the)": [
    'Vientiane',
    'Luang Prabang',
    'Pakse',
  ],
  Latvia: ['Riga', 'Daugavpils', 'Liepāja'],
  Lebanon: ['Beirut', 'Tripoli', 'Sidon'],
  Lesotho: ['Maseru', 'Teyateyaneng'],
  Liberia: ['Monrovia', 'Gbarnga', 'Buchanan'],
  Libya: ['Tripoli', 'Benghazi', 'Misrata'],
  Liechtenstein: ['Vaduz', 'Schaan'],
  Lithuania: ['Vilnius', 'Kaunas', 'Klaipėda'],
  Luxembourg: ['Luxembourg City'],
  Macao: ['Macao'],
  Madagascar: ['Antananarivo', 'Toamasina', 'Antsiranana'],
  Malawi: ['Lilongwe', 'Blantyre', 'Mzuzu'],
  Malaysia: ['Kuala Lumpur', 'George Town', 'Kota Kinabalu'],
  Maldives: ['Malé', 'Addu City'],
  Mali: ['Bamako', 'Ségou', 'Mopti'],
  Malta: ['Valletta', 'Sliema', 'Birkirkara'],
  'Marshall Islands (the)': ['Majuro', 'Ebeye'],
  Martinique: ['Fort-de-France'],
  Mauritania: ['Nouakchott', 'Nouadhibou'],
  Mauritius: ['Port Louis', 'Curepipe', 'Quatre Bornes'],
  Mayotte: ['Mamoudzou'],
  Mexico: ['Mexico City', 'Guadalajara', 'Monterrey'],
  'Micronesia (Federated States of)': ['Palikir'],
  'Moldova (the Republic of)': ['Chișinău', 'Bălți'],
  Monaco: ['Monaco'],
  Mongolia: ['Ulaanbaatar', 'Erdenet', 'Darkhan'],
  Montenegro: ['Podgorica', 'Herceg Novi'],
  Montserrat: ['Plymouth'],
  Morocco: ['Rabat', 'Casablanca', 'Marrakech'],
  Mozambique: ['Maputo', 'Beira', 'Nampula'],
  Myanmar: ['Naypyidaw', 'Yangon', 'Mandalay'],
  Namibia: ['Windhoek', 'Swakopmund', 'Walvis Bay'],
  Nauru: ['Yaren'],
  Nepal: ['Kathmandu', 'Pokhara', 'Biratnagar'],
  'Netherlands (the)': ['Amsterdam', 'Rotterdam', 'The Hague'],
  'New Caledonia': ['Nouméa'],
  'New Zealand': ['Wellington', 'Auckland', 'Christchurch'],
  Nicaragua: ['Managua', 'León', 'Granada'],
  'Niger (the)': ['Niamey', 'Zinder'],
  Nigeria: [
    'Abuja',
    'Lagos',
    'Kano',
    'Ibadan',
    'Abia',
    'Adamawa',
    'Akwa Ibom',
    'Anambra',
    'Bauchi',
    'Bayelsa',
    'Benue',
    'Borno',
    'Cross River',
    'Delta',
    'Ebonyi',
    'Edo',
    'Ekiti',
    'Enugu',
    'Gombe',
    'Imo',
    'Jigawa',
    'Kaduna',
    'Kogi',
    'Kogi',
    'Katsina',
    'Kano',
    'Kogi',
    'Kwara',
    'Lagos',
    'Nasarawa',
    'Niger',
    'Ogun',
    'Ondo',
    'Osun',
    'Oyo',
    'Plateau',
    'Rivers',
    'Sokoto',
    'Taraba',
    'Yobe',
    'Zamfara',
  ],
  Niue: ['Alofi'],
  'Norfolk Island': ['Kingston'],
  'Northern Mariana Islands (the)': ['Saipan', 'Tinian', 'Rota'],
  Norway: ['Oslo', 'Bergen', 'Stavanger', 'Trondheim'],
  Oman: ['Muscat', 'Salalah', 'Sohar'],
  Pakistan: ['Islamabad', 'Karachi', 'Lahore', 'Rawalpindi'],
  Palau: ['Ngerulmud'],
  'Palestine, State of': ['East Jerusalem', 'Ramallah', 'Gaza'],
  Panama: ['Panama City', 'Colón', 'David'],
  'Papua New Guinea': ['Port Moresby', 'Lae', 'Mount Hagen'],
  Paraguay: ['Asunción', 'Ciudad del Este', 'Encarnación'],
  Peru: ['Lima', 'Arequipa', 'Cusco'],
  'Philippines (the)': ['Manila', 'Quezon City', 'Davao', 'Cebu City'],
  Pitcairn: ['Adamstown'],
  Poland: ['Warsaw', 'Kraków', 'Wrocław', 'Gdańsk'],
  Portugal: ['Lisbon', 'Porto', 'Coimbra', 'Braga'],
  'Puerto Rico': ['San Juan', 'Ponce', 'Bayamón'],
  Qatar: ['Doha'],
  'Republic of North Macedonia': ['Skopje', 'Bitola'],
  Romania: ['Bucharest', 'Cluj-Napoca', 'Timișoara'],
  'Russian Federation (the)': [
    'Moscow',
    'Saint Petersburg',
    'Novosibirsk',
    'Yekaterinburg',
  ],
  Rwanda: ['Kigali', 'Butare'],
  Réunion: ['Saint-Denis'],
  'Saint Barthélemy': ['Gustavia'],
  'Saint Helena, Ascension and Tristan da Cunha': ['Jamestown'],
  'Saint Kitts and Nevis': ['Basseterre', 'Charlestown'],
  'Saint Lucia': ['Castries'],
  'Saint Martin (French part)': ['Marigot'],
  'Saint Pierre and Miquelon': ['Saint-Pierre'],
  'Saint Vincent and the Grenadines': ['Kingstown'],
  Samoa: ['Apia'],
  'San Marino': ['San Marino'],
  'Sao Tome and Principe': ['São Tomé', 'Principe'],
  'Saudi Arabia': ['Riyadh', 'Jeddah', 'Dammam', 'Mecca'],
  Senegal: ['Dakar', 'Saint-Louis'],
  Serbia: ['Belgrade', 'Novi Sad', 'Niš'],
  Seychelles: ['Victoria'],
  'Sierra Leone': ['Freetown'],
  Singapore: ['Singapore'],
  'Sint Maarten (Dutch part)': ['Philipsburg'],
  Slovakia: ['Bratislava', 'Košice'],
  Slovenia: ['Ljubljana', 'Maribor'],
  'Solomon Islands': ['Honiara'],
  Somalia: ['Mogadishu', 'Hargeisa'],
  'South Africa': ['Pretoria', 'Cape Town', 'Durban'],
  'South Georgia and the South Sandwich Islands': [],
  'South Sudan': ['Juba'],
  Spain: ['Madrid', 'Barcelona', 'Valencia', 'Seville'],
  'Sri Lanka': ['Colombo', 'Kandy', 'Galle'],
  'Sudan (the)': ['Khartoum', 'Omdurman'],
  Suriname: ['Paramaribo'],
  'Svalbard and Jan Mayen': ['Longyearbyen'],
  Sweden: ['Stockholm', 'Gothenburg', 'Malmö'],
  Switzerland: ['Zurich', 'Geneva', 'Bern', 'Basel'],
  'Syrian Arab Republic': ['Damascus', 'Aleppo', 'Homs'],
  Taiwan: ['Taipei', 'Kaohsiung', 'Taichung'],
  Tajikistan: ['Dushanbe', 'Khujand', 'Khorugh'],
  'Tanzania, United Republic of': ['Dodoma', 'Dar es Salaam', 'Arusha'],
  Thailand: ['Bangkok', 'Chiang Mai', 'Pattaya'],
  'Timor-Leste': ['Dili'],
  Togo: ['Lomé'],
  Tokelau: [],
  Tonga: ['Nukuʻalofa'],
  'Trinidad and Tobago': ['Port of Spain', 'San Fernando'],
  Tunisia: ['Tunis', 'Sfax', 'Sousse'],
  Turkey: ['Ankara', 'Istanbul', 'Izmir'],
  Turkmenistan: ['Ashgabat', 'Turkmenabat'],
  Tuvalu: ['Funafuti'],
  Uganda: ['Kampala', 'Entebbe', 'Gulu'],
  Ukraine: ['Kyiv', 'Kharkiv', 'Lviv', 'Odessa'],
  'United Arab Emirates (the)': ['Abu Dhabi', 'Dubai', 'Sharjah'],
  'United Kingdom of Great Britain and Northern Ireland (the)': [
    'London',
    'Birmingham',
    'Manchester',
    'Edinburgh',
  ],
  'United States of America (the)': [
    'Washington, D.C.',
    'New York',
    'Los Angeles',
    'Chicago',
  ],
  Uruguay: ['Montevideo', 'Salto', 'Paysandú'],
  Uzbekistan: ['Tashkent', 'Samarkand', 'Bukhara'],
  Vanuatu: ['Port Vila'],
  'Venezuela (Bolivarian Republic of)': ['Caracas', 'Maracaibo', 'Valencia'],
  'Viet Nam': ['Hanoi', 'Ho Chi Minh City', 'Da Nang'],
  'Western Sahara': ['Laayoune'],
  Yemen: ["Sana'a", 'Aden'],
  Zambia: ['Lusaka', 'Kitwe', 'Ndola'],
  Zimbabwe: ['Harare', 'Bulawayo', 'Mutare'],
};
