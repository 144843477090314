import React, { useState } from 'react';

import './QuizReview.css';
import { QuestionReviewI } from '../../types/Questions';
import { options } from './QuizReviewData';
import { GoChevronDown } from 'react-icons/go';
import { CloseSvg } from '../Svgs/svgsComponents';

const QuizzReview = ({
  questions,
  setIsOpenQuizReview,
}: {
  questions: QuestionReviewI[];
  setIsOpenQuizReview: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [dropDownValue, setDropDownValue] = useState('All questions');
  const [showDropDown, setShowDropDown] = useState(false);

  const [questionsReview, setQuestoinReview] = useState(questions);

  const handleReviewSelection = (option: string) => {
    switch (option) {
      case 'All questions':
        setQuestoinReview(questions);
        break;
      case 'Correct questions':
        setQuestoinReview(
          questions.filter((question) => {
            if (question.answer === question.optionSelected) {
              return question;
            }

            return null;
          })
        );
        break;
      case 'Incorrect questions':
        setQuestoinReview(
          questions.filter((question) => {
            if (
              question.answer !== question.optionSelected &&
              question.optionSelected !== ''
            ) {
              return question;
            }
            return null;
          })
        );
        break;
      case 'Missed questions':
        setQuestoinReview(
          questions.filter((question) => {
            if (question.optionSelected === '') {
              return question;
            }

            return null;
          })
        );
        break;
      default:
        setQuestoinReview(questions);
    }
  };

  return (
    <section
      className="trvia-bg question-width "
      onClick={(e) => e.stopPropagation()}
    >
      <div className=" review">
        <div className="review_header-con">
          <p className="review-header-content">Review question</p>
          <div className="review__header">
            <div
              className="review__close"
              onClick={() => setIsOpenQuizReview(false)}
            >
              {CloseSvg()}
            </div>

            {/* Drop down */}
            <div className="drop-down">
              <div
                className="drop-down__value"
                onClick={() => setShowDropDown((prevState) => !prevState)}
              >
                <p>{dropDownValue}</p>

                <GoChevronDown />
              </div>
              {showDropDown && (
                <div className="drop-down__options">
                  {options.map((option, index) => (
                    <p
                      key={index}
                      onClick={() => {
                        setDropDownValue(option);
                        handleReviewSelection(option);
                        setShowDropDown(false);
                      }}
                      className={`${
                        option === dropDownValue ? 'removeView' : ''
                      } drop-down__option`}
                    >
                      {option}
                    </p>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Questions */}
        <div className="questions-container">
          {questionsReview &&
            questionsReview.map((question, index) => (
              <div className="question" key={index}>
                <div className="question__header">
                  <div className="question__header-question">
                    <h3 className="question-number">Question {index + 1}:</h3>
                    <h3
                      className={`
                    ${
                      question?.optionSelected === ''
                        ? 'missed'
                        : question?.optionSelected === question.answer
                        ? 'correct'
                        : 'wrong'
                    }
                    `}
                    >
                      {question?.optionSelected === ''
                        ? 'Missed'
                        : question?.optionSelected === question.answer
                        ? 'Correct'
                        : 'Incorrect'}
                    </h3>
                  </div>
                  <p className="review-speed">
                    Speed: {question.speed} seconds
                  </p>
                </div>

                <p>{question?.question}</p>

                <div className="options">
                  {question &&
                    question?.options.map((optionVal, optionIndex) => (
                      <div
                        className={`option ${
                          optionVal === question.answer
                            ? 'bg-correct'
                            : optionVal === question?.optionSelected &&
                              optionVal !== question.answer
                            ? 'bg-wrong'
                            : null
                        } `}
                        key={optionIndex}
                      >
                        <div className="option__left">
                          <div
                            className={`


                           outer-radio`}
                          >
                            <div
                              className={`


                            ${
                              optionVal === question?.optionSelected
                                ? 'selected-Option-inner'
                                : null
                            }

                          `}
                            />
                          </div>

                          <p>{optionVal}</p>
                        </div>
                        {question.optionSelected !== '' &&
                          question.optionSelected === optionVal && (
                            <p
                              className={`
                                ${
                                  question?.optionSelected === ''
                                    ? 'missed'
                                    : question?.optionSelected ===
                                      question.answer
                                    ? 'correct'
                                    : 'wrong'
                                }
                                `}
                            >
                              {question?.optionSelected === question.answer
                                ? '(Correct)'
                                : '(Incorrect)'}
                            </p>
                          )}
                      </div>
                    ))}
                </div>

                <div className="question-explain">
                  <p className="question-explain__header">Explanation</p>
                  <p>{question.explain}</p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default QuizzReview;
