import { gql } from '@apollo/client';

export const UPLOAD_FILES = gql`
  mutation UploadImageToQuestion($files: [Upload!]!) {
    uploadImageToQuestion(files: $files) {
      imageUrls
    }
  }
`;

export const DELETE_UPLOADED_IMAGE = gql`
  mutation DeleteImageFromQuestion($deleteQsImagesInput: DeleteQsImagesInput!) {
    deleteImageFromQuestion(deleteQsImagesInput: $deleteQsImagesInput) {
      message
    }
  }
`;

export const UPLOAD_PROFILE_IMAGE = gql`
  mutation UploadProfileImage($file: Upload!) {
    uploadProfileImage(file: $file) {
      secure_url
    }
  }
`;
