import React, { useEffect, useRef, useState } from 'react';

import './ConfirmOtp.js.css';
const ConfirmOtp = ({
  isOtpEnter,
  setEnterOtp,
}: {
  isOtpEnter: boolean;
  setEnterOtp: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const inputRefs = useRef<HTMLInputElement[] | null[]>([]);

  const handleChange = (element: HTMLInputElement, index: number) => {
    const value = element.value.replace(/[^0-9]/g, '');

    if (value.length > 1) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    // Update prop otp
    setEnterOtp(newOtp.join(''));

    if (value && index < otp.length - 1) {
      inputRefs!.current[index + 1]!.focus();
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const pasteData = event.clipboardData
      .getData('text')
      .split('')
      .slice(0, otp.length);

    const newOtp = [...otp];

    pasteData.forEach((value, index) => {
      newOtp[index] = value;
    });

    setOtp(newOtp);

    // Update prop otp
    setEnterOtp(newOtp.join(''));

    inputRefs!.current[pasteData.length - 1]!.focus();
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (event.key === 'Backspace' && otp[index] === '' && index > 0) {
      inputRefs!.current[index - 1]!.focus();
    }
  };

  // Use Effect

  useEffect(() => {
    if (isOtpEnter) {
      inputRefs!.current[0]!.focus();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOtpEnter]);

  // Spinier loader

  return (
    <div className="confirm-otp">
      <div className="confirm-otp__otp">
        <div>
          <div className="confirm-otps ">
            {otp.map((value, index) => (
              <input
                key={index}
                type="text"
                maxLength={1}
                value={value}
                onChange={(e) => handleChange(e.target, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                onPaste={handlePaste}
                ref={(el) => (inputRefs.current[index] = el)}
                className="otp-input"
                required
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmOtp;
