import { gql } from '@apollo/client';

export const GET_QUESTION_BANK = gql`
  query GetAllQuiz {
    getAllQuiz {
      category
      coverImage
      description
      duration
      point
      quizCategory {
        customCat
        customCatId
      }
      quizUUID
      topic
      totalDuration
      totalQuestion
    }
  }
`;

export const GET_QUESTION_BANK_CAT = gql`
  query GetQBQuizCategory($query: String) {
    getQBQuizCategory(query: $query) {
      customCat
      customCatId
    }
  }
`;

export const GET_QUESTION_BANK_QUIZ = gql`
  query GetQuesFromQuesBank($quizUuid: String!) {
    getQuesFromQuesBank(quizUUID: $quizUuid) {
      answer {
        answer
        answer_details
        id
        reference
      }
      question
      questionNumber
      questionUUID
      quizUUID
      images
      options {
        id
        value
      }
    }
  }
`;

export const GET_QUIZ = gql`
  query GetQuiz($quizUuid: String!) {
    getQuiz(quizUUID: $quizUuid) {
      coverImage
      description
      duration
      point
      quizUUID
      topic
      quizCategory {
        customCat
        customCatId
      }
    }
  }
`;
