import React, { useEffect, useState } from 'react';
import '../CircularProgressCountDown/CircularProgressCountDown.css';

const SocketCirculerCountDown = ({
  timeLeft,
  duration,
  pauseTime,
  timePauseValue,
}: {
  timeLeft: number;
  duration: number;
  timePauseValue: number;
  pauseTime: boolean;
}) => {
  const radius = 40;
  const circumference = 2 * Math.PI * radius;

  const [timerValue, setTimerValue] = useState<number>(duration);

  const progress = (1 - timerValue / duration) * circumference;

  useEffect(() => {
    setTimerValue(pauseTime ? timePauseValue : timeLeft);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pauseTime, timeLeft]);

  return (
    <div className="progress-container">
      <svg className="progress-ring" width="100" height="110">
        <circle
          className="progress-ring__circle"
          stroke="#349AFF"
          strokeWidth="6"
          fill="transparent"
          r={radius}
          cx="50"
          cy="50"
          style={{ strokeDasharray: circumference, strokeDashoffset: progress }}
        />
      </svg>
      <div className="progress-text">{timerValue}s</div>
    </div>
  );
};

export default SocketCirculerCountDown;
