import React from 'react';
import { DNA } from 'react-loader-spinner';
import './SpinnerLoader.css';

const SpinnerLoader = ({ loading }: { loading: boolean }) => {
  return (
    <>
      {loading && (
        <div className="spinner">
          <DNA
            visible={true}
            height="130"
            width="130"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
        </div>
      )}
    </>
  );
};

export default SpinnerLoader;
