import {
  logoutIconSvg,
  // notifictionIconSvg,
  profileIconSvg,
  subscriptionIconSvg,
  shieldIconSvg,
} from '../Svgs/svgsComponents';

export const settingSidebarData = [
  {
    title: 'Profile',
    icon: (fill) => profileIconSvg(fill),
  },

  {
    title: 'Subscription',
    icon: (fill) => subscriptionIconSvg(fill),
  },

  {
    title: 'Top up',
    icon: (fill) => shieldIconSvg(fill),
  },

  // {
  //   title: 'Change password',
  //   icon: () => shieldIconSvg(),
  // },

  // {
  //   title: 'Notification',
  //   icon: () => notifictionIconSvg(),
  // },

  {
    title: 'Logout',
    icon: (fill) => logoutIconSvg(),
  },
];
