import React from 'react';
import './Hero.css';
import LazyLoad from 'react-lazyload';
import {
  AtomSvg,
  ConnectSvg,
  GalaxySvg,
} from '../../../../components/Svgs/svgsComponents';
import { useNavigate } from 'react-router-dom';
import { ReactTyped } from 'react-typed';
import { GetDownloadLink } from '../../../../Utils/generateDownloadLink';

const Hero: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    const directLink = GetDownloadLink();
    window.location.href = directLink;
  };

  return (
    <section>
      <div className="container hero">
        <div className="hero__ai-power gradiat-border">Powered by OpenAI</div>

        <div className="typing-hero">
          <h1 className={' hero_header'}>MedScroll offers</h1>
          <ReactTyped
            strings={[
              `a vibrant platform for mastering medical knowledge.`,
              `AI-powered personalized learning with tailored feedback.`,
              `gamified experiences to make learning enjoyable and effective.`,
              `a supportive community for collaboration and growth.`,
            ]}
            typeSpeed={50} // Typing speed in milliseconds
            backSpeed={50} // Backspacing speed
            loop={true} // Set to true if you want to loop the effect
            cursorChar="|"
            style={{}}
          />
        </div>
        <div className="hero__buttons">
          <div className="hero__get-started" onClick={handleClick}>
            Get started
          </div>

          <div
            className="hero__join-game gradiat-border"
            onClick={() => navigate('/dashboard/multiplyer-invited')}
          >
            Join a game
          </div>
        </div>
      </div>

      <div className="hero__robot-container">
        <img
          src="/images/ellipse-hero.png"
          className="decor decor-ellispes"
          alt="ellipse"
        />

        <div className=" decor decor-galaxy-white">
          {GalaxySvg()}

          <span className="decor-atom">{AtomSvg()}</span>
        </div>

        <div className="decor decor-galaxy-colored-left">
          {GalaxySvg('#349AFF')}
        </div>

        <LazyLoad offset={100} className="hero__robot-doc-img">
          <div className="decor decor-galaxy-colored-right">
            {GalaxySvg('#349AFF')}
          </div>

          <div className="decor decor-connect-right">{ConnectSvg()}</div>

          <img src="/images/hero-robot.png" alt="robot doctor" />
        </LazyLoad>

        <div className="decor decor-connect-bottom-mid">{ConnectSvg()}</div>
      </div>
    </section>
  );
};

export default Hero;
