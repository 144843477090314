import {
  DashboardCreateSvg,
  DashboardHomeSvg,
  // DashboardFriendsSvg,
  // DashboardIcon,
  DashboardSettingsSvg,
  logoutIconSvg,
} from '../../components/Svgs/svgsComponents';

export const sideBarData = [
  {
    title: 'Home',
    icon: (fill) => DashboardHomeSvg(fill),
    link: '/dashboard',
  },

  {
    title: 'Create',
    icon: (fill) => DashboardCreateSvg(fill),
    link: '/dashboard/create',
  },

  // {
  //   title: 'Friends',
  //   icon: (fill) => DashboardFriendsSvg(fill),
  //   link: '/dashboard/friends',
  // },

  // {
  //   title: 'Dashboard',
  //   icon: (fill) => DashboardIcon(fill),

  //   link: '/dashboard/dashboard',
  // },

  {
    title: 'Settings',
    icon: (fill) => DashboardSettingsSvg(fill),
    link: '/dashboard/settings',
  },

  {
    title: 'Logout',
    icon: (fill) => logoutIconSvg(),
    link: '',
  },
];
