import React, { useState } from 'react';

import './DashboardSettings.css';
import DashboardSettingsSidebar from '../../components/DashboardSettingsSidebar/DashboardSettingsSidebar';
import { DashboardProfile } from '../../components/DashboardProfile/DashboardProfile';
import { FaChevronDown } from 'react-icons/fa6';
import { settingSidebarData } from '../../components/DashboardSettingsSidebar/DashboardSettingsSidebarData';
import ChangePassword from '../../components/ChangePassword/ChangePassword';
import DashboardTopup from '../../components/DashboardTopup/DashboardTopup';
import DashboardSubscription from '../DashboardSubscription/DashboardSubscription';

const DashboardSettings = () => {
  const [curAciveSideIndex, setCurActiveSideIndex] = useState(0);
  const [curShowSettingIndex, setCurShowSettingIndex] = useState('Profile');
  const [isSettingSideOPen, setIsSettingSideOPen] = useState(true);

  return (
    <div className="main-setting-container">
      <div className="show-desktop setting-main-content">
        <div
          style={{
            transform: `${isSettingSideOPen ? 'scaleX(-1)' : ''}`,
            left: `${isSettingSideOPen ? '' : '-10px'}`,
          }}
          className="dashboard-setting-sidebar__animated-arrow"
          onClick={() => setIsSettingSideOPen((prev) => !prev)}
        >
          <img src="/images/arrow-animate-white.gif" alt="arrow animate" />
        </div>
        <DashboardSettingsSidebar
          curIndex={curAciveSideIndex}
          setCurIndex={setCurActiveSideIndex}
          setIsOpen={setIsSettingSideOPen}
          isOpen={isSettingSideOPen}
        />

        <div
          style={{
            paddingLeft: `${isSettingSideOPen ? '' : '4rem'}`,
          }}
          className="setting-right-side"
        >
          {
            {
              0: <DashboardProfile />,
              1: <DashboardSubscription />,
              2: <DashboardTopup />,
            }[curAciveSideIndex]
          }
        </div>
      </div>

      <div className="show-mobile">
        {settingSidebarData.map(({ title, icon }, index) => (
          <div key={index} className="mobile-setting">
            {
              {
                Profile: (
                  <>
                    {' '}
                    <div
                      className="mobile-setting__header"
                      onClick={() => setCurShowSettingIndex(title)}
                    >
                      <div className="indicator">
                        <p>{title}</p>
                      </div>

                      <FaChevronDown />
                    </div>
                    {curShowSettingIndex === title && <DashboardProfile />}
                  </>
                ),

                'Change password': (
                  <>
                    {' '}
                    <div
                      className="mobile-setting__header"
                      onClick={() => setCurShowSettingIndex(title)}
                    >
                      <div className="indicator">
                        <p>{title}</p>
                      </div>

                      <FaChevronDown />
                    </div>
                    {curShowSettingIndex === title && <ChangePassword />}
                  </>
                ),

                'Top up': (
                  <>
                    <div
                      className="mobile-setting__header"
                      onClick={() => setCurShowSettingIndex(title)}
                    >
                      <div className="indicator">
                        <p>{title}</p>
                      </div>

                      <FaChevronDown />
                    </div>
                    {curShowSettingIndex === title && <DashboardTopup />}
                  </>
                ),

                Subscription: (
                  <>
                    <div
                      className="mobile-setting__header"
                      onClick={() => setCurShowSettingIndex(title)}
                    >
                      <div className="indicator">
                        <p>{title}</p>
                      </div>

                      <FaChevronDown />
                    </div>
                    {curShowSettingIndex === title && <DashboardSubscription />}
                  </>
                ),
              }[title]
            }
          </div>
        ))}
      </div>
    </div>
  );
};

export default DashboardSettings;
