// src/store/userSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserI } from '../types/AuthResponse';

const initialState: UserI | {} = {};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<UserI>) {
      return action.payload;
    },

    updateQuizzer(state, action: PayloadAction<object>) {
      if ('quizzer' in state) {
        return {
          ...state,
          quizzer: {
            ...(state as UserI).quizzer,
            ...action.payload,
          },
        };
      }
      return state;
    },

    updateUser(state, action: PayloadAction<object>) {
      return {
        ...state,
        ...action.payload,
        quizzer: {
          ...(state as UserI).quizzer,
        },
      };
    },

    clearUser(state) {
      return {};
    },
  },
});

export const { setUser, updateQuizzer, updateUser, clearUser } =
  userSlice.actions;
export default userSlice.reducer;
