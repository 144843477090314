import { PlayerScoreI } from '../types/socket';

export const sortLeadderBoard = (data: PlayerScoreI[]) => {
  const sortedData = data.sort((a, b) => {
    if (b.score !== a.score) {
      return b.score - a.score; // Sort by highest score
    }
    if (b.correct !== a.correct) {
      return b.correct - a.correct; // Sort by highest correct number
    }
    return a.speed - b.speed; // Sort by lowest speed
  });

  // Add position key
  sortedData.forEach((item: PlayerScoreI, index: number) => {
    item.position = index + 1;
  });

  return sortedData;
};
