export const sampleQuizData = [
  {
    question: 'Who is known as the "Father of Medicine"?',
    options: [
      { id: 101, value: 'Hippocrates' },

      { id: 102, value: 'Galen' },

      { id: 103, value: 'Avicenna' },

      { id: 104, value: 'Aristotle' },
    ],
    answer: {
      answer: 'Hippocrates',
      answer_details:
        'Hippocrates is considered the "Father of Medicine" for his contributions to the medical profession.',
    },
  },

  {
    question:
      'Hippocratic Oath, the ethical standard for physicians, originated in which culture?',
    options: [
      { id: 201, value: 'Ancient Egypt' },
      { id: 202, value: 'Ancient Greece' },
      { id: 203, value: 'Ancient Rome' },
      { id: 204, value: 'Ancient China' },
    ],
    answer: {
      answer: 'Ancient Greece',
      answer_details:
        'The Hippocratic Oath originated in Ancient Greece and set ethical standards for physicians.',
    },
  },

  {
    question: 'How many bones are in the adult human body?',
    options: [
      { id: 301, value: '206' },
      { id: 302, value: '305' },
      { id: 303, value: '187' },
      { id: 304, value: '199' },
    ],
    answer: {
      answer: '206',
      answer_details:
        'The adult human body typically has 206 bones, which supports the body structure and allows for movement.',
    },
  },

  {
    question: 'What is the longest bone in the human body?',
    options: [
      { id: 401, value: 'Femur' },

      { id: 402, value: 'Humerus' },
      { id: 403, value: 'Tibia' },
      { id: 404, value: 'Fibula' },
    ],
    answer: {
      answer: 'Femur',
      answer_details: `The Femur, or thigh bone, is the longest and strongest bone in the human body, crucial for supporting the body's weight and enabling leg movement.`,
    },
  },

  {
    question: `Galen's theory of the four humors was based on which substances?`,
    options: [
      { id: 501, value: 'Blood, phlegm, yellow bile, black bile' },
      { id: 502, value: 'Water, air, fire, earth' },
      { id: 503, value: 'Mercury, sulfur, salt' },
      { id: 504, value: 'Proteins, carbohydrates, fats, vitamins' },
    ],
    answer: {
      answer: 'Blood, phlegm, yellow bile, black bile',
      answer_details: `Galen's theory revolved around blood, phlegm, yellow bile, and black bile balancing health.`,
    },
  },

  {
    question:
      'In which civilization were public baths, as part of public health and sanitation, first developed?',
    options: [
      { id: 601, value: 'Ancient Egypt' },
      { id: 602, value: 'Ancient Greece' },
      { id: 603, value: 'Ancient Rome' },
      { id: 604, value: 'Ancient India' },
    ],
    answer: {
      answer: 'Ancient Rome',
      answer_details:
        'Public baths were developed in Ancient Rome as part of their advanced public health and sanitation efforts.',
    },
  },

  {
    question: 'How many lobes does the right lung have?',
    options: [
      { id: 701, value: '3' },
      { id: 702, value: '2' },
      { id: 703, value: '4' },
      { id: 704, value: '5' },
    ],
    answer: {
      answer: '3',
      answer_details:
        'The right lung is divided into three lobes (superior, middle, and inferior), facilitating efficient gas exchange.',
    },
  },

  {
    question: 'What is the primary component of the nails?',
    options: [
      { id: 801, value: 'Keratin' },
      { id: 802, value: 'Collagen' },
      { id: 803, value: 'Elastin' },
      { id: 804, value: 'Melanin' },
    ],
    answer: {
      answer: 'Keratin',
      answer_details:
        'Keratin, a type of protein, is the primary component of the nails, providing strength and protection.',
    },
  },

  {
    question:
      'Which plant was commonly used in ancient medicine for its sedative and pain-relieving properties?',
    options: [
      { id: 901, value: 'Willow bark' },
      { id: 902, value: 'Opium poppy' },
      { id: 903, value: 'Henbane' },
      { id: 904, value: 'Garlic' },
    ],
    answer: {
      answer: 'Opium poppy',
      answer_details:
        'The opium poppy was used for its analgesic properties in various ancient civilizations.',
    },
  },

  {
    question:
      'Who is credited with the discovery of the circulation of blood in the human body, challenging ancient medical theories?',
    options: [
      { id: 1001, value: 'William Harvey' },
      { id: 1002, value: 'Galen' },
      { id: 1003, value: 'Hippocrates' },
      { id: 1004, value: 'Avicenna' },
    ],
    answer: {
      answer: 'William Harvey',
      answer_details: `William Harvey's work on the circulation of blood was revolutionary, challenging long-standing ancient medical theories.`,
    },
  },
];
