import React, { useState } from 'react';
import AuthHeader from '../../components/AuthHeader/AuthHeader';
import { IoEyeOutline, IoEyeOffOutline } from 'react-icons/io5';
import { FaCheck } from 'react-icons/fa';
import client from '../../graphql/client';

// Share same css with login css
import '../Login/Login.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import {
  REGISTER_USER,
  VALIDATE_LOGIN_USER_OTP,
} from '../../graphql/mutatons/authentication';
import { toast } from 'react-toastify';
import SpinnerLoader from '../../components/SpinnerLoader/SpinnerLoader';
import Modal from '../../components/Modal/Modal';
import ConfirmOtp from '../../components/ConfirmOtp/ConfirmOtp';
import { useDispatch } from 'react-redux';
import { setUser } from '../../state/userSlice';
import './Signup.css';
import { useAuth } from '../../context/AuthContext';
import SubscriptionPayment from '../Subscription/Subscription';

const Signup = () => {
  const initFormState = {
    email: '',
    password: '',
    firstName: '',
    lastName: '',
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [formData, setFormData] = useState(initFormState);
  const [isEnterOtp, setIsEnterOtp] = useState(false);
  const [enterOtp, setEnterOtp] = useState('');

  const [showSubscription, setShowSubscription] = useState(false);

  const [isSubScribeInView, setIsSubScribeInView] = useState(true);

  const { authenticateUser } = useAuth();

  const location = useLocation();

  const redirectPath = location.search.replace('?', '').trim() || '';

  // function init
  const [SignUp, { loading }] = useMutation(REGISTER_USER, {
    client,
  });

  const togglePasswordEye = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleSubmitSignUp = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    await SignUp({
      variables: {
        signUpInput: formData,
      },
    })
      .then((res) => {
        toast.success(res?.data?.registerUser?.message);

        setIsEnterOtp(true);
      })
      .catch((error) => {
        toast.error(error?.message || 'Unexpected error occured');
      });
  };

  // Mutation
  const [confirmUserOtp, { loading: signUpLoading }] = useMutation(
    VALIDATE_LOGIN_USER_OTP,
    {
      client,
    }
  );

  const handleOtpSubmit = async () => {
    if (enterOtp.trim() === '') {
      toast.warn('Otp can  not be empty');
      return;
    }

    await confirmUserOtp({
      variables: {
        validateOtpInput: {
          email: formData?.email,
          otp: enterOtp,
        },
      },
    })
      .then((res) => {
        // Set token to localStorage
        localStorage.setItem(
          'token',
          res?.data.validateLoginUserOtp.access_token
        );

        //  Auth user to true
        authenticateUser();

        // Save user details to state
        dispatch(setUser({ ...res?.data?.validateLoginUserOtp?.user }));

        setShowSubscription(true);

        toast.success('Otp confimation successful');

        // Set otp to default (false)
        setIsEnterOtp(false);
      })
      .catch((err) => {
        toast.error(err?.message || 'Unexpected error occured');
      });
  };

  const handleLoginNav = () => {
    if (redirectPath) {
      navigate(`/login?${redirectPath}`);
    } else {
      navigate('/login');
    }
  };

  // Spinner
  if (loading || signUpLoading)
    return <SpinnerLoader loading={loading || signUpLoading} />;

  return (
    <div className="login">
      <AuthHeader title="Sign up" isLogin={true} />

      <form className="auth-form" onSubmit={handleSubmitSignUp}>
        <div className="auth-input-con">
          <p>First Name</p>

          <input
            type="text"
            placeholder="First name"
            value={formData.firstName}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                firstName: e.target.value,
              }))
            }
            className="input-text"
            required
          />
        </div>

        <div className="auth-input-con">
          <p>Last Name</p>

          <input
            type="text"
            placeholder="Last name"
            value={formData.lastName}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                lastName: e.target.value,
              }))
            }
            className="input-text"
            required
          />
        </div>

        <div className="auth-input-con">
          <p>Email Address</p>

          <input
            type="text"
            placeholder="Insert your email"
            value={formData.email}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                email: e.target.value,
              }))
            }
            className="input-text"
            required
          />
        </div>

        <div className="auth-input-con">
          <p>Password</p>

          <div className="password">
            <input
              type={showPassword ? 'text' : 'password'}
              placeholder="password"
              value={formData.password}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  password: e.target.value,
                }))
              }
              className="input-password"
              required
            />
            {showPassword ? (
              <IoEyeOutline onClick={() => togglePasswordEye()} />
            ) : (
              <IoEyeOffOutline onClick={() => togglePasswordEye()} />
            )}
          </div>
        </div>

        <div className="agree-con">
          <div className="check-con">
            <div
              className="custom-checkBox"
              onClick={() => setIsChecked((prevState) => !prevState)}
            >
              {isChecked && <FaCheck />}
            </div>

            <div className="term-agreement">
              <p>By continuing you agree to our</p>
              <p className="term-link">terms of use and privacy policy</p>
            </div>
          </div>
        </div>

        <button className="auth-btn" type="submit">
          Sign Up
        </button>

        <div className="auth-suggestion">
          <p>Aleady have an account?</p>

          <p
            className="suggest"
            onClick={() => {
              handleLoginNav();
            }}
          >
            Login
          </p>
        </div>
      </form>

      {isEnterOtp && (
        <Modal extraFunc={() => {}}>
          <div className="confirm-otp-modal">
            <div className="">
              <h2>One time passcode</h2>
              <p>An email with an OTP has been sent to your email</p>
            </div>

            <h3>Enter OTP Passcode</h3>

            <ConfirmOtp isOtpEnter={isEnterOtp} setEnterOtp={setEnterOtp} />

            <button className="auth-btn" onClick={() => handleOtpSubmit()}>
              Continue
            </button>
          </div>
        </Modal>
      )}

      {showSubscription && (
        <Modal extraFunc={() => {}}>
          <div className="subscription-modal">
            <button
              onClick={() => {
                setShowSubscription(false);
                // If user is redirected to login when trying to access a paticular protected route
                if (redirectPath) {
                  navigate(redirectPath);
                } else {
                  navigate('/');
                }
              }}
            >
              Maybe later
            </button>

            {/* content */}
            <div className="subscription-modal__sub-content">
              <SubscriptionPayment
                isSubScribeInView={isSubScribeInView}
                isTrail={true}
              />

              {isSubScribeInView && (
                <div className="subscription-actons">
                  <div className="subcription-actons__text">
                    <h4>7 days free trial</h4>

                    <p>
                      Unlock a week of unlimited AI-powered insights with our
                      7-day free trial.
                    </p>
                  </div>
                  <button onClick={() => setIsSubScribeInView(false)}>
                    Continue
                  </button>
                </div>
              )}
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Signup;
