export function timeToSeconds(time: string) {
  const parts = time ? time.split(':').map(Number) : null; // Split the time by ':' and convert each part to a number

  if (parts === null) return 0;

  let seconds = 0;

  if (parts.length === 3) {
    // If the format is HH:MM:SS
    const [hours, minutes, secs] = parts;
    seconds = hours * 3600 + minutes * 60 + secs;
  } else if (parts.length === 2) {
    // If the format is MM:SS
    const [minutes, secs] = parts;
    seconds = minutes * 60 + secs;
  }

  return seconds;
}

export function timeToMinutes(time: string) {
  const parts = time ? time.split(':').map(Number) : null; // Split the time by ':' and convert each part to a number
  if (parts === null) return 0;

  let result = '';

  if (parts.length === 3) {
    // If the format is HH:MM:SS
    const [hours, mins, secs] = parts;

    let resNotation = '';

    if (hours !== 0) {
      resNotation = hours > 1 ? 'hours' : 'hour';
      result =
        mins !== 0 && secs !== 0
          ? `${hours}:${mins}:${secs} ${resNotation}`
          : mins !== 0
          ? `${hours}:${mins} ${resNotation}`
          : `${hours} ${resNotation}`;
    } else if (mins !== 0) {
      resNotation = mins > 1 ? 'minutes' : 'minute';
      result =
        secs !== 0
          ? `${mins}:${secs} ${resNotation}`
          : `${mins} ${resNotation}`;
    } else {
      resNotation = secs > 1 ? 'seconds' : 'secoond';
      result = `${secs} ${resNotation}`;
    }
  }

  return result;
}
