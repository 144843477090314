import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { io, Socket } from 'socket.io-client';
import { RootState } from '../state';
import { UserI } from '../types/AuthResponse';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { EmitFunction, OnFunction } from '../types/socket';

export const SOCKET_URL = process.env.REACT_APP_WEB_SOCKET_BASE_URL; // Replace with your server URL

const useSocket = (code: unknown) => {
  const user = useSelector((state: RootState) => state.user) as UserI;

  const navigate = useNavigate();

  const token = localStorage.getItem('token');

  const [codeValue, setCodeValue] = useState(code);

  const [socket, setSocket] = useState<Socket>();

  const [isSocketConnected, setIsSocketConnected] = useState(false);

  useEffect(() => {
    // Initialize the socket connection
    if (codeValue && token) {
      const socketInstance = io(SOCKET_URL!, {
        transports: ['websocket'],
        auth: {
          // Include the Authorization token in the auth object
          authorization: `Bearer ${token}`, // Pass the Authorization token
          code: codeValue,
        },
      });

      setSocket(socketInstance);

      // Handle connection errors
      socketInstance.on('error', (err) => {
        console.error('Connection error:', err);

        toast.error(err);
        navigate(-1);
      });

      // Connectioon indication
      socketInstance.on('connect', () => {
        console.log('Socket connected');

        setIsSocketConnected(true);

        // Add user to the game
        socketInstance.emit('join-game', {
          name: user?.firstName,
        });
      });

      // Disconnection indication
      socketInstance.on('disconnect', () => {
        console.log('Socket disconnected');

        setIsSocketConnected(false);
      });

      // Cleanup on component unmount
      return () => {
        socketInstance.disconnect();
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeValue, token]);

  const emit: EmitFunction = (
    event: any,
    data: any,
    ackCallback?: (ackData?: any) => void
  ) => {
    if (socket) {
      if (ackCallback) {
        socket.emit(event, data, ackCallback);
      } else {
        socket.emit(event, data);
      }
    }
  };

  const on: OnFunction = (event: any, callback: any) => {
    if (socket) {
      socket.on(event, callback);
    }
  };

  const off: OnFunction = (event: any, callback: any) => {
    if (socket) {
      socket.off(event, callback);
    }
  };

  const handleSetCode = (code: string) => {
    setCodeValue(code);
  };

  const disConnectSocket = () => {
    socket?.emit('leave-game');
    socket?.disconnect();
  };

  return { emit, on, off, handleSetCode, disConnectSocket, isSocketConnected };
};

export default useSocket;
