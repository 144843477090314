// src/context/SocketContext.tsx
import React, { createContext, useContext, ReactNode, useMemo } from 'react';
import useSocket from '../hooks/useSocket';
import { EmitFunction, OnFunction } from '../types/socket';

// Define a type for the context value
interface SocketContextProps {
  emit: EmitFunction;
  on: OnFunction;
  off: OnFunction;
  handleSetCode: (code: string) => void;
  disConnectSocket: () => void;
  isSocketConnected: boolean;
}

interface SocketProviderProps {
  children: ReactNode;
}

// Create the context with an initial value of `null` or an empty object with default values
const SocketContext = createContext<SocketContextProps | null>(null);

export const useSocketContext = () => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error('useSocketContext must be used within a SocketProvider');
  }
  return context;
};

const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
  const { emit, on, off, handleSetCode, isSocketConnected, disConnectSocket } =
    useSocket('');

  const socketValue = useMemo(
    () => ({
      emit,
      on,
      off,
      handleSetCode,
      isSocketConnected,
      disConnectSocket,
    }),
    [emit, on, off, handleSetCode, isSocketConnected, disConnectSocket]
  );

  return (
    <SocketContext.Provider value={socketValue}>
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;
