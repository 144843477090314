import React, { useState } from 'react';
import './DashboardTopup.css';
import StripePayment from '../StripePayment/StripePayment';

const DashboardTopup = () => {
  const [creditQuantity, setCreditQuantity] = useState(1);
  const [amount, setAmount] = useState(2);

  const handleAmountUpdate = (action: string) => {
    setAmount((prev) =>
      action === 'add'
        ? parseInt((prev + 2).toFixed(2))
        : parseInt((prev - 2).toFixed(2))
    );
  };

  const handleIncreaseCredit = () => {
    setCreditQuantity((prev) => prev + 1);

    handleAmountUpdate('add');
  };

  const handleDecreaseCredit = () => {
    if (creditQuantity === 30) return null;
    else {
      setCreditQuantity((prev) => prev - 1);

      handleAmountUpdate('subtract');
    }
  };

  return (
    <div className="topup-container">
      <div className="topup-main">
        <div className="topup-left-container">
          <div className="topup-main_left">
            <div className="topup-main_left-row">
              <p>Credit Top Up</p>

              <div className="topup-main_left-row__counters">
                <div className="topup-main_left-row__counters-item">
                  <span
                    className={` ${
                      creditQuantity === 30
                        ? 'topup-main_left-row__counter__inactive '
                        : 'topup-main_left-row__counter__active'
                    } topup-main_left-row__counter`}
                    onClick={handleDecreaseCredit}
                  >
                    -
                  </span>
                  <span> {`${creditQuantity} M`}</span>
                  <span
                    className="topup-main_left-row__counter topup-main_left-row__counter__active "
                    onClick={handleIncreaseCredit}
                  >
                    +
                  </span>
                </div>

                <span>Credit</span>
              </div>
            </div>

            <div className="topup-main_left-row">
              <div className="topup-main_left-row-header">
                <p>Total</p>
                <span>Sales ends in a day 20% off</span>
              </div>

              <div className="topup-main_left-row__counters">
                <div className="topup-main_left-row__counters-item-p">
                  $ {amount}
                </div>
              </div>
            </div>

            <div className="line-through" />

            <p>All credit are to be used in a month</p>
          </div>

          <p>
            Warning!!: We don’t save any information about you and neither does
            our payment gateway. Studio will never ask for you for any private
            details.
          </p>
        </div>

        {/* Payment card */}

        <div className="topup-right-container">
          <StripePayment topUpToken={amount} />
        </div>
      </div>
    </div>
  );
};

export default DashboardTopup;
