import React, { useRef } from 'react';
import './Pitch.css';

import { androidSvg, iphoneSvg } from '../Svgs/svgsComponents';
import useCustomInVew from '../../hooks/useCustomInVew';

const Pitch = () => {
  const pitchTextRef = useRef<HTMLDivElement | null>(null);
  const pitchCardRef = useRef<HTMLDivElement | null>(null);

  const pitchTextInView = useCustomInVew(pitchTextRef);
  const pitchCardInView = useCustomInVew(pitchCardRef);

  return (
    <div className="pitch">
      <div className="container pitch-con">
        <div
          ref={pitchTextRef}
          className={`
          ${pitchTextInView ? 'scale-out' : ''}

          pitch-left`}
        >
          <div className="pitch__heading">
            <span>Ready To Transform Your Learning Experience?</span>

            <p>
              Embark on your journey with MedScroll and turn medical learning
              into an adventure.
            </p>
          </div>
        </div>
        <div
          ref={pitchCardRef}
          className={`


          ${pitchCardInView ? 'animate-slide-in-right' : ''}
          pitch__quiz-cards`}
        >
          <div className="pitch__quiz-card">
            <div className="pitch__quiz-card-header">
              <h4>For iOS</h4>

              <p>Available for all device</p>
            </div>

            <a
              href="https://apps.apple.com/ng/app/medscroll/id6483944208"
              target="_blank"
              rel="noopener noreferrer"
              className="button pitch__quiz-card-btn"
            >
              {' '}
              Download now
            </a>

            {iphoneSvg()}
          </div>

          <div className="pitch__quiz-card">
            <div className="pitch__quiz-card-header">
              <h4>For Andriod </h4>

              <p>Available for all device</p>
            </div>

            <a
              href="https://play.google.com/store/apps/details?id=com.loopscribe.medscroll&hl=en&gl=US"
              target="_blank"
              rel="noopener noreferrer"
              className="button pitch__quiz-card-btn"
            >
              {' '}
              Download now
            </a>

            {androidSvg()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pitch;
