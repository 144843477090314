import React, { useState } from 'react';
import './ForgetPassowrd.css';
import { useMutation } from '@apollo/client';
import { FORGET_PASSWORD } from '../../graphql/mutatons/authentication';
import client from '../../graphql/client';
import { toast } from 'react-toastify';
import SpinnerLoader from '../../components/SpinnerLoader/SpinnerLoader';
import { useNavigate } from 'react-router-dom';

const ForgetPassowrd = () => {
  const [formEmail, setFormEmail] = useState('');
  const navigate = useNavigate();

  const [forgetPassword, { loading }] = useMutation(FORGET_PASSWORD, {
    client,
  });

  const handleResetPasswword = async () => {
    if (formEmail.trim() === '') {
      toast.warn('Email can not be empty');
      return;
    }

    await forgetPassword({
      variables: {
        getOtpInput: {
          email: formEmail,
        },
      },
    })
      .then((res) => {
        toast.success(res?.data?.forgotPassword?.message);

        // Set mail sate to default
        setFormEmail('');

        // Navigatee to page to input otp sent and neww password
        navigate('/reset-password', {
          state: {
            email: formEmail,
          },
        });
      })
      .catch((error) => {
        toast.error(error?.message || 'Unexpected error occured');
      });
  };

  if (loading) return <SpinnerLoader loading={loading} />;

  return (
    <div className="forget-password-con">
      <div className="forget-password-main">
        <div className="forget-password-main__header">
          <h3>Forget Password</h3>
          <p> We are glad to have you here</p>
        </div>

        <form
          className="auth-form"
          onSubmit={(e) => {
            e.preventDefault();

            handleResetPasswword();
          }}
        >
          <div className="auth-input-con">
            <p>Email Address</p>

            <input
              type="text"
              placeholder="Insert your email"
              className="input-text"
              value={formEmail}
              onChange={(e) => {
                setFormEmail((prev) => e.target.value);
              }}
              required
            />
          </div>

          <button className="auth-btn" type="submit">
            Continue
          </button>
        </form>
      </div>
    </div>
  );
};

export default ForgetPassowrd;
