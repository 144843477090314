import { gql } from '@apollo/client';

// For create subscription
export const CREATE_SUBSCRIPTION = gql`
  mutation CreateSubscription($stripeSubDto: StripeSubDto!) {
    createSubscription(stripeSubDto: $stripeSubDto) {
      credits
      generalTrivia
      identifier
      maxNumQuestion
      medicalTrivia
      multiplayerCapacity
      plan
      tokenBalance
      txtLimitPerOption
      txtLimitPerQuestion
      isTrialPeriod
      topUpCredits
      productId
    }
  }
`;

// For Update subscription
export const UPDATE_SUBSCRIPTION = gql`
  mutation UpdateSubscription($updateStripeSubDto: UpdateStripeSubDto!) {
    updateSubscription(updateStripeSubDto: $updateStripeSubDto) {
      credits
      generalTrivia
      identifier
      imageUploadPerQues
      isActive
      isTrialPeriod
      maxNumQuestion
      medicalTrivia
      multiplayerCapacity
      plan
      productId
      storage
      tokenBalance
      topUpCredits
      txtLimitPerOption
      txtLimitPerQuestion
    }
  }
`;

// For topup
export const MAKE_PAYMENT = gql`
  mutation MakePayment($stripePaymentDto: StripePaymentDto!) {
    makePayment(stripePaymentDto: $stripePaymentDto) {
      client_secret
      id
      status
    }
  }
`;
