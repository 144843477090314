export const navLinkData = [
  // {
  //   text: 'About us',
  //   link: '/about-us',
  // },

  {
    text: 'Contact us',
    link: '/contact-us',
  },
  {
    text: 'Pricing',
    link: '/pricing',
  },
  {
    text: 'FAQ',
    link: '/faq',
  },
];

export const langData = [
  {
    lang: 'en',
  },
  {
    lang: 'fr',
  },
];
