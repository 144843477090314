import {
  bookFetureSvg,
  bowSvg,
  emojiSvg,
  smileSvg,
} from '../../../../components/Svgs/svgsComponents';

export const aboutData: {
  title: string;
  text: string;
  icon: () => JSX.Element;
}[] = [
  {
    icon: () => smileSvg(),
    title: 'Build Your Knowledge',
    text: "'My Quiz Bank' enhances your educational experience beyond personalized quiz creation. With our AI-driven features, including case presentations, medical synopses, and more, you can transform your content into dynamic, interactive learning experiences. Challenge friends, foster collaborative learning, and drive collective advancement through innovative tools that support your growth.",
  },

  {
    icon: () => bowSvg(),
    title: 'Engage with Medical Trivia',
    text: 'Challenge yourself with a wide range of questions that test your knowledge and keep you on your toes. Perfect for quick study breaks or group competitions, our trivia makes learning addictive.',
  },

  {
    icon: () => emojiSvg(),
    title: 'Personalize your learnings',
    text: `Gone are the days of rigid study schedules. With MedScroll, learning fits seamlessly into your lifestyle. Access your personalized quizzes anytime, anywhere, whether you're commuting to work, waiting at the doctor's office, or relaxing at home. Our app syncs across devices, ensuring that your learning progress is always at your fingertips.`,
  },
  {
    icon: () => bookFetureSvg(),
    title: 'Interactive, Fun, and Tailored Learning',
    text: 'MedScroll goes beyond traditional study methods by offering a platform where learning is led by curiosity and reinforced through engagement. Whether you’re revising for exams or keeping your knowledge sharp, MedScroll adapts to your pace and style.',
  },
];

export const featuresData: {
  img: string;
  title: string;
  text: string;
}[] = [
  {
    img: '/images/feature-robot-1.png',
    title: 'Case Presentation',
    text: `Deliver live presentations, transcribe them, and let our AI provide rewrites or feedback to improve your delivery. This feature helps you master clinical communication and presentation skills.`,
  },

  {
    img: '/images/feature-robot-2.png',
    title: 'Case Recall',
    text: `Sharpen your focus, reading speed, and memory recall with our Case Recall game. Engage in AI-personalized scenarios that become more challenging as you progress to higher levels.`,
  },
  {
    img: '/images/feature-img-3.png',
    title: 'MedSynopsis',
    text: `Enhance your ability to synthesize, summarize, and communicate medical information concisely. Through simulations and AI feedback, MedSynopsis helps you refine these crucial skills.`,
  },
];
