import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useAuthI } from '../types/AuthResponse';

const AuthContext = createContext<useAuthI | undefined>(undefined);

export const useAuth = (): useAuthI => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const token = localStorage.getItem('token');

  const [isAuthenticated, setIsAuthenticated] = useState(token ? true : false);

  const authenticateUser = () => {
    setIsAuthenticated(true);
  };

  const logout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('token');
  };

  useEffect(() => {
    if (token) {
      setIsAuthenticated(true);
    }
  }, [token]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, authenticateUser, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
