import React, { useRef, useEffect } from 'react';
// import './AutoResizeTextarea.css';

const AutoResizeTextarea = ({
  setValue,
  value,
}: {
  setValue: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value: string;
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const autoResize = () => {
    const textarea = textareaRef.current;

    if (textarea === null) return;

    textarea.style.height = 'auto'; // Reset the height
    textarea.style.height = `${textarea.scrollHeight}px`; // Set it to the scrollHeight

    const maxHeight = parseInt(getComputedStyle(textarea).maxHeight);
    if (textarea.scrollHeight > maxHeight) {
      textarea.style.height = `${maxHeight}px`;
      textarea.style.overflowY = 'auto'; // Ensure the scrollbar is enabled
    } else {
      textarea.style.overflowY = 'hidden'; // Hide scrollbar if height is less than max-height
    }
  };

  useEffect(() => {
    autoResize();
  }, []);

  return (
    <textarea
      ref={textareaRef}
      onInput={autoResize}
      value={value}
      onChange={(e) => setValue(e)}
      className="auto-resize-textarea"
    />
  );
};

export default AutoResizeTextarea;
