import { gql } from '@apollo/client';

export const GET_THREAD_GROUP = gql`
  query GetThreadGrp($component: String) {
    getThreadGrp(component: $component) {
      component
      data {
        description
        threadId
      }
      threadGrpUUID
      title
    }
  }
`;
