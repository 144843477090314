import './AISpinner.css';
import { ThreeDots } from 'react-loader-spinner';

const AISpinner = ({
  loading,
  questionNo,
}: {
  loading: boolean;
  questionNo?: number;
}) => {
  return (
    <div className="ai-spinner">
      <div className="ai-spinner__header">
        <p>AI is generating questions</p>

        <ThreeDots
          visible={loading}
          height="60"
          width="60"
          color="#ffffff"
          radius="7"
          ariaLabel="three-dots-loading"
        />
      </div>
      <p className="ai-spinner__content">
        {questionNo} questions are generated at a time, always click on generate
        more
      </p>
    </div>
  );
};

export default AISpinner;
