import React from 'react';

import './DashboardSettingsSidebar.css';
import { settingSidebarData } from './DashboardSettingsSidebarData';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const DashboardSettingsSidebar = ({
  curIndex,
  setCurIndex,
  isOpen,
  setIsOpen,
}: {
  curIndex: number;
  setCurIndex: React.Dispatch<React.SetStateAction<number>>;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleSidBarNav = (index: number) => {
    if (index !== settingSidebarData.length - 1) {
      setCurIndex(index);
    } else {
      logout();
      navigate('/');
    }
  };

  return (
    <div
      style={{
        display: isOpen ? 'flex' : 'none',
      }}
      className="dashboard-setting-sidebar "
    >
      <div className="dashboard-setting-sidebar__links">
        {settingSidebarData.map(({ title, icon }, index) => (
          <div
            onClick={() => {
              handleSidBarNav(index);
              setIsOpen(!isOpen);
            }}
            key={index}
            className={`${
              index === curIndex && index !== settingSidebarData.length - 1
                ? 'active'
                : null
            } dashboard-setting-sidebar__link`}
          >
            <div>
              {icon(
                index === curIndex && index !== settingSidebarData.length - 1
                  ? '#ffffff'
                  : ''
              )}
            </div>

            <p>{title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DashboardSettingsSidebar;
