import React, { useState } from 'react';

import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import NavBar from '../../components/NavBar/NavBar';
import './ContactUs.css';
import Footer from '../../components/Footer/Footer';
import SEO from '../../components/SEO/SEO';
import { ApolloError, useMutation } from '@apollo/client';
import { CONTACT_US_MUTATION } from '../../graphql/mutatons/contactUs';
import { toast } from 'react-toastify';
import client from '../../graphql/client';
import SpinnerLoader from '../../components/SpinnerLoader/SpinnerLoader';

export const ContactUs = () => {
  const initState = {
    firstName: '',
    email: '',
    message: '',
  };

  const [value, setValue] = useState<string | undefined>();

  const [formData, setFormData] = useState(initState);

  const [contactUs, { loading, error }] = useMutation(CONTACT_US_MUTATION, {
    client,
  });

  const initializeState = () => {
    setFormData(initState);
    setValue('');
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = {
      ...formData,
      value,
    };

    await contactUs({
      variables: {
        contactUsContactUsInput2: {
          email: data.email,
          firstName: data.firstName,
          message: data.message,
          phoneNumber: data.value,
        },
      },
    })
      .then((response) => {
        initializeState();
        toast.success(response.data.contactUs.message);
      })
      .catch((err) => {
        let errorMessage = 'An error occurred';

        if (err instanceof ApolloError && err.message) {
          errorMessage = err.message;
        }
        toast.error(errorMessage);
      });
  };

  return (
    <section className="contactus">
      <SpinnerLoader loading={loading} />
      <NavBar />

      <SEO
        title="Contact Us"
        description="Connect with Us: Let's Start a Conversation!"
        name={'medscroll'}
        type="article"
      />

      <div className="container contactus__header">
        <h1>Connect with Us: Let's Start a Conversation!</h1>
        <p>
          Have questions or feedback? We're here to help! Reach out to us and
          let's discuss how we can support your learning goals with MedScroll.
        </p>
      </div>

      <div className="contactus__form-con">
        <div className="container contactus__form-con">
          <h3>Complete the form below </h3>

          <form className="form" onSubmit={handleSubmit}>
            <div className="form__items">
              <div className="form__item">
                <label>First Name</label>
                <input
                  type="text"
                  value={formData.firstName}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      firstName: e.target.value,
                    }))
                  }
                  required
                />
              </div>

              <div className="form__item">
                <label>Email Address</label>
                <input
                  type="text"
                  value={formData.email}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      email: e.target.value,
                    }))
                  }
                  required
                />
              </div>
            </div>

            <div className="contactus__phone">
              <PhoneInput
                placeholder="Enter phone number"
                value={value}
                onChange={setValue}
                required
              />
            </div>

            <div className="form__item ">
              <label>Message</label>
              <textarea
                value={formData.message}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    message: e.target.value,
                  }))
                }
                required
              />
            </div>

            <button type="submit" disabled={loading}>
              Submit
            </button>
          </form>
          {error && <p>Error Please try again</p>}
        </div>
      </div>

      <Footer />
    </section>
  );
};

export default ContactUs;
