import { gql } from '@apollo/client';

export const GET_SPECIALTY = gql`
  query GetAllSpecialties {
    getAllSpecialties {
      specialty
      specialtyUUID
    }
  }
`;
