import { gql } from '@apollo/client';

export const GET_ALL_PRODUCTS = gql`
  query GetAllProduct {
    getAllProduct {
      amount {
        monthly
        yearly
      }
      credits
      generalTrivia
      imageUploadPerQues
      maxNumQuestion
      medicalTrivia
      multiplayerCapacity
      productUUID
      storage
      stripeProduct {
        monthlyPriceId
        stripeProductId
        yearlyPriceId
      }
      txtLimitPerQuestion
      plan
    }
  }
`;
