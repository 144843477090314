import React, { useEffect, useRef, useState } from 'react';

import './MultiplayerCodeGenerated.css';

import PrivateRoute from '../../Utils/PrivateRoute/PrivateRoute';
import {
  BackArrowSvg,
  copyIconSvg,
} from '../../components/Svgs/svgsComponents';
import { useSelector } from 'react-redux';
import { RootState } from '../../state';
import { UserI } from '../../types/AuthResponse';
import QRCode from 'qrcode.react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { copyToClipboard } from '../../Utils/copyText';

// Socket
// import useSocket from '../../hooks/useSocket';
import { PlayerScoreI } from '../../types/socket';
import { toast } from 'react-toastify';
import { useSocketContext } from '../../context/socketContext';
import SpinnerLoader from '../../components/SpinnerLoader/SpinnerLoader';

const MultiplayerCodeGenerated = () => {
  const qrRef = useRef<HTMLDivElement | null>(null);
  const [qrCodeUrl, setQRCodeUrl] = useState('');
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user) as UserI;

  const location = useLocation();

  const { code } = useParams();

  const originURL = window.location.origin;

  const { inviteCode } = location.state ?? {};

  const [inviteGeneratedCode, setInviteGenerationCode] = useState<string>('');

  const { emit, on, off, handleSetCode, isSocketConnected } =
    useSocketContext();

  const [usersJoined, setUsersJoined] = useState<
    { name: string; image: string }[]
  >([]);

  const displayedMessages = useRef<string[]>([]);

  const toastControlMessages = displayedMessages.current;

  const joinedMessage: string = 'joined the game';

  const leaveMessage: string = 'left the game';

  // To downloadd barcode

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = qrCodeUrl;
    link.download = 'qr-code.png';
    link.click();
  };

  const handleStartGame = () => {
    if (usersJoined.length === 0)
      return toast.warn('You must have atleast one joined user');

    emit('start-game', {});
    emit('start-count-down', {});
  };

  // critical functions
  const handleListenLeaveGame = (message: string) => {
    const userName = message.replace(`${leaveMessage}`, '').trim();

    // Check if the message has already been displayed
    if (toastControlMessages.includes(userName)) {
      toast.warning(message);

      // Remove the message to the Set to avoid duplication
      const index = toastControlMessages.indexOf(userName);

      if (index !== -1) {
        // Remove the message from the array to avoid duplication
        toastControlMessages.splice(index, 1);
      }

      // trigger/call fetch-game to get update on game status
      emit('fetch-game', {});
    }
  };

  const handleListenJoinGame = (message: string) => {
    const userName = message.replace(`${joinedMessage}`, '').trim();

    // Check if the message has already been displayed
    if (!toastControlMessages.includes(userName)) {
      // Display the toast if the message is new
      toast.success(message);

      // Add the message to the Set to avoid duplication
      toastControlMessages.push(userName);

      // Trigger/call fetch-game to get update on game status
      emit('fetch-game', {});
    }
  };

  const handleListenFetchGame = (data: any) => {
    // Convert object to array
    const resultArray: PlayerScoreI[] = Object.values(data?.playerScores);

    const usersData = resultArray
      .filter((playerScore) => playerScore.userUUID !== user?.userUUID)
      .map((playerScore) => ({
        name: playerScore.firstName,
        image:
          playerScore.url === null ? '/images/Avatar.png' : playerScore.url,
      }));

    setUsersJoined(usersData);
  };

  const handleListenStartGame = () => {
    navigate('/multiplayer-quiz');
  };

  useEffect(() => {
    // call to recieve/listen on who joined game
    on('joinGame', (message) => handleListenJoinGame(message));

    on('leaveGame', (message: any) => handleListenLeaveGame(message));

    // call to recieve/listen on game status changes
    on('fetchGame', (data) => handleListenFetchGame(data));

    // Hwne questtion is  satrted
    on('startGame', () => handleListenStartGame());

    return () => {
      off('joinGame', handleListenJoinGame);
      off('leaveGame', handleListenLeaveGame);
      off('fetchGame', handleListenFetchGame);
      off('startGame', handleListenStartGame);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [on]);

  useEffect(() => {
    if (qrRef.current) {
      const canvas = qrRef.current.querySelector('canvas');
      if (canvas) {
        const url = canvas.toDataURL('image/png');
        setQRCodeUrl(url);
      }
    }

    setInviteGenerationCode(inviteCode || code);

    handleSetCode(inviteCode || code);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteCode, code]);

  if (!isSocketConnected) return <SpinnerLoader loading={true} />;

  return (
    <PrivateRoute>
      <div className="container multiplayer-code">
        <div className="multiplayer-code__header">
          <span onClick={() => navigate(-1)}>{BackArrowSvg()}</span>

          <p>Multiplayer mode</p>
        </div>
        <div>
          <div className="main-multiplayer-code">
            <div className=" main-multiplayer-code__left-con">
              <div className="main-multiplayer-code__left">
                <div className="main-multiplayer-code__left-code">
                  <div className="game-creator">
                    <div>
                      <img
                        src={user?.profileImage || '/images/guest.svg'}
                        alt="user profile"
                      />
                    </div>
                    <p>YOU</p>
                  </div>
                </div>

                <div className="main-multiplayer-code__left-copy-code">
                  <div className="input-multiplyer">
                    <label>URL</label>

                    <div className="input-multiplyer__input-box">
                      <input
                        type="text"
                        value={
                          `${originURL}/dashboard/multiplyer-invited/${inviteGeneratedCode}`
                            .length > 35
                            ? `${originURL}/dashboard/multiplyer-invited/${inviteGeneratedCode}`.slice(
                                0,
                                35
                              ) + '...'
                            : `${originURL}/dashboard/multiplyer-invited`
                        }
                        readOnly
                      />
                      <span
                        onClick={() =>
                          copyToClipboard(
                            `${originURL}/dashboard/multiplyer-invited/${inviteGeneratedCode}`
                          )
                        }
                      >
                        {copyIconSvg()}
                      </span>
                    </div>
                  </div>

                  <div className="input-multiplyer">
                    <label>Code</label>

                    <div className="input-multiplyer__input-box">
                      <input
                        type="text"
                        value={inviteGeneratedCode}
                        readOnly
                        className="main-code-input"
                      />
                      <span
                        onClick={() => copyToClipboard(inviteGeneratedCode)}
                      >
                        {copyIconSvg()}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <button
                className="code-btn"
                onClick={() => {
                  handleStartGame();
                }}
              >
                Start game
              </button>

              <div className="users-joined">
                <p>{usersJoined.length} players have joined</p>

                <div className="user-joined-con">
                  {usersJoined.map(({ name, image }, index) => (
                    <div className="user-joined-con-item" key={index}>
                      <div className="user-joined-con-item__img-con ">
                        <img src={image} alt={name} />
                      </div>

                      <p>{name}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div
              ref={qrRef}
              onClick={() => handleDownload()}
              className="qr-code-view"
            >
              {' '}
              <QRCode
                size={256}
                value={`${originURL}/dashboard/multiplyer-invited/${inviteCode}`}
              />
            </div>
          </div>
        </div>
      </div>
    </PrivateRoute>
  );
};

export default MultiplayerCodeGenerated;
