import React, { Dispatch, SetStateAction, useState } from 'react';
import './SampleQuiz.css';
import Quiz from '../Quiz/Quiz';
import { sampleQuizData } from './SampleQuizData';
import CardPrompt from '../CardPrompt/CardPrompt';

const SampleQuiz = ({
  setRestartQuiz,
}: {
  setRestartQuiz: Dispatch<SetStateAction<boolean>>;
}) => {
  const [quizHasEnded, setQuizHasEnded] = useState<boolean>(false);
  const [scoreCount, setScoreCount] = useState<number>(0);

  return (
    <>
      {!quizHasEnded ? (
        <>
          <div className="sample-quiz">
            <Quiz
              questions={sampleQuizData}
              endQuiz={setQuizHasEnded}
              setScoreCount={setScoreCount}
            />
          </div>
        </>
      ) : (
        <CardPrompt
          setClosePrompt={setQuizHasEnded}
          setRestartQuiz={setRestartQuiz}
          score={scoreCount}
        />
      )}
    </>
  );
};

export default SampleQuiz;
