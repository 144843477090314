import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import './Quiz.css';
import { QuestionsI } from '../../types';
import Timer from '../Timer/Timer';

const Quiz = ({
  questions,
  endQuiz,
  setScoreCount,
}: {
  questions: QuestionsI[];
  endQuiz: Dispatch<SetStateAction<boolean>>;
  setScoreCount: Dispatch<SetStateAction<number>>;
}) => {
  const maxTimeCount = 30;
  const [questionCounter, setQuestionCounter] = useState(0);
  const [selectedOption, setSelectedOption] = useState<{
    id: number;
    value: string;
  } | null>(null);

  // Prevent selection after intial selection
  const [canSelectOption, setCanSelectOption] = useState<boolean>(true);

  // Timer
  const [timeCount, setTimeCount] = useState<number>(maxTimeCount);
  const [pauseTime, setPauseTime] = useState<boolean>(false);

  const handleNextQuestion = () => {
    setCanSelectOption(false);
    setPauseTime(true);

    if (questionCounter < questions.length - 1) {
      // wait for 2 sec
      setTimeout(() => {
        // Move to next question
        if (canSelectOption) {
          setQuestionCounter((prevState) => prevState + 1);
        }
        setSelectedOption(null);
        setCanSelectOption(true);
        setTimeCount(maxTimeCount);
        setPauseTime(false);
      }, 2000);
    } else {
      // Show something after questions end
      setTimeout(() => {
        endQuiz(true);
      }, 2000);
    }
  };

  // To check time count
  // and move to Next quetion

  useEffect(() => {
    if (timeCount === 0) {
      handleNextQuestion();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeCount]);

  // Perform Correct answer count
  useEffect(() => {
    ((answer: string) => {
      if (selectedOption?.value === answer) {
        setScoreCount((prev) => prev + 1);
      }
    })(questions[questionCounter]?.answer?.answer);
  }, [questionCounter, questions, selectedOption, setScoreCount]);

  return (
    <div className="quiz">
      <div className="quiz__timeCount">
        <Timer
          timeCount={timeCount}
          setTimeCount={setTimeCount}
          pauseTime={pauseTime}
        />
      </div>

      {/* Question count and Question */}
      <div className="quiz__question-con quiz-width">
        <div className="quiz__question-count">
          <span>
            {questionCounter + 1}/{questions?.length}
          </span>
        </div>
        <p className="quiz__question ">
          {questions[questionCounter]?.question}
        </p>
      </div>

      {/* Answer */}
      <div className="quiz__options quiz-width">
        {questions[questionCounter]?.options.map((option) => (
          <div
            key={option.id}
            className={`quiz__option ${
              selectedOption &&
              selectedOption?.value ===
                questions[questionCounter]?.answer?.answer &&
              option?.value === questions[questionCounter]?.answer.answer
                ? 'quiz__correct-correct'
                : null
            }

              ${
                selectedOption &&
                selectedOption?.value !==
                  questions[questionCounter]?.answer?.answer &&
                selectedOption?.id === option?.id
                  ? 'quiz__wrong-wrong'
                  : null
              }

              ${
                selectedOption &&
                selectedOption?.value !==
                  questions[questionCounter]?.answer.answer &&
                option?.value === questions[questionCounter]?.answer?.answer
                  ? 'quiz__correct-answer'
                  : null
              }

              `}
            onClick={() => {
              if (canSelectOption) {
                setSelectedOption(option);
                handleNextQuestion();
              }
            }}
          >
            <p>{option?.value}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Quiz;
