import React, { ReactNode } from 'react';
import './Modal.css';

const Modal = ({
  children,
  extraFunc,
}: {
  children: ReactNode;
  extraFunc: () => void;
}) => {
  return (
    <div
      className="modal overlay"
      onClick={(e) => {
        e.stopPropagation();
        extraFunc();
      }}
    >
      <div className="modal__child">{children}</div>
    </div>
  );
};

export default Modal;
