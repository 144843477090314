import { gql } from '@apollo/client';

export const GET_QUESTIONS = gql`
  query GetQuiz($quizUuid: String, $subcatId: String) {
    getQuestions(quizUUID: $quizUuid, subcatId: $subcatId) {
      answer {
        answer
        answer_details
        id
        reference
      }
      question
      questionNumber
      questionUUID
      quizUUID
      options {
        id
        value
      }
    }
  }
`;
