import React from 'react';
import LazyLoad from 'react-lazyload';
import './Footer.css';
import { useNavigate } from 'react-router-dom';
import { navLinkData } from '../NavBar/NavData';
import { footerSocials } from './FooterData';
import { FaLocationDot } from 'react-icons/fa6';

const Footer = () => {
  const navigate = useNavigate();
  return (
    <section className="">
      <div className="container footer__con">
        <div className="footer__con-upper">
          <div className="footer__con-items " onClick={() => navigate('/')}>
            {' '}
            <LazyLoad offset={100}>
              <img src="/images/Logo.png" alt="facenook logo" />
            </LazyLoad>
          </div>

          <ul className="footer__con-lists">
            {navLinkData.map(({ link, text }, index) => (
              <li
                key={index}
                className="navbar__link"
                onClick={() => {
                  navigate(link);
                }}
              >
                {text}
              </li>
            ))}
          </ul>

          <div className="footer__con-socials">
            {footerSocials.map(({ link, img }, index) => (
              <a href={link} target="_blank" rel="noreferrer" key={index}>
                <LazyLoad offset={100} className="footer__con-social">
                  <img src={img} alt="facenook logo" />
                </LazyLoad>
              </a>
            ))}
          </div>
        </div>
        <div className=" footer__con-down ">
          <div className="footer__con-down__privacy-address">
            <p>© 2024 MedScroll. All Rights Reserved.</p>
            <div>
              <FaLocationDot />

              <p>Level 1/390 St Kilda Road, Melbourne, VIC 3004, Australia</p>
            </div>
          </div>

          <div className="footer__con-down-privacy">
            <p onClick={() => navigate('/privacy')}>Privacy Policy</p>

            <p onClick={() => navigate('/terms')}> Terms & Conditions</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
