export function GetDownloadLink() {
  const userAgent = navigator.userAgent;

  let downloadlink = '';

  // Check for Android devices
  if (/android/i.test(userAgent)) {
    downloadlink =
      'https://play.google.com/store/apps/details?id=com.loopscribe.medscroll&hl=en&gl=US&pli=1';
  }
  // Check for iOS devices (iPhone, iPad, iPod)
  else if (/iPad|iPhone|iPod/.test(userAgent)) {
    downloadlink = 'https://apps.apple.com/ng/app/medscroll/id6483944208';
  }
  // Check for Mac
  else if (/Macintosh|Mac OS X/i.test(userAgent)) {
    downloadlink = 'https://apps.apple.com/ng/app/medscroll/id6483944208'; // Change to your Mac download link if available
  }
  // Check for Windows PCs anyother pc
  else {
    downloadlink =
      'https://play.google.com/store/apps/details?id=com.loopscribe.medscroll&hl=en&gl=US&pli=1'; // Change to your Windows download link if available
  }

  return downloadlink;
}
