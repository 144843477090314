import React from 'react';

import { GoogleLogin, CredentialResponse } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { useMutation } from '@apollo/client';
import { GOOGLE_LOG_IN } from '../../graphql/mutatons/authentication';
import client from '../../graphql/client';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUser } from '../../state/userSlice';
import { toast } from 'react-toastify';
import SpinnerLoader from '../SpinnerLoader/SpinnerLoader';
import { useAuth } from '../../context/AuthContext';

const GoogleAuth = ({ text }: { text: string }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { authenticateUser } = useAuth();

  const location = useLocation();

  const redirectPath = location.search.replace('?', '').trim() || '';

  // Mutation
  const [googleLogin, { loading }] = useMutation(GOOGLE_LOG_IN, {
    client,
  });

  const onSuccess = async (response: CredentialResponse) => {
    const token = response.credential;

    const decoded: {
      family_name: string;
      given_name: string;
      email: string;
    } = jwtDecode(token!);

    await googleLogin({
      variables: {
        googleLoginInput: {
          lastName: decoded?.given_name || '',
          firstName: decoded?.family_name || '',
          email: decoded?.email,
        },
      },
    })
      .then((res) => {
        // Set token to localStorage
        localStorage.setItem(
          'token',
          res?.data?.googleLoginMobile?.access_token
        );

        dispatch(setUser(res?.data?.googleLoginMobile?.user));

        //  Auth user to true
        authenticateUser();

        // If user is redirected to login when trying to access a paticular protected route
        if (redirectPath) {
          navigate(redirectPath, { replace: true });
        } else {
          navigate('/');
        }

        toast.success('Login successfully');
      })
      .catch((error) => {
        toast.error(error?.message || ' Unexpected error occured');
      });
  };

  const onFailure = () => {
    toast.error('Login failed');
  };

  if (loading) return <SpinnerLoader loading={loading} />;

  return (
    <div>
      <GoogleLogin onSuccess={onSuccess} onError={onFailure} />
    </div>
  );
};

export default GoogleAuth;
