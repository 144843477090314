import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

const QRCodeRedirection = () => {
  const paraRef = useRef<HTMLParagraphElement | null>(null);

  const { code } = useParams();

  const linkRedirect = `${window.location.origin}multiplyer-invited/${code}`;

  useEffect(() => {
    window.location.href = linkRedirect;

    // const userAgent = navigator.userAgent;

    // if (/android/i.test(userAgent)) {
    //   window.location.href =
    //     'https://play.google.com/store/apps/details?id=com.loopscribe.medscroll&hl=en&gl=US&pli=1';
    // } else if (/iPad|iPhone|iPod/.test(userAgent)) {
    //   window.location.href =
    //     'https://apps.apple.com/ng/app/medscroll/id6483944208';
    // } else {
    //   if (!paraRef.current) return;

    //   paraRef.current.innerText = 'Please visit this link on a mobile device.';
    // }
  }, [linkRedirect]);

  return <p ref={paraRef}>Redirecting...</p>;
};

export default QRCodeRedirection;
