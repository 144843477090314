import { gql } from '@apollo/client';

export const SUBMIT_RESPONSE_MUTATION = gql`
  mutation SubmitResponse($submitResInput: SubmitResInput!) {
    submitResponse(submitResInput: $submitResInput) {
      totalCorrect
      totalPoints
      totalScore
      userRanking
    }
  }
`;
