import { gql } from '@apollo/client';

export const CREATE_QUIZ = gql`
  mutation CreateQuiz($createQuizInput: CreateQuizInput!, $file: Upload) {
    createQuiz(createQuizInput: $createQuizInput, file: $file) {
      category
      coverImage
      description
      duration
      point
      quizCategory {
        customCat
        customCatId
      }
      quizUUID
      topic
      totalDuration
      totalQuestion
    }
  }
`;

export const ADD_QUESTION = gql`
  mutation AddQuestionToQuiz($addQuestionInput: AddQuestionInput!) {
    addQuestionToQuiz(addQuestionInput: $addQuestionInput) {
      message
    }
  }
`;

export const DELETE_QUESTION = gql`
  mutation DeleteQuestion($questionUuid: String!) {
    deleteQuestion(questionUUID: $questionUuid) {
      message
    }
  }
`;

export const UPDATE_QUESTION_IN_QB = gql`
  mutation UpdateQuestionInQuiz($updateQuestionInput: UpdateQuestionInput!) {
    updateQuestionInQuiz(updateQuestionInput: $updateQuestionInput) {
      message
    }
  }
`;

export const UPDATE_QUIZ = gql`
  mutation UpdateQuiz($updateQuizInput: UpdateQuizInput!, $file: Upload) {
    updateQuiz(updateQuizInput: $updateQuizInput, file: $file) {
      quizUUID
    }
  }
`;

export const ADD_QUIZ_CATEGORY = gql`
  mutation AddQBQuizCategory($addCusCatInput: AddCusCatInput!) {
    addQBQuizCategory(addCusCatInput: $addCusCatInput) {
      message
    }
  }
`;
