import React from 'react';
import './QuestionDetails.css';
import {
  questionDurationIconSvg,
  questionNumberIconSvg,
} from '../Svgs/svgsComponents';
import { timeToMinutes, timeToSeconds } from '../../Utils/convertTime';
import { useNavigate } from 'react-router-dom';

const QuestionDetails = ({
  point,
  totalDuration,
  totalQuestion,
  duration,
  quizId,
}: {
  point: number;
  totalDuration: string;
  totalQuestion: number;
  duration: string;
  quizId: string;
}) => {
  const navigate = useNavigate();

  const stateData = {
    duration: timeToSeconds(duration),
    point,
  };

  return (
    <div className="question-detials" onClick={(e) => e.stopPropagation()}>
      <div className="question-detials__header">
        <h4>Quiz Detials</h4>

        <p>Please read the following instructions</p>
      </div>

      <div className="question-detials__durations">
        <div className="question-detials__durations-item">
          <div className=".question-detials__durations-item__img">
            {questionNumberIconSvg()}
          </div>
          <div className="question-detials__durations-item__duration">
            <p>
              {totalQuestion} {totalQuestion > 1 ? 'Questions' : 'Question'}{' '}
            </p>
            <p className="desc">
              Each questions carries {point} {point > 1 ? 'points' : 'point'}
            </p>
          </div>
        </div>

        <div className="question-detials__durations-item">
          <div className="question-detials__durations-item__img">
            {questionDurationIconSvg()}
          </div>
          <div className="question-detials__durations-item__duration">
            <p>{timeToMinutes(totalDuration)}</p>
            <p className="desc">Total duration of the quiz</p>
          </div>
        </div>
      </div>

      <ul className="question-detials__list">
        <li>
          If you answer a question correctly within the first 8 secs you will
          get an additional {point} {point > 1 ? 'points' : 'point'}.
        </li>
        <li>
          Each question is timed for {timeToSeconds(duration)}{' '}
          {timeToSeconds(duration) > 1 ? 'seconds' : 'second'}
        </li>
        <li>Once you miss a question you can't revisit it again.</li>
      </ul>

      <button
        className="btn question-detials__btn"
        onClick={() =>
          navigate(`/question-bank/quiz/${quizId}`, { state: stateData })
        }
      >
        Done
      </button>
    </div>
  );
};

export default QuestionDetails;
