import React, { useEffect } from 'react';
import './CircularProgressCountDown.css';

const CircularProgressCountDown = ({
  duration,
  timeLeft,
  setTimeLeft,
  pauseTime,
}: {
  duration: number;
  timeLeft: number;
  setTimeLeft: React.Dispatch<React.SetStateAction<number>>;
  pauseTime: boolean;
}) => {
  const radius = 40;
  const circumference = 2 * Math.PI * radius;

  useEffect(() => {
    if (timeLeft > 0 && !pauseTime) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [pauseTime, setTimeLeft, timeLeft]);

  const progress = (1 - timeLeft / duration) * circumference;

  return (
    <div className="progress-container">
      <svg className="progress-ring" width="100" height="110">
        <circle
          className="progress-ring__circle"
          stroke="#349AFF"
          strokeWidth="6"
          fill="transparent"
          r={radius}
          cx="50"
          cy="50"
          style={{ strokeDasharray: circumference, strokeDashoffset: progress }}
        />
      </svg>
      <div className="progress-text">{timeLeft}s</div>
    </div>
  );
};

export default CircularProgressCountDown;
