// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import translation files
import homeEN from './locales/en/home.json';
import homeFR from './locales/fr/home.json';

import contactUsEN from './locales/en/contactUs.json';
import contactUsFR from './locales/fr/contactUs.json';

import pricingEN from './locales/en/pricing.json';
import pricingFR from './locales/fr/pricing.json';

import faqEN from './locales/en/faq.json';
import faqFR from './locales/fr/faq.json';

import aboutEN from './locales/en/about.json';
import aboutFR from './locales/fr/about.json';

// Define the resources with namespaces
const resources = {
  en: {
    home: homeEN,
    about: aboutEN,
    contact: contactUsEN,
    pricing: pricingEN,
    faq: faqEN,
  },
  fr: {
    home: homeFR,
    about: aboutFR,
    contact: contactUsFR,
    pricing: pricingFR,
    faq: faqFR,
  },
};

i18n
  .use(LanguageDetector) // Detect user language
  .use(initReactI18next) // Pass the i18n instance to react-i18next
  .init({
    resources,
    fallbackLng: 'en',
    ns: ['home', 'about', 'contact', 'pricing', 'faq'],
    defaultNS: 'home',
    interpolation: {
      escapeValue: false, // React already escapes values to prevent XSS
    },
  });

export default i18n;
