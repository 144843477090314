import './StripePayment.css';
import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import { RootState } from '../../state';
import { UserI } from '../../types/AuthResponse';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import {
  CREATE_SUBSCRIPTION,
  MAKE_PAYMENT,
  UPDATE_SUBSCRIPTION,
} from '../../graphql/mutatons/payments';
import client from '../../graphql/client';
import SpinnerLoader from '../SpinnerLoader/SpinnerLoader';
import { updateUser } from '../../state/userSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const cardElementOptions = {
  style: {
    base: {
      color: '#272d37b9',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSize: '20px',
      fontWeight: 'bold',
      backgroundColor: '#FFFFFF',
      lineHeight: '28px',
      '::placeholder': {
        color: '#AFAFAF',
        fontSize: '20px',
      },
      ':-webkit-autofill': {
        color: '#32325d',
        padding: '15px 40px',
      },
    },

    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

const CheckoutForm = ({
  productId,
  subscriptionId,
  isTrail,
  isSubscriptionPayment,
  topUpToken,
}: {
  productId: number | string;
  subscriptionId: string | null;
  isSubscriptionPayment: boolean;
  topUpToken: number;
  isTrail?: boolean;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stripe = useStripe(); // Stripe instance
  let elements = useElements(); // Elements instance
  const [isProcessing, setIsProcessing] = useState(false);

  const currency = 'USD';

  const user = useSelector((state: RootState) => state.user) as UserI;

  // Mutation

  // Create Subscription
  const [createSubscription, { loading: CreateSubLoading }] = useMutation(
    CREATE_SUBSCRIPTION,
    { client }
  );

  // Update Subscription
  const [updateSubscription, { loading: UpdateSubLoading }] = useMutation(
    UPDATE_SUBSCRIPTION,
    { client }
  );

  //Top up
  const [makeTopupPayment] = useMutation(MAKE_PAYMENT, { client });

  const validateStripeForm = () => {
    // Do not call if stripe or elements are null or not defined
    if (!elements || !stripe) return undefined;

    // Get card details from the elements
    const cardNumber = elements.getElement(CardNumberElement);
    const cardExpiry = elements.getElement(CardExpiryElement);
    const cardCvc = elements.getElement(CardCvcElement);

    if (!cardNumber || !cardExpiry || !cardCvc) {
      toast.error('One or more card elements are missing.');
      return;
    }

    return { cardNumber, cardExpiry, cardCvc };
  };

  // only call when its not subscription payment
  const handleSubmitTopUp = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const cardDetails = validateStripeForm();

    if (!cardDetails) {
      // Handle the error case where the form validation fails.
      toast.error('Unable to retrieve card details.');
      return;
    }

    const { cardNumber } = cardDetails;

    setIsProcessing(true);

    // Get payment method id from stripe

    const { paymentMethod, error } = await stripe!.createPaymentMethod({
      type: 'card',
      card: cardNumber,
      billing_details: { email: user?.email || '', name: user?.firstName },
    });

    // If unable to get payment method details
    if (error) {
      setIsProcessing(false);
      return toast.error(error.message);
    }

    const topUpPayload = {
      email: user?.email,
      paymentMethodId: paymentMethod?.id,
      tokenNumber: topUpToken,
    };

    // 1. Create PaymentIntent on the backend and get the client_secret
    await makeTopupPayment({
      variables: {
        stripePaymentDto: topUpPayload,
      },
    })
      .then(async (res) => {
        console.log(res?.data);

        let { client_secret: clientSecret } = res?.data?.makePayment;

        // Use the client_secret to confirm the payment on the frontend
        const { error: confirmError, paymentIntent } =
          await stripe!.confirmCardPayment(clientSecret, {
            payment_method: {
              card: cardNumber,
              billing_details: {
                name: `${user?.firstName || ''} ${user?.lastName || ''}`,
              },
            },
          });

        if (confirmError) {
          console.log(confirmError, ' this is error ---');
          // Handle error from confirmCardPayment
          setIsProcessing(false);
          return toast.error(
            confirmError.message || 'Failed to process payment'
          );
        }

        if (paymentIntent?.status === 'succeeded') {
          // Payment was successful
          setIsProcessing(false);
          toast.success('Payment successful!');
          navigate('/dashboard');
        }
      })
      .catch((error) => {
        toast.error(error?.message || 'Unexpected errorr occured');
        setIsProcessing(false);
      });
  };

  // Only call when is Subscription Payment
  const handleSubmitSubcription = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    const cardDetails = validateStripeForm();

    // Start loading after validation passes
    setIsProcessing(true);

    if (!cardDetails) {
      // Handle the error case where the form validation fails.
      toast.error('Unable to retrieve card details.');
      return;
    }

    const { cardNumber } = cardDetails;

    // Get payment method from stripe
    const { paymentMethod, error } = await stripe!.createPaymentMethod({
      type: 'card',
      card: cardNumber,
      billing_details: { email: user?.email || '', name: user?.firstName },
    });

    if (error) {
      setIsProcessing(false);
      return toast.error(error.message);
    }

    console.log(paymentMethod, ' This iss method');

    const createSubPayload = {
      productPriceId: productId,
      defaultPaymentMethod: paymentMethod?.id,
    };

    const updateSubPayload = {
      productPriceId: productId,

      subscriptionId: user?.stripeCustomer?.stripeSub?.stripeSubId,
      subscriptionItemsId: user?.stripeCustomer?.stripeSub?.stripeSubItemId,
    };

    // Create Sub
    if (!user?.subscription?.isActive) {
      await createSubscription({
        variables: {
          stripeSubDto: createSubPayload,
        },
      })
        .then((res) => {
          console.log(res?.data, ' response ');

          // Update user state
          dispatch(updateUser({ subscription: res?.data?.createSubscription }));

          setIsProcessing(false);
          toast.success('Subscribed sucessfully ');
          console.log(user);

          navigate('/dashboard');
        })
        .catch((err) => {
          console.log(err, ' ttiiss iss error ');
          setIsProcessing(false);
          toast.error(err?.message || 'Unexpected error occured');
        });
    } else {
      // Update subscription
      await updateSubscription({
        variables: {
          updateStripeSubDto: updateSubPayload,
        },
      })
        .then((res) => {
          // Update user state
          dispatch(updateUser({ subscription: res?.data?.updateSubscription }));

          setIsProcessing(false);
          toast.success('Subscription updated sucessfully ');

          navigate('/dashboard');
        })
        .catch((err) => {
          console.log(err, ' ttiiss iss error ');
          setIsProcessing(false);
          toast.error(err?.message || 'Unexpected error occured');
        });
    }
  };

  if (CreateSubLoading) return <SpinnerLoader loading={true} />;

  return (
    <form
      onSubmit={(event) => {
        if (isSubscriptionPayment) {
          handleSubmitSubcription(event);
        } else {
          handleSubmitTopUp(event);
        }
      }}
      className="stripe-form"
    >
      <div className="form-stripe__item">
        <label>Card Number</label>
        <CardNumberElement options={cardElementOptions} />
      </div>

      <div className="form-stripe__item-row">
        <div className="form-stripe__item">
          <label>Expiration Date</label>
          <CardExpiryElement options={cardElementOptions} />
        </div>

        <div className="form-stripe__item">
          <label>CVC</label>
          <CardCvcElement options={cardElementOptions} />
        </div>
      </div>

      <div className="form-stripe__row">
        <div>
          <input type="checkbox" />
        </div>
        <p>Remember this details next time</p>
      </div>
      {isTrail && (
        <p className="trail-text">
          Unlock a week of unlimited medical insights with our 7-day free trial!
          Utilize MedScroll's comprehensive resources to enhance your medical
          practice.
        </p>
      )}

      <button
        className="form-stripe__btn"
        type="submit"
        disabled={!stripe || isProcessing}
      >
        {isProcessing ? 'Processing...' : 'Pay'}
      </button>
    </form>
  );
};

const StripePayment = ({
  productId,
  isSubscriptionPayment,
  subscriptionId,
  topUpToken,
  isTrail,
}: {
  productId?: number | string;
  subscriptionId?: string | null;
  topUpToken?: number;
  isSubscriptionPayment?: boolean;
  isTrail?: boolean;
}) => {
  // Load Stripe with your publishable key
  const stripePromise = loadStripe(
    `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`
  );

  const [isStripeFormLoading, setIsStripeFormLoading] = useState(true);

  useEffect(() => {
    stripePromise
      .then((stripe) => {
        if (!stripe) {
          toast.error('Stripe initialization failed');
        }

        // Card form is noe ready for input
        setIsStripeFormLoading(false);
      })
      .catch((error) => {
        toast.error(`Stripe error: ${error.message}`);
      });
  }, [stripePromise]);

  if (isStripeFormLoading) return <SpinnerLoader loading={true} />;

  return (
    <div className="stripe-main-chekout">
      <div className="stripe-img-card-con">
        <img src="/images/payment-cards.png" alt="payment card" />
      </div>
      <Elements stripe={stripePromise}>
        <CheckoutForm
          productId={productId || ''}
          isTrail={isTrail || false}
          isSubscriptionPayment={isSubscriptionPayment || false}
          subscriptionId={subscriptionId || null}
          topUpToken={topUpToken || 0}
        />
      </Elements>

      <div className="powered-by-stripe">
        <img
          src="/images/powered-by-stripe.png"
          className="stripe-img"
          alt="powered by stripe"
        />
      </div>
    </div>
  );
};

export default StripePayment;
