import React from 'react';

const BackDrop = ({
  isShow,
  extraFunc,
}: {
  isShow: boolean;
  extraFunc: () => void;
}) => {
  return (
    <>
      {isShow && <div className="backdrop" onClick={() => extraFunc()}></div>}
    </>
  );
};

export default BackDrop;
