import React from 'react';

import './AuthHeader.css';
import GoogleAuth from '../GoogleAuth/GoogleAuth';

const AuthHeader = ({
  title,
  isLogin,
}: {
  title: string;
  isLogin: boolean;
}) => {
  return (
    <div className="auth-header">
      <h2>{title}</h2>

      {/* <div className="gradiat-blue-border google-auth">
        <div>
          <img src="/images/google-icon.png" alt="google design" />
        </div>
        <p>Sign in with Google</p>
      </div> */}

      <GoogleAuth text={isLogin ? 'Sign Up' : 'Sign In'} />

      <p className="auth-indicator">
        {isLogin ? 'Sign Up' : 'Sign In'} with your Email
      </p>
    </div>
  );
};

export default AuthHeader;
