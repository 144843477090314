import { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  blueLinkIconSvg,
  blueUploadIconSvg,
  clickUploadIconSvg,
  editUploadIconSvg,
  promptSendIconSvg,
  viewUploadIconSvg,
} from '../../components/Svgs/svgsComponents';
import ToggleSlider from '../../components/ToggleSlider/ToggleSlider';
import PrivateRoute from '../../Utils/PrivateRoute/PrivateRoute';

import './AICreateQuestion.css';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from '../../components/Modal/Modal';
import { useMutation, useQuery } from '@apollo/client';
import { AI_GENERATE_QUIZ } from '../../graphql/mutatons/aiGenerateQuiz';
import client from '../../graphql/client';
import AutoResizeTextarea from '../../components/resizbleTextArea/resizbleTextArea';
import { QuizzPromptI } from '../../types/aiGeneratedquiz';
import { modifiyAIQuizData } from '../../Utils/moddifyResponseeData';
import AISpinner from '../../components/AISpinner/AISpinner';
import { GET_THREAD_GROUP } from '../../graphql/queries/getAIThreadGroup';
import SpinnerLoader from '../../components/SpinnerLoader/SpinnerLoader';

const AICreateQuestion = () => {
  const navigate = useNavigate();
  const pdfUploadRef = useRef<HTMLInputElement>(null);
  const [isShowPdf, setIsShowPdf] = useState(false);
  const [isShowUrl, setIsShowUrl] = useState(false);
  const [fileName, setFileName] = useState<null | string>(null);
  const [threadId, setThreadId] = useState<string | null>(null);

  const location = useLocation();

  const { quizUuidState } = location.state || {};

  const [formData, setFormData] = useState<QuizzPromptI>({
    url: '',
    prompt: '',
    questionNo: 10,
    questionType: 'Multiple choice',
    optionNo: 4,
    hideAnswer: false,
  });

  const [aiData, setAIData] = useState<{
    file: File | string;
  }>({
    file: '',
  });

  // Ai Generate quiz mutation
  const [generateAiQuiz, { loading }] = useMutation(AI_GENERATE_QUIZ, {
    client,
  });

  // AI get thread
  const {
    data: threadData,
    loading: threadLoading,
    error: threadError,
    refetch,
  } = useQuery(GET_THREAD_GROUP, {
    variables: { component: 'QUIZ_AI' },
  });

  const handlePdf = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      setAIData((prev) => ({
        file: file,
      }));

      setFileName(file?.name);
    }
  };

  const handleSubmit = async () => {
    if (formData?.prompt === '') {
      toast.warning('Prompt can not be empty');
      return;
    }

    const { hideAnswer, ...otherPayload } = formData;

    const fileValue = aiData.file === '' ? null : aiData.file;

    const payload = {
      file: fileValue,
      quizPromptInput: { ...otherPayload, threadId },
    };

    await generateAiQuiz({
      variables: { ...payload },
    })
      .then((res) => {
        const questionData = res?.data?.generateAIquiz;

        const modifiedQuestionData = modifiyAIQuizData(questionData);

        // Set quiz question to local storage
        localStorage.setItem(
          `${quizUuidState}`,
          JSON.stringify(modifiedQuestionData)
        );

        navigate('/add-questions', {
          state: {
            quizUuidState: quizUuidState,
            isAiGeneratedQuestion: true,
            isHideAnswer: hideAnswer,
            promptData: { ...payload },
            questionNo: formData?.questionNo,
            questionType: formData?.questionType,
            optionNo: formData?.optionNo,
          },
        });
      })
      .catch((err) => {
        toast.error(err?.message || 'Unexpected error occured');
      });
  };

  useEffect(() => {
    if (threadData) {
      const threadDataId =
        threadData.getThreadGrp.length > 0
          ? threadData?.getThreadGrp[0]?.data[0]?.threadId
          : null;

      setThreadId(threadDataId);
    }

    if (threadError) {
      toast.error(threadError?.message || ' unexpected error occured');
    }
  }, [threadData, threadError]);

  useEffect(() => {
    if (!quizUuidState) {
      toast.warning('Quiz id not found ');
      navigate(-1);
    }

    refetch({ component: 'QUIZ_AI' });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, quizUuidState]);

  if (threadLoading) return <SpinnerLoader loading={true} />;

  return (
    <PrivateRoute>
      <div className="container ai-create">
        <div className="not-for-current-sprint ai-create__headder">
          Extract Doc
        </div>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <div className="form-main-ai">
            <div className="form-main-ai__prompt">
              <div className="form-main-ai__prompt-holder">
                <div
                  className={`${
                    isShowUrl
                      ? 'ease-in-pdf-url-animation'
                      : 'ease-out-pdf-url-animation remove-from-display'
                  } form-main-ai__prompt-url`}
                >
                  <textarea
                    placeholder="Paste URL"
                    value={formData?.url}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        url: e.target.value,
                      }))
                    }
                  ></textarea>
                </div>
                {fileName === null ? (
                  <div
                    className={`${
                      isShowPdf
                        ? 'ease-in-pdf-url-animation'
                        : 'ease-out-pdf-url-animation remove-from-display'
                    } form-main-ai__prompt-upload-pdf`}
                    onClick={() => {
                      pdfUploadRef?.current && pdfUploadRef?.current.click();
                    }}
                  >
                    {clickUploadIconSvg()}
                    <p>Select PDF/Doc</p>
                  </div>
                ) : (
                  <div
                    className={`${
                      isShowPdf
                        ? 'ease-in-pdf-url-animation'
                        : 'ease-out-pdf-url-animation remove-from-display'
                    } form-main-ai__prompt-view-pdf`}
                    onClick={() => {
                      pdfUploadRef?.current && pdfUploadRef?.current.click();
                    }}
                  >
                    {viewUploadIconSvg()}
                    <div>
                      {fileName.length > 30
                        ? fileName.slice(0, 30) + '...'
                        : fileName}
                    </div>
                    <div
                      className="edit-pdf"
                      onClick={() => {
                        pdfUploadRef?.current && pdfUploadRef?.current.click();
                      }}
                    >
                      {editUploadIconSvg()}
                    </div>
                  </div>
                )}

                <input
                  ref={pdfUploadRef}
                  type="file"
                  accept="application/pdf"
                  onChange={(e) => handlePdf(e)}
                  className="remove-from-display "
                />
              </div>
              <div className="form-main-ai__prompt-textarea">
                <AutoResizeTextarea
                  setValue={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      prompt: e.target.value,
                    }))
                  }
                  value={formData?.prompt}
                />

                {formData.prompt.length > 10 && (
                  <button type="submit" className="prompt-send">
                    <div>{promptSendIconSvg()}</div>
                  </button>
                )}
              </div>

              <div className="form-main-ai__prompt-feature">
                <div
                  className="form-main-ai__prompt-feature-item"
                  onClick={() => {
                    setIsShowUrl((prev) => !prev);
                    setIsShowPdf(false);
                  }}
                >
                  {blueLinkIconSvg()}
                  <p>URL/Link</p>
                </div>

                <div
                  className="form-main-ai__prompt-feature-item"
                  onClick={() => {
                    if (fileName !== null) {
                      // remove pdf
                      setAIData((prev) => ({
                        file: '',
                      }));

                      setFileName(null);

                      // Set file to empty
                      if (pdfUploadRef?.current) {
                        pdfUploadRef.current.value = '';
                      }
                    }

                    setIsShowPdf((prev) => !prev);
                    setIsShowUrl(false);
                  }}
                >
                  {blueUploadIconSvg()}
                  <p>{fileName === null ? 'Upload doc' : 'Remove pdf'}</p>
                </div>
              </div>
              {fileName !== null && (
                <button type="submit" className="btn prompt-done-btn">
                  Done
                </button>
              )}
            </div>

            <div className="form-main-ai__details">
              <div className="form-main-ai__detail">
                <div className="form-main-ai__detail-content">
                  <p>No of questions</p>
                  <span>
                    Select the number of questions you want to generate.
                  </span>
                </div>

                <div className="form-main-ai__detail-input-range">
                  <input
                    type="number"
                    max={10}
                    min={1}
                    value={formData?.questionNo}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        questionNo: parseInt(e.target.value),
                      }))
                    }
                  />
                </div>
              </div>

              <div className="form-main-ai__detail">
                <div className="form-main-ai__detail-content">
                  <p>Question type</p>
                  <span>
                    Select the type of question you want AI to generate.
                  </span>
                </div>

                <div className="form-main-ai__detail-input-select">
                  <select
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        questionType: e.target.value,
                      }))
                    }
                  >
                    <option value="Multiple choice">
                      Multiple choice (MCQ)
                    </option>
                    <option value="True or False">True or false</option>
                  </select>
                </div>
              </div>

              <div className="form-main-ai__detail">
                <div className="form-main-ai__detail-content">
                  <p>Number of Options</p>
                  <span>
                    Select the type of question you want AI to generate.
                  </span>
                </div>

                <div className="form-main-ai__detail-input-range">
                  <input
                    type="number"
                    max={4}
                    min={1}
                    value={formData?.optionNo}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        optionNo: parseInt(e.target.value),
                      }))
                    }
                  />
                </div>
              </div>

              <div className="form-main-ai__detail">
                <div className="form-main-ai__detail-content">
                  <p>Hide Answer</p>
                </div>

                <div className="">
                  <ToggleSlider
                    isShow={formData.hideAnswer}
                    extraFunc={() => {
                      setFormData((prev) => ({
                        ...prev,
                        hideAnswer: !prev?.hideAnswer,
                      }));
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      {loading && (
        <Modal extraFunc={() => {}}>
          <AISpinner loading={loading} questionNo={formData?.questionNo} />
        </Modal>
      )}
    </PrivateRoute>
  );
};

export default AICreateQuestion;
