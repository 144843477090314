import React from 'react';

import './SignleAnswers.css';
import { AddQuestionI } from '../../types/CreateQuestions';

const SignleAnswers = ({
  setQuizFormData,
  quizFormData,
  isHideAnswer,
}: {
  setQuizFormData: React.Dispatch<React.SetStateAction<AddQuestionI>>;
  quizFormData: AddQuestionI;
  isHideAnswer: boolean;
}) => {
  const handleOptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    optionIndex: number
  ) => {
    setQuizFormData((prev) => ({
      ...prev,
      options: quizFormData.options.map(
        (option: { value: string; id: number | string }, index: number) => {
          if (index === optionIndex) {
            return { ...option, value: event.target.value };
          } else return option;
        }
      ),
    }));
  };

  return (
    <div className="single-options">
      {quizFormData.options.map((optionValue, index) => (
        <input
          key={index}
          type="text"
          placeholder={`${
            index !== 1 ? 'Answer ' + (index + 1) : 'Correct Answer'
          }`}
          className={`remove-default-input options__input-item ${
            index === 1 && isHideAnswer ? 'answer-option' : 'normal-option'
          }`}
          required
          value={optionValue.value}
          onChange={(event) => handleOptionChange(event, index)}
        />
      ))}
    </div>
  );
};

export default SignleAnswers;
