import { ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';

// Create an HTTP upload to enable file upload link to the GraphQL server
const httpLink = createUploadLink({
  uri: process.env.REACT_APP_API_BASE_URL,
});

// Set the headers for each request
const authLink = setContext((_, { headers }) => {
  // Get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');

  // Get the API key from an environment variable or a configuration file
  const apikey = process.env.REACT_APP_API_KEY;
  const apihost = process.env.REACT_APP_API_HOST;

  // Return the headers to the context so that the httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      apikey: `${apikey}`,
      apihost: `${apihost}`,
      'Apollo-Require-Preflight': 'true',
    },
  };
});

// Create the Apollo Client instance
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
