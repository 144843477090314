export const handleSpeak = (textToSpeech) => {
  const textToSpeek = textToSpeech || 'No prompt inputed';

  // Cancel any ongoing speech if it exists
  if (window.speechSynthesis.speaking || window.speechSynthesis.pending) {
    window.speechSynthesis.cancel();
    console.log(' In cancel here ');
  }

  console.log(' Out war - ');

  // Create a new utterance instance
  const utterance = new SpeechSynthesisUtterance(textToSpeek);

  // Set up event handlers (optional)
  utterance.onstart = () => {
    console.log('Speech started');
  };

  utterance.onend = () => {
    console.log('Speech ended');
  };

  utterance.onerror = (event) => {
    console.error('Speech error', event);
  };

  // Speak the text
  console.log('Speaking text:', textToSpeek);

  // Speak the text
  window.speechSynthesis.speak(utterance);
};
