import React from 'react';
import LazyLoad from 'react-lazyload';
import './UserProfilePic.css';

const UserProfilePic = ({ userImage }: { userImage?: string | null }) => {
  return (
    <div className="profilePic">
      <div className="clip">
        <div className="profilePic__ring">
          <LazyLoad offset={100}>
            <img src="/images/profile-ring.png" alt="User profile" />
          </LazyLoad>
        </div>

        <div className="profilePic__item">
          <LazyLoad offset={100}>
            <img src={userImage || '/images/guest.svg'} alt="User profile" />
          </LazyLoad>
        </div>
      </div>
    </div>
  );
};

export default UserProfilePic;
