import React from 'react';
import LazyLoad from 'react-lazyload';

const DownloadApp = () => {
  return (
    <div className="hero__donwloads">
      <a
        href="https://play.google.com/store/apps/details?id=com.loopscribe.medscroll&hl=en&gl=US"
        target="_blank"
        rel="noopener noreferrer"
      >
        <LazyLoad offset={100}>
          <img
            src="/images/playstore-download.png"
            alt="download android app"
          />
        </LazyLoad>
      </a>

      <a
        href="https://apps.apple.com/ng/app/medscroll/id6483944208"
        target="_blank"
        rel="noopener noreferrer"
      >
        <LazyLoad offset={100}>
          <img
            src="/images/apple-store-download.png"
            alt="download apple app"
          />
        </LazyLoad>
      </a>
    </div>
  );
};

export default DownloadApp;
