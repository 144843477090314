import React, { useEffect, useRef, useState } from 'react';

import './DashboardAddQuestion.css';
import PrivateRoute from '../../Utils/PrivateRoute/PrivateRoute';
import {
  addIconSvg,
  BackArrowSvg,
  CloseSvg,
  imageIconSvg,
  promptCopyIconSvg,
  promptRotateArrowIconSvg,
  promptSendIconSvg,
  promptSpeakIconSvg,
  redFlagIconSvg,
  searchIconSvg,
} from '../../components/Svgs/svgsComponents';
import SignleAnswers from '../../components/SignleAnswers/SignleAnswers';
import ToggleSlider from '../../components/ToggleSlider/ToggleSlider';
import MultipleAnswer from '../../components/MultipleAnswer/MultipleAnswer';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AddQuestionI } from '../../types/CreateQuestions';
import {
  moveAnswerToCorrectIndex,
  shuffleArray,
} from '../../Utils/randomizeAnswer';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@apollo/client';
import {
  ADD_QUESTION,
  DELETE_QUESTION,
  UPDATE_QUESTION_IN_QB,
} from '../../graphql/mutatons/createQuiz';
import client from '../../graphql/client';
import SpinnerLoader from '../../components/SpinnerLoader/SpinnerLoader';
import { GET_QUESTION_BANK_QUIZ } from '../../graphql/queries/getQuestionBankData';
import { Questions } from '../../types/Questions';
import {
  DELETE_UPLOADED_IMAGE,
  UPLOAD_FILES,
} from '../../graphql/mutatons/uploadFiles';
import Modal from '../../components/Modal/Modal';
import AutoResizeTextarea from '../../components/resizbleTextArea/resizbleTextArea';
import { AIGeneratePayloadI } from '../../types/aiGeneratedquiz';
import { copyToClipboard } from '../../Utils/copyText';
import { AI_GENERATE_QUIZ } from '../../graphql/mutatons/aiGenerateQuiz';
import { modifiyAIQuizData } from '../../Utils/moddifyResponseeData';
import AISpinner from '../../components/AISpinner/AISpinner';
import { handleSpeak } from '../../Utils/speachAndText';

const DashboardAddQuestion = () => {
  const location = useLocation();

  const {
    quizUuidState,
    isAiGeneratedQuestion,
    isHideAnswer,
    promptData,
    questionNo,
    questionType,
    optionNo,
  } = location.state || {};

  const finalQuestionNum =
    questionType === 'Multiple choice' && optionNo
      ? optionNo
      : questionType === 'True or False'
      ? 2
      : 4;

  const initQuestionAdd = {
    answer: '',
    answer_details: '',
    images: [],
    options: Array(finalQuestionNum).fill({ value: '', id: '' }),
    question: '',
    reference: '',
    subtopic: '',
    topic: '',
  };

  // Hold current question
  const [quizFormData, setQuizFormData] =
    useState<AddQuestionI>(initQuestionAdd);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const questionImageRef = useRef<HTMLInputElement>(null);

  const { quizUuid } = useParams();

  const [showAnswer, setShowAnswer] = useState(isHideAnswer || true);

  const [showRefinePrompt, setShowRefinePrompt] = useState(false);

  const [promptFormData, setPromptFormData] =
    useState<AIGeneratePayloadI>(promptData);

  const navigate = useNavigate();
  const [showMultipleOptions, setShowMultipleOptions] = useState(false);
  const [questions, setQuestions] = useState<AddQuestionI[] | []>([]);
  const [curIndex, setCurIndex] = useState<number | null>(null);

  // Mutattion
  const [addQuestionMutation, { loading, error }] = useMutation(ADD_QUESTION, {
    client,
  });

  const [updateQuestionInQb, { loading: updateQBQuizzLoading }] = useMutation(
    UPDATE_QUESTION_IN_QB,
    { client }
  );

  // Upload mutation
  const [uploadFiles, { loading: uploadLoading }] = useMutation(UPLOAD_FILES, {
    client,
  });

  const [deleteUploadedImage, { loading: deleteUploadedLoading }] = useMutation(
    DELETE_UPLOADED_IMAGE,
    { client }
  );

  const [deleteQuestionMutation, { data: deleteData, loading: deleteLoading }] =
    useMutation(DELETE_QUESTION, { client });

  const [refinePrompt, { loading: refinePromptoading }] = useMutation(
    AI_GENERATE_QUIZ,
    { client }
  );

  // Query
  const {
    loading: questionLoading,
    data: questionData,
    refetch: questionRefecth,
  } = useQuery(GET_QUESTION_BANK_QUIZ, {
    variables: { quizUuid: quizUuid },
    skip: !quizUuid, // Skip the query if quizUUID is not available
  });

  const resetData = (arryItem?: AddQuestionI[]) => {
    // update local storage
    localStorage.setItem(
      `${quizUuidState}`,
      JSON.stringify(arryItem || questions)
    );

    // Update question state
    setQuestions(arryItem || questions);

    // reset quizData
    setQuizFormData(initQuestionAdd);

    // Reset index
    setCurIndex(null);

    // reset Image view & selected files

    setSelectedFiles([]);

    // Close modal for refine
    setShowRefinePrompt(false);
  };

  const addquestionUtil = () => {
    const answer = quizFormData.options[1]?.value;

    // Randomiize optionsto prevent ansswer always on index 1
    const roundomizOptions = shuffleArray([...quizFormData.options]);

    const newQuestionData = {
      ...quizFormData,
      options: roundomizOptions,
      answer: answer,
      images: quizFormData.images,
    };

    return newQuestionData;
  };

  // Add question to list
  const addQuestion = () => {
    let newQuestionData = addquestionUtil();

    // Save question to array of questions
    const questionArray = [newQuestionData, ...questions];

    // Create the storage with id as key
    localStorage.setItem(`${quizUuidState}`, JSON.stringify(questionArray));

    // Set question with new data
    setQuestions(questionArray);

    // Make quizFornData Empty to accomodate new data
    setQuizFormData(initQuestionAdd);
  };

  // Set question on Question tab edit
  const getQuestionFromQuestion = (questionIndex: number) => {
    // Get cur question
    let getCurquestion = questions[questionIndex];

    // Get correct options with answer in index 1
    let options = moveAnswerToCorrectIndex(
      questions[questionIndex].options,
      questions[questionIndex].answer
    );

    setQuizFormData({ ...getCurquestion, options });
  };

  // Remove from question
  const deleteQuestion = () => {
    const updatedQuestion = questions.filter(
      (question, index) => index !== curIndex
    );

    resetData(updatedQuestion);
  };

  // Update question
  const updateQuestion = () => {
    const updatedQuestion = questions.map(
      (questionVal: AddQuestionI, index: number) => {
        if (index === curIndex) {
          return quizFormData;
        } else return questionVal;
      }
    );

    resetData(updatedQuestion);
  };

  // Publish to seerver
  const handlePublish = async () => {
    if (questions.length === 0) {
      toast.error('Question can not be empty');
      return;
    }

    const dataVariable = {
      addQuestionInput: {
        quizUUID: quizUuidState || quizUuid,
        questions: [
          ...questions.map((question) => {
            const { images, ...questionData } = question;
            return {
              ...questionData,
              options: questionData.options.map((option) => option.value),
              imageUrls: images,
              answer: questionData?.answer,
              answer_details: questionData?.answer_details,
              reference: questionData?.reference,
            };
          }),
        ],
      },
    };

    await addQuestionMutation({
      variables: { ...dataVariable },
    })
      .then((res) => {
        toast.success(res?.data?.addQuestionToQuiz?.message);

        // Delete local state
        localStorage.removeItem(`${quizUuidState}`);
        setQuestions([]);
        // navigate to question bank
        navigate('/dashboard');
      })
      .catch((err) => {
        toast.error(error?.message || 'unexpected error occured');
      });
  };

  // Server Side actions
  const deleteServerQuestion = async () => {
    await deleteQuestionMutation({
      variables: { questionUuid: quizFormData?.questionUUID },
    })
      .then((res) => {
        toast.success(res?.data?.deleteQuestion?.message);
        deleteQuestion();
      })
      .catch((err) => {
        toast.error(deleteData?.message || err?.message);
      });
  };

  const addServerQuestion = async () => {
    let {
      answer,
      answer_details,
      images,
      reference,
      quizUUID,
      answerId,
      ...newQuestionData
    } = addquestionUtil();

    const dataVariable = {
      addQuestionInput: {
        quizUUID: quizUuidState || quizUuid,
        questions: [
          {
            ...newQuestionData,
            imageUrls: images,
            options: newQuestionData.options.map((option) => option.value),
            answer_details: answer_details,
            answer: answer,
          },
        ],
      },
    };

    await addQuestionMutation({
      variables: { ...dataVariable },
    })
      .then((res) => {
        toast.success(res?.data?.addQuestionToQuiz?.message);
        addQuestion();
        // To refecth quiz so it has quizuuid
        questionRefecth();
      })
      .catch((err) => {
        toast.error(
          error?.message || err?.messagee || 'unexpected error occured'
        );
      });
  };

  // Update question server
  const updateServerQuestion = async () => {
    // Note id is the answer id
    let {
      answer,
      answer_details,
      images,
      reference,
      quizUUID,
      answerId,
      ...newQuestionData
    } = addquestionUtil();

    const questionData = {
      ...newQuestionData,

      imageUrls: images,

      answer: {
        answer_details: answer_details,
        answer: answer,
        id: answerId,
      },
    };

    await updateQuestionInQb({
      variables: {
        updateQuestionInput: {
          quizUUID,
          questions: [questionData],
        },
      },
    })
      .then((res) => {
        toast.success(res?.data?.updateQuestionInQuiz?.message);

        questionRefecth();
      })
      .catch((error) => {
        toast.error(error?.message || 'Unexpected errorr occured');
      });
  };

  // Dom click ref upload question
  const handleQuestionImagUpload = () => {
    // If the use ref value is null
    if (!questionImageRef.current) return;

    questionImageRef.current.click();
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files) {
      // Convert the FileList to an array and update the state
      const files = Array.from(event.target.files);
      setSelectedFiles(files);

      await uploadFiles({
        variables: {
          files: files,
        },
      })
        .then((res) => {
          // Set Image url to quiz Data view
          setQuizFormData((prevState) => ({
            ...prevState,
            images: [
              ...prevState.images,
              ...res.data?.uploadImageToQuestion.imageUrls,
            ],
          }));

          // set file uploadd state to []
          setSelectedFiles([]);
        })
        .catch((err) => {
          toast.error(err?.message || 'An unexpected error occured');
        });
    }
  };

  const handleDeleteUploadImage = async (imageIndex: number) => {
    // Pperfoorm delete upload
    const imageUrls = quizFormData.images[imageIndex];

    // Remove image from currrent  question
    const newImage = quizFormData.images.filter((image) => image !== imageUrls);

    const newQuizFormData = {
      ...quizFormData,
      images: newImage,
    };

    await deleteUploadedImage({
      variables: {
        deleteQsImagesInput: {
          imageUrls: [imageUrls],
          questionUUID: quizFormData?.questionUUID,
        },
      },
    })
      .then((res) => {
        // set quizForm Data
        setQuizFormData(newQuizFormData);

        // Update queestion
        updateQuestion();

        questionRefecth();

        toast.success(res?.data?.deleteImageFromQuestion?.message);
      })
      .catch((error) => {
        toast.error(error?.message || 'Unexpected error ocurred');
      });
  };

  // Handle Refine AI question
  const handleRefineAIQuestion = async (isNew: boolean) => {
    // For initial prompt value of a new one
    const payload = isNew ? promptFormData : promptData;

    await refinePrompt({
      variables: { ...payload },
    })
      .then((res) => {
        const questionData = res?.data?.generateAIquiz;

        const modifiedQuestionData = modifiyAIQuizData(questionData);

        resetData(modifiedQuestionData);
      })
      .catch((err) => {
        toast.error(err?.message || 'Unexpected error occcured');
      });
  };

  // Handle ad moore Ai  gnrateed qquestiion to  existing question
  const handleGenerateeMorequestion = async () => {
    const generateMore = {
      ...promptFormData,

      quizPromptInput: {
        ...promptFormData.quizPromptInput,
        prompt: 'Generate unique more question',
      },
    };

    await refinePrompt({
      variables: { ...generateMore },
    })
      .then((res) => {
        const questionData = res?.data?.generateAIquiz;

        const modifiedQuestionData = modifiyAIQuizData(questionData);

        // add to existing question

        const combineQuestion = [...questions, ...modifiedQuestionData];

        resetData(combineQuestion);
      })
      .catch((err) => {
        toast.error(err?.message || 'Unexpected error occcured');
      });
  };

  useEffect(() => {
    // Check if staorage with quizz id exist
    const quizData = localStorage.getItem(`${quizUuidState}`);

    if (quizData && !quizUuid) {
      const parsedQuiz = JSON.parse(quizData);
      setQuestions(parsedQuiz);
    }

    if (questionData?.getQuesFromQuesBank?.length > 0) {
      const modifiedQuestionData = questionData.getQuesFromQuesBank.map(
        (questionValue: Questions) => ({
          answerId: questionValue?.answer.id,
          answer: questionValue?.answer.answer,
          answer_details: questionValue?.answer?.answer_details,
          images: questionValue?.images,
          options: questionValue?.options.map((option) => ({
            value: option.value,
            id: option?.id,
          })),
          question: questionValue?.question,
          reference: questionValue?.answer.reference,
          subtopic: '',
          topic: '',
          questionUUID: questionValue?.questionUUID,
          quizUUID: quizUuid,
        })
      );

      // Set questions
      setQuestions(modifiedQuestionData);

      // Set localstoragee
      localStorage.setItem(`${quizUuid}`, JSON.stringify(modifiedQuestionData));
    }
  }, [quizUuidState, quizUuid, questionData]);

  if (
    loading ||
    deleteUploadedLoading ||
    uploadLoading ||
    questionLoading ||
    deleteLoading ||
    updateQBQuizzLoading
  )
    return (
      <SpinnerLoader
        loading={
          loading ||
          deleteUploadedLoading ||
          questionLoading ||
          deleteLoading ||
          uploadLoading ||
          updateQBQuizzLoading
        }
      />
    );

  return (
    <PrivateRoute>
      <div className="container dashboard-add-question">
        <div className="dashboard-add-question__header">
          <div className="dashboard-add-question__header-left">
            <div
              className="add-question__svg"
              onClick={() => navigate('/dashboard/create')}
            >
              {BackArrowSvg()}
            </div>
            <p>Question</p>
          </div>

          {!quizUuid && (
            <button
              className="btn question-add__btn "
              onClick={() => handlePublish()}
            >
              Publish
            </button>
          )}
        </div>

        <div className="dashboard-add__main-content">
          <div className="dashboard-add__main-content-left">
            <div className="not-for-current-sprint search-question">
              {searchIconSvg()}
              <input type="text" placeholder="search" required />
            </div>

            {!isAiGeneratedQuestion ? (
              <div className="add-question" onClick={() => resetData()}>
                <div>{addIconSvg()}</div>
                <p>Add new question</p>
              </div>
            ) : (
              <div className="ai-generated-features">
                <div
                  className="ai-generated-features__add-question"
                  onClick={() => {
                    handleGenerateeMorequestion();
                  }}
                >
                  <div>{addIconSvg()}</div>
                  <p>Generate more questions</p>
                </div>

                <div
                  className="ai-generated-features__refine-prompt"
                  onClick={() => setShowRefinePrompt(true)}
                >
                  <p>Refine your prompt</p>
                </div>

                <div className="ai-generated-features__show-answer">
                  <p>Show answer</p>

                  <ToggleSlider
                    isShow={showAnswer}
                    extraFunc={() => setShowAnswer((prev: boolean) => !prev)}
                  />
                </div>
              </div>
            )}

            <div className="question-items">
              {questions.map(({ question }, index) => (
                <div
                  className="question-item"
                  key={index}
                  onClick={() => {
                    setCurIndex(index);
                    getQuestionFromQuestion(index);
                  }}
                >
                  <p>Question {index + 1}</p>

                  <div className="question-item__topic">{question}</div>
                </div>
              ))}
            </div>
          </div>

          <form
            className="dashboard-add__main-content-right"
            onSubmit={(e) => {
              e.preventDefault();

              if (quizUuid) {
                // Server action
                curIndex === null
                  ? addServerQuestion()
                  : updateServerQuestion();
              } else {
                // Client Action

                curIndex === null ? addQuestion() : updateQuestion();
              }
            }}
          >
            <div className="dashboard-add__main-content-right__item">
              <div className="question-add__question-header-con">
                <div className="header-option">
                  {!quizUuid ? (
                    <>
                      {!isAiGeneratedQuestion && (
                        <>
                          {curIndex === null ? (
                            <button className="btn-add-question">
                              Add Question
                            </button>
                          ) : (
                            <button className="btn-add-question">
                              Update Question
                            </button>
                          )}
                        </>
                      )}

                      {curIndex !== null && (
                        <p
                          className="add-question-delete"
                          onClick={() => deleteQuestion()}
                        >
                          Delete
                        </p>
                      )}
                    </>
                  ) : (
                    <>
                      {/* Server side actions  */}
                      {curIndex === null ? (
                        <button className="btn-add-question">
                          Add Question
                        </button>
                      ) : (
                        <button className="btn-add-question">
                          Update Question
                        </button>
                      )}

                      {curIndex !== null && (
                        <p
                          className="add-question-delete"
                          onClick={() => deleteServerQuestion()}
                        >
                          Delete Question
                        </p>
                      )}
                    </>
                  )}
                </div>

                <div className="question-add__questions">
                  <textarea
                    placeholder="Type your question here"
                    className="question-add__question-item"
                    required
                    value={quizFormData?.question}
                    onChange={(e) =>
                      setQuizFormData((prev) => ({
                        ...prev,
                        question: e.target.value,
                      }))
                    }
                  ></textarea>

                  <div className="question-add__questions-image">
                    <div
                      className="question-add__questions-image__svg"
                      onClick={handleQuestionImagUpload}
                    >
                      {imageIconSvg()}
                    </div>

                    <input
                      ref={questionImageRef}
                      onChange={(event) => handleFileChange(event)}
                      type="file"
                      accept="image/*"
                      multiple
                      className="question-add-upload-image"
                    />
                  </div>
                </div>
              </div>

              {/* Images */}
              <div className="grouped-question-images">
                {quizFormData?.images.length > 0 &&
                  quizFormData?.images.map((imageUrl, index) => (
                    <div className="grouped-question-image" key={index}>
                      <div
                        className="grouped-question-image__svg"
                        onClick={() => handleDeleteUploadImage(index)}
                      >
                        {CloseSvg()}
                      </div>
                      <div className="grouped-question-image__img-con">
                        <img src={`${imageUrl}`} alt="question view" />
                      </div>
                    </div>
                  ))}
              </div>

              {!showMultipleOptions ? (
                <SignleAnswers
                  setQuizFormData={setQuizFormData}
                  quizFormData={quizFormData}
                  isHideAnswer={showAnswer}
                />
              ) : (
                <MultipleAnswer />
              )}

              <div className="not-for-current-sprint dashboard-add__show-multiple-answer">
                <p>Allow multiple answer</p>

                <ToggleSlider
                  isShow={showMultipleOptions}
                  extraFunc={() => setShowMultipleOptions((prev) => !prev)}
                />
              </div>

              {isAiGeneratedQuestion && (
                <div className="not-for-current-sprint ai-generated-features__flag-question">
                  <p>Flag this question</p>

                  {redFlagIconSvg()}
                </div>
              )}
              <div className="explaination-ref">
                <div className="explaination-ref__item">
                  <p className="explaination-ref__item-explain">Explanation</p>

                  <textarea
                    placeholder="This is where the explanation of the answer will be typed in..."
                    required
                    value={quizFormData?.answer_details}
                    onChange={(e) =>
                      setQuizFormData((prev) => ({
                        ...prev,
                        answer_details: e.target.value,
                      }))
                    }
                  ></textarea>
                </div>

                <div className="explaination-ref__item">
                  <p className="explaination-ref__item-ref">Reference</p>

                  <textarea
                    placeholder="This is where the reference of the answer will be typed in..."
                    value={quizFormData?.reference}
                    onChange={(e) =>
                      setQuizFormData((prev) => ({
                        ...prev,
                        reference: e.target.value,
                      }))
                    }
                  ></textarea>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* Refine Prompt Modal */}
      {showRefinePrompt && promptFormData !== null && (
        <Modal
          extraFunc={() => {
            setShowRefinePrompt(false);
          }}
        >
          <div
            className="ai-generated-features__textarea-modal"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="ai-generated-features__header">
              <div
                onClick={() => {
                  setShowRefinePrompt(false);
                }}
              >
                {BackArrowSvg()}
              </div>

              <p>Prompt</p>
            </div>
            <div className="ai-generated-features__prompt-textarea ">
              <div className="ai-generated-features__prompt-textarea-icons">
                <div
                  className="ai-generated-features__prompt-textarea-icons__item"
                  onClick={() =>
                    copyToClipboard(promptFormData?.quizPromptInput?.prompt)
                  }
                >
                  {promptCopyIconSvg()}
                </div>
                <div
                  className="ai-generated-features__prompt-textarea-icons__item"
                  onClick={() => handleRefineAIQuestion(false)}
                >
                  {promptRotateArrowIconSvg()}
                </div>
                <div
                  className="ai-generated-features__prompt-textarea-icons__item btn-speak"
                  onClick={() => {
                    // Speak start

                    handleSpeak(promptFormData.quizPromptInput.prompt);
                  }}
                >
                  {/* <Text /> */}
                  {promptSpeakIconSvg()}
                </div>
              </div>
              <AutoResizeTextarea
                setValue={(e) =>
                  setPromptFormData((prev) => ({
                    ...prev,
                    quizPromptInput: {
                      ...prev?.quizPromptInput,
                      prompt: e.target.value,
                    },
                  }))
                }
                value={promptFormData?.quizPromptInput?.prompt}
              />

              {promptFormData?.quizPromptInput.prompt.length > 10 && (
                <div className="ai-generated-features__prompt-btn">
                  <span onClick={() => handleRefineAIQuestion(true)}>
                    {promptSendIconSvg()}
                  </span>
                </div>
              )}
            </div>
          </div>
        </Modal>
      )}

      {refinePromptoading && (
        <Modal extraFunc={() => {}}>
          <AISpinner loading={refinePromptoading} questionNo={questionNo} />
        </Modal>
      )}
    </PrivateRoute>
  );
};

export default DashboardAddQuestion;
