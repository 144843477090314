import React, { useEffect, useState } from 'react';
import './DashboardHome.css';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_CATEGORY } from '../../graphql/queries/getDashboardCategory';
import { CategoryI, GetCategoryI } from '../../types/GetCategory';
import SpinnerLoader from '../../components/SpinnerLoader/SpinnerLoader';

import { useDispatch } from 'react-redux';

import { toast } from 'react-toastify';
import Modal from '../../components/Modal/Modal';
import { CloseSvg } from '../../components/Svgs/svgsComponents';

// Add name of card that should not show up oon the UI
// As the main card display might not yet be avialable for web
// But only on mobile
const cardsToRemove = ['Case Recall', 'Open Ended Question'];

const DashboardHome = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { emit, on } = useSocketContext();

  const [showMutiplyer, setShowMultipler] = useState(false);

  const [dashCategory, setDashCategory] = useState<CategoryI[] | []>([]);

  // Get dashboard category
  const { loading, error, data, refetch } =
    useQuery<GetCategoryI>(GET_CATEGORY);

  const handleRouteLink = (category: string, quizUUID: string) => {
    switch (category) {
      case 'General Trivia':
        navigate(`/trivia/${quizUUID}`);
        return;
      case 'Medical Trivia':
        navigate(`/dashboard/medical-trivia/${quizUUID}`);
        return;
      case 'My Question Bank':
        navigate(`/question-bank`);
        return;
      case 'Basic Sciences':
        navigate(`/dashboard/basic-sciences/${quizUUID}`);
        return;
      default:
        navigate('dashboard');
    }
  };

  useEffect(() => {
    if (data) {
      const newData = Object.keys(data.getCategory?.categories)
        .map((key) => data.getCategory?.categories[key])
        .reverse();

      setDashCategory(newData);
    }

    if (error) {
      toast.error(error?.message || 'Unexpected errorr occured');
    }
    refetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dispatch, refetch]);

  // Spinner Loads
  if (loading) return <SpinnerLoader loading={loading} />;

  return (
    <div className="dashboard-home">
      <div className="dashboard-home-cards">
        {dashCategory &&
          dashCategory.map(
            ({ category, coverImage, totalNumber, quizUUID }, index) =>
              !cardsToRemove.includes(category) && (
                <div
                  className="dashboard-home-card"
                  key={index}
                  onClick={() => handleRouteLink(category, quizUUID)}
                >
                  <div className="dashboard-home-card__img">
                    <img src={coverImage} alt={`${category} banner`} />
                  </div>

                  <div className="dashboard-home-card__content">
                    <h3>{category} </h3>
                    <p>
                      {totalNumber} {totalNumber > 1 ? 'questions' : 'question'}{' '}
                    </p>
                  </div>
                </div>
              )
          )}
      </div>
      <div className="dashboard-home-btn">
        <button className="not-for-current-sprint auth-btn">
          Untimed Quiz
        </button>
        <button className="btn-border " onClick={() => setShowMultipler(true)}>
          Multiplayer
        </button>
      </div>

      {/* {error && <p>{error.message}</p>} */}

      {showMutiplyer && (
        <Modal
          extraFunc={() => {
            setShowMultipler(false);
          }}
        >
          <div
            className="modal-multiplayer "
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div
              className="modal-cancel"
              onClick={() => setShowMultipler(false)}
            >
              {CloseSvg()}
            </div>
            <h4>Select your preferred mode</h4>

            <p
              onClick={() => {
                setShowMultipler(false);
                navigate('/dashboard/multiplyer');
              }}
            >
              Friends
            </p>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default DashboardHome;
