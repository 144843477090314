import { toast } from 'react-toastify';

export const copyToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
    toast.success('Text copied to clipboard');
  } catch (err) {
    toast.error('Failed to copy text: ');
  }
};
