import './MultiplayerQuestionBank.css';
import React, { useEffect, useState } from 'react';

import PrivateRoute from '../../Utils/PrivateRoute/PrivateRoute';
import { BackArrowSvg } from '../../components/Svgs/svgsComponents';
import { useQuery } from '@apollo/client';
import {
  GET_QUESTION_BANK,
  GET_QUESTION_BANK_CAT,
} from '../../graphql/queries/getQuestionBankData';
import {
  QuestionBankBaseStrucCustomI,
  QuestionBankCatDetailI,
  QuestionBankCategoryI,
} from '../../types/QuestionBankTypes';
import SpinnerLoader from '../../components/SpinnerLoader/SpinnerLoader';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaCheck } from 'react-icons/fa6';

const MultiplayerQuestionBank = () => {
  const navigate = useNavigate();
  const { pathname, state } = useLocation();

  const { topic, totalQuestion, questionUUIDs, questionSelection } = state;

  const [selectedQuiz, setSelectedQuiz] = useState<string[]>([
    ...questionUUIDs,
  ]);

  // Note: cat is short for category
  const [catWithNoQuestionData, setCatWithNoQuestionData] = useState<string[]>(
    []
  );

  const [categoryData, setCategoryData] = useState<
    QuestionBankCategoryI[] | []
  >([]);

  // For Ref purpose to know the init stat of All QB list
  // Does not update after initial update
  const [initCategoryDetails, setInitCategoryDetails] = useState<
    QuestionBankBaseStrucCustomI[] | []
  >([]);

  // For question bank question list
  // Updates bas on cat selected
  const [categoryDetails, setCategoryDetails] = useState<
    QuestionBankBaseStrucCustomI[] | []
  >([]);

  // Query get question Bank Categories
  const { loading, data, error, refetch } = useQuery(GET_QUESTION_BANK_CAT);

  // Query get all bank questions
  const {
    loading: qbLoading,
    data: qbData,
    error: qbError,
    refetch: refetchQbData,
  } = useQuery(GET_QUESTION_BANK);

  const hanldeActiveCategory = (catId: string | number) => {
    const newCategoryState = categoryData.map(({ customCatId, ...others }) => {
      if (customCatId === catId) {
        return {
          customCatId,
          ...others,
          isActive: true,
        };
      } else
        return {
          customCatId,
          ...others,
          isActive: false,
        };
    });

    // Filter category Details
    let newCategoreDetails = initCategoryDetails;

    if (catId !== 'all') {
      newCategoreDetails = initCategoryDetails.filter(
        (catItem) => catItem?.catId === catId
      );
    }

    setCategoryDetails(newCategoreDetails);
    setCategoryData(newCategoryState);
  };

  const handleQuizChecker = (quizId: string) => {
    // Remove from list
    if (selectedQuiz?.includes(quizId)) {
      const newsubCat = selectedQuiz.filter((catItem) => catItem !== quizId);
      setSelectedQuiz(newsubCat);
    } else {
      // Add to list
      const newAddded = [...selectedQuiz, quizId];
      setSelectedQuiz(newAddded);
    }
  };

  const handleQuestionSelected = () => {
    // Only with 10 or more questions seleccted can user proceed
    if (selectedQuiz.length < 10)
      return toast.warn('You have to select a minimum of 10 questions');

    navigate('/dashboard/multiplyer', {
      state: {
        questionUUIDs: selectedQuiz,
        topic,
        totalQuestion,
        questionSelection,
      },
    });
  };

  // IN use Effect set the category select state
  useEffect(() => {
    if (data) {
      // For selection of all Question List to show
      // Default is true
      const showAllCat = {
        __typename: 'CustomCat',
        customCat: 'All',
        customCatId: 'all',
        isActive: true,
      };

      const catRes = data?.getQBQuizCategory
        .map((catValue: QuestionBankCategoryI) => ({
          ...catValue,
          isActive: false,
        }))
        .reverse();

      setCategoryData([showAllCat, ...catRes]);
    }

    // Modify Question Bank Response
    if (qbData && data) {
      const baseQBQuizzStructure = data?.getQBQuizCategory
        .map((catValue: QuestionBankCategoryI) => ({
          catName: catValue?.customCat,
          catId: catValue?.customCatId,
          catDetails: [],
        }))
        .reverse();

      // Update baseStruture to have Quiz list Details arranged based on category name
      qbData.getAllQuiz.forEach((qbItem: QuestionBankCatDetailI) => {
        baseQBQuizzStructure.forEach(
          (baseItem: QuestionBankBaseStrucCustomI) => {
            if (baseItem.catName === qbItem?.quizCategory?.customCat) {
              baseItem.catDetails.push(qbItem);
            }
          }
        );
      });

      // Get all cat that has no question
      // So there dont show up on ui not untill it has question

      let catWithNoQuestion: string[] = [];

      baseQBQuizzStructure.forEach((qbItem: QuestionBankBaseStrucCustomI) => {
        if (qbItem.catDetails.length === 0) {
          return catWithNoQuestion.push(qbItem.catName);
        } else return null;
      });

      setCatWithNoQuestionData(catWithNoQuestion);

      setInitCategoryDetails(baseQBQuizzStructure);
      setCategoryDetails(baseQBQuizzStructure);
    }

    // To reload each time comes to this page
    refetchQbData();
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, qbData, pathname, refetchQbData, refetch]);

  // Spinner
  if (loading || qbLoading)
    return <SpinnerLoader loading={loading || qbLoading} />;

  if (error) toast.error(error?.message || 'An unexpected error occured');

  if (qbError) toast.error(error?.message || 'An unexpected error occured');

  return (
    <PrivateRoute>
      <div className="container question-bank-quiz-select">
        <div className="question-bank__headers">
          <div className="question-bank__header-left">
            <div
              onClick={() =>
                navigate('/dashboard/multiplyer', {
                  state: {
                    questionUUIDs: [],
                    topic,
                    totalQuestion,
                    questionSelection: 'Question selection',
                  },
                })
              }
            >
              {BackArrowSvg()}
            </div>

            <h3 className="question-title">My Question Bank Selection</h3>
          </div>

          <button
            className="quiz-select-btn"
            onClick={() => handleQuestionSelected()}
          >
            Done
          </button>
        </div>

        <div className="question-bank__content">
          <div className="question-bank__content-selction">
            {categoryData.map(
              ({ customCatId, customCat, isActive }, index) =>
                !catWithNoQuestionData.includes(customCat) && (
                  <div
                    key={index}
                    className={` ${
                      isActive && 'question-bank__active-selection'
                    } question-bank__content-selction__item`}
                    onClick={() => hanldeActiveCategory(customCatId)}
                  >
                    {customCat}
                  </div>
                )
            )}
          </div>

          <div className="question-bank__cards-categories">
            {categoryDetails.map(
              (questionDetails, index) =>
                questionDetails?.catDetails.length > 0 && (
                  <>
                    <div className="question-bank__cards-category" key={index}>
                      <p>{questionDetails?.catName}</p>
                      {questionDetails?.catDetails.length > 0 ? (
                        <div className="question-bank__cards">
                          {questionDetails?.catDetails.map(
                            (
                              { coverImage, description, topic, quizUUID },
                              index
                            ) => (
                              <React.Fragment key={quizUUID}>
                                <div className="question-bank__card">
                                  <div
                                    className="question-bank__card-checker"
                                    onClick={() => {
                                      handleQuizChecker(quizUUID);
                                    }}
                                  >
                                    {selectedQuiz.includes(quizUUID) && (
                                      <FaCheck />
                                    )}
                                  </div>
                                  <div className="question-bank__card-img">
                                    <img
                                      src={coverImage || '/images/card-img.png'}
                                      alt="card"
                                    />
                                  </div>

                                  <div className="question-bank__card-content">
                                    <div className="question-bank__card-content__topic ">
                                      <h5>
                                        {' '}
                                        {topic && topic.length <= 15
                                          ? topic
                                          : topic && topic.length > 15
                                          ? topic.slice(0, 15) + '...'
                                          : null}
                                      </h5>
                                    </div>

                                    <p className="question-bank__card-descrip">
                                      {description && description.length <= 25
                                        ? description
                                        : description && description.length > 25
                                        ? description.slice(0, 25) + '...'
                                        : null}
                                    </p>
                                  </div>
                                </div>
                              </React.Fragment>
                            )
                          )}
                        </div>
                      ) : (
                        <p>No Question currently </p>
                      )}
                    </div>
                  </>
                )
            )}
          </div>
        </div>

        <button
          className="btn question-bank-btn"
          onClick={() => navigate('/dashboard/create')}
        >
          Create
        </button>
      </div>
    </PrivateRoute>
  );
};

export default MultiplayerQuestionBank;
