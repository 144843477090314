import React, { useEffect, useState } from 'react';

import './QuizzTrivia.css';
import QuizzBars from '../QuizzBars/QuizzBars';
import CircularProgressCountDown from '../CircularProgressCountDown/CircularProgressCountDown';
import { Option, Question } from '../../types/Questions';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from '../Modal/Modal';
import QuizResult from '../QuizResult/QuizResult';

const QuizzTrivia = ({
  questions,
  updatePointUI,
  duration,
  point,
  backLink,
}: {
  questions: Question[];
  updatePointUI: (score: number) => void;
  duration?: number;
  point?: number;
  backLink: string;
}) => {
  const DURATION = duration || 20;
  // Incase of bonus
  const BONUSSPEED = 8;
  const BONUSPOINT = 3;

  const CORRECTSCOREPOINT = point || 1;

  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(DURATION);
  const [pauseTime, setPauseTime] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [showAnswer, setShowAnswer] = useState(false);
  const [showResult, setShowResult] = useState<boolean>(false);

  // Get quizId
  const { quizUUID } = useParams();

  const [modalClickable, setModalClickable] = useState(true);
  // Prevent selection after intial selection
  const [canSelectOption, setCanSelectOption] = useState(true);

  const [bars, setBars] = useState(
    questions.length !== 0
      ? questions.map((_, index) => ({ state: '', curIndex: index }))
      : []
  );

  function modifyQuestion() {
    let newQuestion = questions.map((question) => ({
      question: question?.question,
      questionUUID: question?.questionUUID,
      speed: DURATION,
      optionSelected: '',
      answer: question?.answer?.answer,
      score: 0,
      options: [
        question?.options[0]?.value,
        question?.options[1]?.value,
        question?.options[2]?.value,
        question?.options[3]?.value,
      ],
      explain: question?.answer?.answer_details,
    }));

    return newQuestion;
  }

  // Question review to save answers and selected options
  const [questionReviewData, setQuestionReviewData] = useState(
    questions.length !== 0 ? modifyQuestion() : []
  );

  // Know if current question is correct or wrong
  const handleIsAnswerCorrect = (option: Option) => {
    if (option) {
      return option?.value === ''
        ? ''
        : option.value === questions[currentQuestionIndex].answer?.answer
        ? 'correct'
        : 'wrong';
    } else {
      return '';
    }
  };

  const handleNextQuestion = (option?: Option) => {
    setPauseTime(true);
    setCanSelectOption(false);

    if (currentQuestionIndex < questions.length - 1) {
      setTimeout(() => {
        // Move to next question only when cur question index is less
        // question lenth - 1

        setCurrentQuestionIndex((prevState) => prevState + 1);

        setTimeLeft(DURATION);
        setCanSelectOption(true);
        setSelectedOption(null);
        setPauseTime(false);
        setShowAnswer(false);
      }, 2000);
    } else {
      // Perfrom operation when question has ended

      // OPen to result after 2secs, to enble user
      // see last option selectd on last question
      setTimeout(() => {
        // Open modal to show result
        setShowResult(true);
      }, 2000);
    }
  };

  const handleQuestionReviewConfig = (option: Option) => {
    let isAnswer = handleIsAnswerCorrect(option);

    const timeSpeed = DURATION - timeLeft;

    let questionScore =
      isAnswer === 'correct' && DURATION > BONUSSPEED && timeSpeed <= BONUSSPEED
        ? BONUSPOINT
        : isAnswer === 'correct'
        ? CORRECTSCOREPOINT
        : 0;

    // Update points on ui
    updatePointUI(questionScore);

    let newQuestionReview = questionReviewData.map((question, index) => {
      if (index === currentQuestionIndex) {
        return {
          ...question,
          optionSelected: option?.value !== '' ? option.value : '',
          speed: DURATION - timeLeft,
          score: questionScore,
        };
      } else {
        return question;
      }
    });

    // Save update question  to set staet
    setQuestionReviewData(newQuestionReview);
    // Save question review details to storage
    localStorage.setItem('questionReview', JSON.stringify(newQuestionReview));
  };

  const handleUpdateBars = (option: Option) => {
    // Update Bars indicator
    const isAnswer = handleIsAnswerCorrect(option);

    const newBarIndicators = bars.map((bar, index) => {
      if (index === currentQuestionIndex) {
        return { state: isAnswer, curIndex: index };
      } else return bar;
    });

    setBars(newBarIndicators);
  };

  // Handle when an option is selected
  const handleOptionSelect = async (option: Option) => {
    if (currentQuestionIndex <= questions.length - 1) {
      if (canSelectOption) {
        // To know if user can select another option
        if (option) {
          // To update selected option only if option is present
          setSelectedOption(option);
        }
        handleQuestionReviewConfig(option);
        handleNextQuestion(option);
        handleUpdateBars(option);
      }
    }
  };

  // Effect for timeLeft changes
  useEffect(() => {
    if (timeLeft === 0) {
      setShowAnswer(true);
      handleOptionSelect({ value: '', id: 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft]);

  useEffect(() => {
    // Reset All To avoid Broswer reload
    if (questions.length > 0) {
      const newQuestionData = modifyQuestion();

      setBars(
        newQuestionData.map((_, index) => ({ state: '', curIndex: index }))
      );

      setTimeLeft(DURATION);
      setPauseTime(false);
      setPauseTime(false);
      setCurrentQuestionIndex(0);
      setSelectedOption(null);
      setShowAnswer(false);
      setShowResult(false);
      setCanSelectOption(true);
      setModalClickable(true);
      setQuestionReviewData(newQuestionData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions]);

  if (questions.length === 0) {
    return (
      <div className="no-question-error">
        <p>Questions for this quizz has not been added</p>

        <button
          className="no-question-error_btn"
          onClick={() => navigate(`/dashboard/create/${quizUUID}`)}
        >
          Add Questions
        </button>
      </div>
    );
  }

  return (
    <>
      <div className="quizz-trivia">
        <div className="countDown">
          <CircularProgressCountDown
            duration={DURATION}
            timeLeft={timeLeft}
            setTimeLeft={setTimeLeft}
            pauseTime={pauseTime}
          />
        </div>

        <div className="quiz-trivia__bars">
          <QuizzBars bars={bars} />
        </div>

        <div className="trivia-quizz-con">
          <div className="trivia-quizz-question">
            <p>{questions[currentQuestionIndex]?.question}</p>
          </div>
          <div className="trivia-quizz-options">
            {questions[currentQuestionIndex]?.options.map((option) => (
              <div
                className={`
            ${
              selectedOption &&
              selectedOption?.value ===
                questions[currentQuestionIndex]?.answer?.answer &&
              option?.value === questions[currentQuestionIndex]?.answer.answer
                ? 'quiz__correct-correct'
                : null
            }

              ${
                selectedOption &&
                selectedOption?.value !==
                  questions[currentQuestionIndex]?.answer?.answer &&
                selectedOption?.id === option?.id
                  ? 'quiz__wrong-wrong'
                  : null
              }

              ${
                selectedOption &&
                selectedOption?.value !==
                  questions[currentQuestionIndex]?.answer.answer &&
                option?.value ===
                  questions[currentQuestionIndex]?.answer?.answer
                  ? 'quiz__correct-answer'
                  : null
              }

              ${
                !selectedOption &&
                showAnswer &&
                option?.value ===
                  questions[currentQuestionIndex]?.answer?.answer
                  ? 'quiz__correct-answer'
                  : null
              }


              trivia-quizz-option
              `}
                key={option.id}
                onClick={() => {
                  handleOptionSelect(option);
                }}
              >
                {' '}
                {option.value}
              </div>
            ))}
          </div>
        </div>
      </div>

      {showResult && (
        <Modal
          extraFunc={() => {
            if (!modalClickable) {
              setShowResult(false);
              navigate(-1);
            }
          }}
        >
          <QuizResult
            setModalClickable={setModalClickable}
            backLink={backLink}
          />
        </Modal>
      )}
    </>
  );
};

export default QuizzTrivia;
