import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import client from './graphql/client';
import { ApolloProvider } from '@apollo/client';
import { AuthProvider } from './context/AuthContext';
import { Provider } from 'react-redux';
import store, { persistor } from './state/index';
import { PersistGate } from 'redux-persist/integration/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import SocketProvider from './context/socketContext';
// import { hydrateRoot } from 'react-dom/client';
const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID!;
const rootElement = document.getElementById('root') as HTMLElement;

const root = ReactDOM.createRoot(rootElement);

// if (rootElement?.hasChildNodes()) {
//   hydrateRoot(
//     rootElement,
//     <React.StrictMode>
//       <AuthProvider>
//         <Provider store={store}>
//           <PersistGate persistor={persistor} loading={null}>
//             <Router>
//               <ApolloProvider client={client}>
//                 <App />
//               </ApolloProvider>
//             </Router>{' '}
//           </PersistGate>
//         </Provider>
//       </AuthProvider>
//     </React.StrictMode>
//   );
// } else {
root.render(
  <React.StrictMode>
    <AuthProvider>
      <GoogleOAuthProvider clientId={CLIENT_ID}>
        <Provider store={store}>
          <PersistGate persistor={persistor} loading={null}>
            <Router>
              <SocketProvider>
                <ApolloProvider client={client}>
                  <App />
                </ApolloProvider>
              </SocketProvider>
            </Router>
          </PersistGate>
        </Provider>
      </GoogleOAuthProvider>
    </AuthProvider>
  </React.StrictMode>
);
// }
