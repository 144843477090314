export const SubTriviaCategoryData = [
  {
    __typename: 'SubcategoryRes',
    coverImage: '',
    subcategory: {
      __typename: 'Subcat',
      id: '1oECc3bxog9a8BK4',
      subcat: 'Coming Soon',
    },

    totalNumber: null,
  },
];
