import React from 'react';

import './ToggleSlider.css';

const ToggleSlider = ({
  isShow,
  extraFunc,
}: {
  isShow: boolean;
  extraFunc: () => void;
}) => {
  return (
    <div
      className={`${
        !isShow
          ? 'toggle-slider__outer-toggle-left'
          : 'toggle-slider__outer-toggle-right'
      } toggle-slider__outer`}
      onClick={() => extraFunc()}
    >
      <div
        className={`${
          isShow ? 'toggle-true' : 'toggle-false'
        } toggle-slider__inner`}
      ></div>
    </div>
  );
};

export default ToggleSlider;
