import React, { useEffect, useState } from 'react';

import './MultiplayerGameCode.css';
import { BackArrowSvg } from '../../components/Svgs/svgsComponents';
import { useNavigate, useParams } from 'react-router-dom';

export const MultiplayerGameCode = () => {
  const navigate = useNavigate();
  const { code } = useParams();

  const [userInviteCode, setUserInviteCode] = useState<string>(code || '');

  const handleJoinGame = async () => {
    // Other users iinitial socket and join game

    navigate('/multiplayer-waiting', {
      state: {
        code: userInviteCode,
      },
    });
  };

  useEffect(() => {
    const inviteCode = code && code !== null ? code : '';

    setUserInviteCode(inviteCode!);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container multiplayer-invited">
      <div className="multiplayer-invited__header">
        <span onClick={() => navigate(-1)}>{BackArrowSvg()}</span>

        <p>Game Code</p>
      </div>
      <div className="invited-code-con">
        <p>Enter game code</p>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleJoinGame();
          }}
        >
          <input
            type="text"
            value={userInviteCode}
            onChange={(e) => setUserInviteCode(e.target.value)}
            required
          />

          <button className="multiplayer-invited__btn">Next</button>
        </form>
      </div>
    </div>
  );
};
