import React, { useState } from 'react';
import SubscriptionPayment from '../Subscription/Subscription';
import './DashboardSubscription.css';

const DashboardSubscription = () => {
  const [isSubScribeInView, setIsSubScribeInView] = useState(true);
  return (
    <div className="dashboard-subscription">
      <SubscriptionPayment isSubScribeInView={isSubScribeInView} />

      {isSubScribeInView && (
        <div className="subscription-actons">
          <div className="subcription-actons__text">
            <h4>7 days free trial</h4>

            <p>
              Unlock a week of unlimited AI-powered insights with our 7-day free
              trial.
            </p>
          </div>
          <button onClick={() => setIsSubScribeInView(false)}>Continue</button>
        </div>
      )}
    </div>
  );
};

export default DashboardSubscription;
