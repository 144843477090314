import React from 'react';
import { TermsData } from './TermsData';
import NavBar from '../../components/NavBar/NavBar';
import Pitch from '../../components/Pitch/Pitch';
import Footer from '../../components/Footer/Footer';
import './Terms.css';

const Terms = () => {
  return (
    <div>
      <NavBar />

      <div className="terms__con">
        <div className="container terms__main">
          <div className="terms__main-header">
            <h3>TERMS OF USE</h3>
          </div>

          <div className="terms__main-body">
            {TermsData.split('\n').map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
          </div>
        </div>
      </div>

      <Pitch />
      <Footer />
    </div>
  );
};

export default Terms;
