import React from 'react';
import './SearchBar.css';
import { searchIconSvg } from '../Svgs/svgsComponents';

const SearchBar = () => {
  return (
    <div className="search-bar">
      <input type="text" placeholder="Search content" />

      <div>{searchIconSvg()}</div>
    </div>
  );
};

export default SearchBar;
