import React, { useEffect, useState } from 'react';
import { Option } from '../../types/Questions';
import { SocketCountDown, SocketQuestionsI } from '../../types/socket';
import SocketCirculerCountDown from '../../components/SocketCirculerCountDown/SocketCirculerCountDown';
import './MultiplayerQuiz.css';
import { useSocketContext } from '../../context/socketContext';

const MultiplayerQuiz = ({
  questionData,
  updatePointUI,
  countDown,
}: {
  questionData: SocketQuestionsI[];
  updatePointUI: (score: number) => void;
  countDown: SocketCountDown | null;
}) => {
  const DURATION = 20;

  const CORRECTSCOREPOINT = 1;

  const { emit } = useSocketContext();

  const [canSelectOption, setCanSelectOption] = useState(true);

  const [pauseTime, setPauseTime] = useState<boolean>(false);

  const [timeLeft, setTimeLeft] = useState<number>(DURATION);

  const [timePauseValue, setTimePauseValue] = useState(DURATION);

  const [countDownStatus, setCountDownStatus] = useState('');

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);

  const [selectedOption, setSelectedOption] = useState<Option | null>(null);

  const [showAnswer, setShowAnswer] = useState(false);

  // Know if current question is correct or wrong
  const handleIsAnswerCorrect = (option: Option) => {
    if (option) {
      return option?.value === ''
        ? ''
        : option.value === questionData[currentQuestionIndex].answer?.answer
        ? 'correct'
        : 'wrong';
    } else {
      return '';
    }
  };

  function modifyQuestion() {
    let newQuestion = questionData.map((question) => ({
      question: question?.question,
      questionUUID: question?.questionUUID,
      speed: DURATION,
      optionSelected: '',
      answer: question?.answer?.answer,
      explain: question?.answer?.answer_details,
      score: 0,
      options: [
        question?.options[0]?.value,
        question?.options[1]?.value,
        question?.options[2]?.value,
        question?.options[3]?.value,
      ],
    }));

    return newQuestion;
  }

  // Question review to save answers and selected options
  const [questionReviewData, setQuestionReviewData] = useState(
    questionData.length !== 0 ? modifyQuestion() : []
  );

  const handleQuestionReviewConfig = (option: Option) => {
    //  Review qquestion is use to showw the review of questions and answer
    // At end of game saaveed in a local storage call questionReview
    let isAnswer = handleIsAnswerCorrect(option);

    let questionScore = isAnswer === 'correct' ? CORRECTSCOREPOINT : 0;

    // Update points on ui
    updatePointUI(questionScore);

    let newQuestionReview = questionReviewData.map((question, index) => {
      if (index === currentQuestionIndex) {
        return {
          ...question,
          optionSelected: option?.value !== '' ? option.value : '',
          speed: DURATION - timeLeft,
          score: questionScore,
        };
      } else {
        return question;
      }
    });

    // Save update question  to set staet
    setQuestionReviewData(newQuestionReview);

    // Save question review details to storage
    localStorage.setItem('questionReview', JSON.stringify(newQuestionReview));
  };

  // Handle when an option is selected
  const handleOptionSelect = async (option: Option) => {
    // To Allow game seet well before triggering seeelection
    if (countDown && countDown.countdown < 20) {
      // when option has being selected sshow answwer
      setShowAnswer(true);

      if (
        currentQuestionIndex <= questionData.length - 1 &&
        countDownStatus !== 'game-end'
      ) {
        if (canSelectOption) {
          // To know if user can select another option
          if (option) {
            // To update selected option only if option is present
            setSelectedOption(option);

            let isAnswerCorrect = handleIsAnswerCorrect(option);

            // emit answer, sent to backend
            emit('submit-answer', {
              questionUUID: questionData[currentQuestionIndex]?.questionUUID,
              speed: timeLeft,
              isCorrect: isAnswerCorrect === 'correct' ? true : false,
            });
          }

          handleQuestionReviewConfig(option);
          setCanSelectOption(false);
          setPauseTime(true);
          setTimePauseValue(timeLeft);
        }
      }
    }
  };

  // A change count

  useEffect(() => {
    setTimeLeft(countDown?.countdown || DURATION);

    setPauseTime(
      countDown?.status === 'game-on' && selectedOption === null ? false : true
    );

    setCanSelectOption(
      countDown?.status === 'game-on' && selectedOption === null ? true : false
    );

    setCurrentQuestionIndex(countDown?.count || 0);

    setCountDownStatus(countDown?.status || '');

    if (
      countDown?.status === 'game-pause' ||
      countDown?.status === 'game-end'
    ) {
      // Default selcted option when
      // moving to next question after game - pause

      // When time out and option not picked
      if (selectedOption === null) {
        emit('submit-answer', {
          questionUUID: questionData[currentQuestionIndex]?.questionUUID,
          speed: timeLeft,
          isCorrect: false,
        });
      }

      if (countDown?.status === 'game-pause') {
        setSelectedOption(null);
        setShowAnswer(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countDown]);

  useEffect(() => {
    // Reset All To avoid Broswer reload
    if (questionData.length > 0) {
      const newQuestionData = modifyQuestion();

      setQuestionReviewData(newQuestionData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionData]);

  return (
    <div>
      <div className="multiplaper-count-down">
        <SocketCirculerCountDown
          timeLeft={timeLeft}
          duration={DURATION}
          pauseTime={pauseTime}
          timePauseValue={timePauseValue}
        />
      </div>

      <div className="trivia-quizz-con">
        <div className="trivia-quizz-question">
          <p>{questionData[currentQuestionIndex]?.question}</p>
        </div>
        <div className="trivia-quizz-options">
          {questionData[currentQuestionIndex]?.options.map((option) => (
            <div
              className={`

                    ${
                      selectedOption !== null &&
                      selectedOption?.value !==
                        questionData[currentQuestionIndex]?.answer.answer &&
                      option?.value ===
                        questionData[currentQuestionIndex]?.answer?.answer
                        ? 'quiz__correct-answer'
                        : null
                    }


                    ${
                      selectedOption !== null &&
                      selectedOption?.value ===
                        questionData[currentQuestionIndex]?.answer?.answer &&
                      option?.value ===
                        questionData[currentQuestionIndex]?.answer.answer
                        ? 'quiz__correct-correct'
                        : null
                    }

                    ${
                      selectedOption !== null &&
                      selectedOption?.value !==
                        questionData[currentQuestionIndex]?.answer?.answer &&
                      selectedOption?.id === option?.id
                        ? 'quiz__wrong-wrong'
                        : null
                    }



                    ${
                      selectedOption === null &&
                      showAnswer &&
                      option?.value ===
                        questionData[currentQuestionIndex]?.answer?.answer
                        ? 'quiz__correct-answer'
                        : null
                    }


                    trivia-quizz-option
              `}
              key={option.id}
              onClick={() => {
                handleOptionSelect(option);
              }}
            >
              {' '}
              {option.value}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MultiplayerQuiz;
