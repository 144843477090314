import React from 'react';

import './PointsIndicator.css';

const PointsIndicator = ({
  image,
  title,
  point,
}: {
  image: string;
  title: string;
  point: number;
}) => {
  return (
    <div className="outlet__header-icon">
      <div>
        <img src={image} alt={title} />
      </div>
      <div className="outlet__header-rate">
        <p>{title}</p>
        <p className="outlet__header-rate-value">{point}</p>
      </div>
    </div>
  );
};

export default PointsIndicator;
