import React, { useState } from 'react';
import LazyLoad from 'react-lazyload';
import './NavBar.css';
import { RxHamburgerMenu } from 'react-icons/rx';
import { IoCloseOutline } from 'react-icons/io5';
import { navLinkData } from './NavData';
import { useNavigate } from 'react-router';
import { useAuth } from '../../context/AuthContext';
import { useDispatch } from 'react-redux';
import { clearUser } from '../../state/userSlice';
import Modal from '../Modal/Modal';

const NavBar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isAuthenticated, logout } = useAuth();

  return (
    <>
      {isMobileMenuOpen && <div className="overlay"></div>}
      <section className="container navbar ">
        <div className="navbar__logo-links">
          <div
            className="navbar__logo"
            onClick={() => {
              navigate('/');
              setIsMobileMenuOpen(false);
            }}
          >
            <LazyLoad height={200} offset={100}>
              <img src="/images/Logo.png" alt="Logo" />
            </LazyLoad>
          </div>

          <ul className="navbar__links">
            {navLinkData.map(({ link, text }, index) => (
              <li
                key={index}
                className="navbar__link"
                onClick={() => {
                  navigate(link);
                }}
              >
                {text}
              </li>
            ))}
          </ul>
        </div>

        <div className="navbar__lang-auths">
          <div className="navbar__lang-con">
            <div className="not-for-current-sprint navbar__lang">
              <span>English</span>

              <span>
                <LazyLoad offset={100}>
                  <img src="/images/chevron-down.png" alt="chevron down" />
                </LazyLoad>
              </span>
            </div>

            {/* <div className="nav__lang-selections">
              {langData.map(({ lang }, index) => (
                <div key={index} className="nav__lang-selection">
                  {lang}
                </div>
              ))}
            </div> */}
          </div>
          {!isAuthenticated ? (
            <div className="navbar__auths">
              <p
                className="navbar__auth auth-signup"
                onClick={() => navigate('/signup')}
              >
                Sign Up
              </p>
              <p
                className="navbar__auth auth-login"
                onClick={() => navigate('/login')}
              >
                Log In
              </p>
            </div>
          ) : (
            <div className="nav__dashboard">
              {' '}
              <button
                className="btn nav__btn"
                onClick={() => navigate('/dashboard')}
              >
                Dashboard
              </button>
              <div
                className="nav__dashboard-logout"
                onClick={() => {
                  dispatch(clearUser());
                  logout();
                }}
              >
                <span>Logout</span>
              </div>
            </div>
          )}
        </div>

        {/* Mobile View */}
        {isMobileMenuOpen && (
          <Modal
            extraFunc={() => {
              setIsMobileMenuOpen(false);
            }}
          >
            <div
              className="navbar__mobile"
              onClick={(e) => e.stopPropagation()}
            >
              <ul className="navbar__mobile-items navbar__links">
                {navLinkData.map(({ link, text }) => (
                  <li
                    className="navbar__link bold__links"
                    onClick={() => {
                      navigate(link);
                    }}
                  >
                    {text}
                  </li>
                ))}
              </ul>

              <div className="navbar__mobile-items navbar__lang-auths">
                <div className="not-for-current-sprint navbar__lang bold__links">
                  <span>English</span>

                  <span>
                    <LazyLoad height={50} offset={100}>
                      <img src="/images/chevron-down.png" alt="chevron down" />
                    </LazyLoad>
                  </span>
                </div>

                {!isAuthenticated ? (
                  <div className="navbar__auths bold__links">
                    <p
                      className="navbar__auth"
                      onClick={() => navigate('/signup')}
                    >
                      Signup
                    </p>
                    <p
                      className="navbar__auth"
                      onClick={() => navigate('/login')}
                    >
                      Login
                    </p>
                  </div>
                ) : (
                  <div className="mobile-dashboard-logout">
                    <button
                      className="btn nav__btn"
                      onClick={() => navigate('/dashboard')}
                    >
                      Dashboard
                    </button>

                    <div
                      className="nav__dashboard-logout"
                      onClick={() => {
                        dispatch(clearUser());
                        logout();
                      }}
                    >
                      <span>Logout</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal>
        )}

        <div className="mobile__toggle-menu">
          {isMobileMenuOpen ? (
            <IoCloseOutline
              onClick={() => setIsMobileMenuOpen(false)}
              className="mobile__toggle-item-close "
            />
          ) : (
            <RxHamburgerMenu
              onClick={() => setIsMobileMenuOpen(true)}
              className="mobile__toggle-item-open "
            />
          )}
        </div>
      </section>
    </>
  );
};

export default NavBar;
