import React, { useState } from "react";
import NavBar from "../../components/NavBar/NavBar";
import "./FAQ.css";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import { FAQData } from "./FAQData";
import Pitch from "../../components/Pitch/Pitch";
import Footer from "../../components/Footer/Footer";
import SEO from "../../components/SEO/SEO";

const FAQ = () => {
  const [selectedFAQ, setSelectedFAQ] = useState<number | null>(null);

  return (
    <section>
      <section className="faq">
        <NavBar />
        <SEO
          title="FAQ"
          description="Answers to Your Burning Questions: Check Out Our FAQs!"
          name={"medscroll"}
          type="article"
        />
        <div className="container faq__header">
          <h1>Answers to Your Burning Questions: Check Out Our FAQs!</h1>
          <p>
            Find quick answers to common inquiries about MedScroll. If you don't
            see what you're looking for, feel free to reach out to us directly.
          </p>
        </div>
        <div className="faq__con">
          <div className="container faq__main">
            <div className="faq__main-header">
              <h3>Frequently Asked Questions</h3>
            </div>

            {/* FAQ section */}
            <div className="faq__items">
              {FAQData.map(({ title, body }, index) => (
                <div className="faq__item" key={index}>
                  <div
                    className="faq__item-header"
                    onClick={() =>
                      setSelectedFAQ((prev) => (prev === index ? null : index))
                    }
                  >
                    <span>{title}</span>
                    {selectedFAQ === index ? (
                      <FaChevronUp />
                    ) : (
                      <FaChevronDown />
                    )}
                  </div>

                  <div
                    className={`${
                      selectedFAQ === index ? "active-faq" : "faq__item-body"
                    }`}
                  >
                    {body}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <Pitch />
        <Footer />
      </section>
    </section>
  );
};

export default FAQ;
