import { AddQuestionI } from '../types/CreateQuestions';

export function modifiyAIQuizData(questionData: any) {
  const modifiedData = questionData.map((questionValue: AddQuestionI) => ({
    answer: questionValue.answer,
    answer_details: questionValue?.answer_details,
    images: questionValue?.images || [],
    options: questionValue?.options.map((option) => ({
      value: option,
      id: '',
    })),
    question: questionValue?.question,
    reference: questionValue?.reference || '',
    subtopic: questionValue?.subtopic || '',
    topic: '',
  }));

  return modifiedData;
}
