import React, { useEffect, useState } from 'react';

import './QuestionBankCard.css';

import { moreOPtionSvg } from '../Svgs/svgsComponents';
import Modal from '../Modal/Modal';
import QuestionDetails from '../QuestionDetails/QuestionDetails';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { DELETE_QUIZ } from '../../graphql/mutatons/question';
import client from '../../graphql/client';
import { toast } from 'react-toastify';
import SpinnerLoader from '../SpinnerLoader/SpinnerLoader';

const QuestionBankCard = ({
  coverImage,
  description,
  topic,
  cardId,

  point,
  totalDuration,
  totalQuestion,
  duration,
  updateUiAfterDelete,
}: {
  coverImage: string | null;
  description: string | null;
  topic: string;
  cardId: string;
  point: number;
  totalDuration: string;
  totalQuestion: number;
  duration: string;
  updateUiAfterDelete: (quizzId: string) => void;
}) => {
  const navigate = useNavigate();
  const [moreOPtionClicked, setMoreOptionClicked] = useState('');
  const [showQuestionDetails, setShowQuestionDetails] = useState(false);
  const [selectedQuizId, setSelectedQuizId] = useState<string | null>(null);

  // Mutation to handle delete
  const [deleteQuiz, { loading, error, data }] = useMutation(DELETE_QUIZ, {
    client,
  });

  const handleQuizDeletion = async (quizId: string) => {
    await deleteQuiz({
      variables: {
        quizUuid: quizId,
      },
    });
  };

  const handleClosePopup = () => {
    setMoreOptionClicked('');
  };

  useEffect(() => {
    if (error) {
      toast.error(error?.message || 'Unwxpwxted error occured');
    }

    if (data) {
      toast.success(data?.deleteQuiz?.message || ' Quiz deleted successfully');
      // Call to update Ui
      updateUiAfterDelete(cardId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);

  if (loading) return <SpinnerLoader loading={loading} />;

  return (
    <div>
      <div className="question-bank__card">
        <div
          className="question-bank__card-img"
          onClick={() => {
            // Route to question quizz
            setSelectedQuizId(cardId);
            setShowQuestionDetails(true);
          }}
        >
          <img src={coverImage || '/images/card-img.png'} alt="card" />
        </div>

        <div className="question-bank__card-content">
          <div className="question-bank__card-content__topic ">
            <h5>
              {' '}
              {topic && topic.length <= 15
                ? topic
                : topic && topic.length > 15
                ? topic.slice(0, 15) + '...'
                : null}
            </h5>

            <div className="question-bank__card-more-option">
              <div
                onClick={() => setMoreOptionClicked(cardId)}
                className="question-bank__card-more-option-item"
              >
                {moreOPtionSvg()}
              </div>

              {/* Popup */}
              {moreOPtionClicked === cardId && (
                <Modal
                  extraFunc={() => {
                    handleClosePopup();
                  }}
                >
                  <ul className="question-bank__card-more-option-popup">
                    <li
                      onClick={() => {
                        handleClosePopup();
                        navigate(`/dashboard/create/${cardId}`);
                      }}
                    >
                      Edit
                    </li>
                    <li
                      onClick={() => {
                        handleClosePopup();
                      }}
                      className="not-for-current-sprint"
                    >
                      Invite players
                    </li>
                    <li
                      onClick={() => {
                        handleClosePopup();
                        handleQuizDeletion(cardId);
                      }}
                      className="delete-option"
                    >
                      Delete
                    </li>
                  </ul>
                </Modal>
              )}
            </div>
          </div>

          <p className="question-bank__card-descrip">
            {description && description.length <= 25
              ? description
              : description && description.length > 25
              ? description.slice(0, 25) + '...'
              : null}
          </p>
        </div>
        {showQuestionDetails && cardId === selectedQuizId && (
          <Modal
            extraFunc={() => {
              setShowQuestionDetails(false);
              setSelectedQuizId(null);
            }}
          >
            <QuestionDetails
              point={point}
              totalDuration={totalDuration}
              totalQuestion={totalQuestion}
              duration={duration}
              quizId={cardId}
            />
          </Modal>
        )}
      </div>
    </div>
  );
};

export default QuestionBankCard;
