import { OptionI } from '../types/CreateQuestions';

// Function to shuffle an array
export function shuffleArray(array: OptionI[]) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]; // Swap elements
  }
  return array;
}

export function moveAnswerToCorrectIndex(options: OptionI[], answer: string) {
  // Remove the element if it exists
  // Find the index of the object with the matching id
  const elementIndex = options.findIndex((option) => option.value === answer);
  if (elementIndex !== -1) {
    // Remove the object from the array
    const [removedElement] = options.splice(elementIndex, 1);
    // Insert the object at the desired index (index 1)
    options.splice(1, 0, removedElement);
  }
  return options;
}
