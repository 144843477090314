import React, { useRef } from 'react';
import './About.css';
import LazyLoad from 'react-lazyload';
import { aboutData, featuresData } from './AboutData';
import { useNavigate } from 'react-router-dom';
import useCustomInVew from '../../../../hooks/useCustomInVew';

const About: React.FC = () => {
  const navigate = useNavigate();

  const refAboutPara = useRef<HTMLDivElement | null>(null);

  // feature card refs
  const featureCardRef0 = useRef<HTMLDivElement | null>(null);
  const featureCardRef1 = useRef<HTMLDivElement | null>(null);
  const featureCardRef2 = useRef<HTMLDivElement | null>(null);
  const featureCardRef3 = useRef<HTMLDivElement | null>(null);

  // feature card text refs
  const caseCardTextRef0 = useRef<HTMLDivElement | null>(null);
  const caseCardTextRef1 = useRef<HTMLDivElement | null>(null);
  const caseCardTextRef2 = useRef<HTMLDivElement | null>(null);
  const caseCardTextRef3 = useRef<HTMLDivElement | null>(null);

  // feature card Img refs
  const caseCardImgRef0 = useRef<HTMLDivElement | null>(null);
  const caseCardImgRef1 = useRef<HTMLDivElement | null>(null);
  const caseCardImgRef2 = useRef<HTMLDivElement | null>(null);

  // For feature card -- in view
  const featureCardInView0 = useCustomInVew(featureCardRef0, 0.4);

  const featureCardInView1 = useCustomInVew(featureCardRef1, 0.4);

  const featureCardInView2 = useCustomInVew(featureCardRef2, 0.4);

  const featureCardInView3 = useCustomInVew(featureCardRef3, 0.4);

  // For case card -- in view Text
  const caseCardTextInView0 = useCustomInVew(caseCardTextRef0, 0.47);
  const caseCardTextInView1 = useCustomInVew(caseCardTextRef1, 0.47);
  const caseCardTextInView2 = useCustomInVew(caseCardTextRef2, 0.47);

  // For case card -- in view Text
  const caseCardImgInView0 = useCustomInVew(caseCardTextRef0, 0.47);
  const caseCardImgInView1 = useCustomInVew(caseCardTextRef1, 0.47);
  const caseCardImgInView2 = useCustomInVew(caseCardTextRef2, 0.47);

  const aboutInView = useCustomInVew(refAboutPara);

  return (
    <section className="">
      <div className="container about">
        <div className="about__header" id="about">
          <div className="about__feature gradiat-border">Our Features</div>

          <p
            ref={refAboutPara}
            className={`
            ${aboutInView ? 'scale-out' : ''}
            about__header-text`}
          >
             With MedScroll, you're not just learning; you're actively
            participating in a community that values knowledge, fun, and
            continuous improvement.
          </p>
        </div>

        <div className="about_points" typeof="button">
          {aboutData.map(({ title, text, icon }, index) => (
            <div
              ref={
                {
                  0: featureCardRef0,
                  1: featureCardRef1,
                  2: featureCardRef2,
                  3: featureCardRef3,
                }[index]
              }
              className={`

                ${
                  featureCardInView0 && index === 0
                    ? 'animate-slide-in-left'
                    : ''
                }

                 ${
                   featureCardInView1 && index === 1
                     ? 'animate-slide-in-right'
                     : ''
                 }

                    ${
                      featureCardInView2 && index === 2
                        ? 'animate-slide-in-left'
                        : ''
                    }

                       ${
                         featureCardInView3 && index === 3
                           ? 'animate-slide-in-right'
                           : ''
                       }

              about__box`}
              key={title}
            >
              <LazyLoad offset={100}>{icon()}</LazyLoad>
              <span className="about_number">{title}</span>
              <p className="about__header-text-sub">{text}</p>
            </div>
          ))}
        </div>

        {/* Case presentation */}

        <div className="feature__case-recall">
          {featuresData.map(({ title, img, text }, index) => (
            <div className="feature__case-recall-item" key={title}>
              <div
                ref={
                  {
                    0: caseCardTextRef0,
                    1: caseCardTextRef1,
                    2: caseCardTextRef2,
                    3: caseCardTextRef3,
                  }[index]
                }
                className={`

                  ${caseCardTextInView0 && index === 0 ? 'scale-out' : ''}
                  ${
                    caseCardTextInView1 && index === 1 ? 'scale-out' : ''
                  }                  ${
                  caseCardTextInView2 && index === 2 ? 'scale-out' : ''
                }
                  feature__case-recall-item__left

                  `}
              >
                <span>AI features</span>

                <h3 className="gradient-text-rainbow">{title}</h3>

                <p>{text}</p>

                <button
                  className={` ${
                    index === 1
                      ? 'feature__case-recall-item__btn-colored'
                      : 'feature__case-recall-item__btn-normal'
                  }  `}
                  onClick={() => navigate('/dashboard/create')}
                >
                  Get started
                </button>
              </div>

              <div
                ref={
                  {
                    0: caseCardImgRef0,
                    1: caseCardImgRef1,
                    2: caseCardImgRef2,
                  }[index]
                }
              >
                <LazyLoad
                  offset={100}
                  className={`


                  ${
                    caseCardImgInView0 && index === 0
                      ? 'animate-slide-in-right '
                      : ''
                  }
                ${
                  caseCardImgInView1 && index === 1
                    ? 'animate-slide-in-left'
                    : ''
                }
                ${
                  caseCardImgInView2 && index === 2
                    ? 'animate-slide-in-right'
                    : ''
                }

                  about__feature-img-con`}
                >
                  <img src={img} alt={title} />
                </LazyLoad>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default About;
