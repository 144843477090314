export const PrivacyData = `



At MedScroll, owned and operated by Loopscribe Pty Ltd (ABN: 29 664555588), we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard the information you provide to us when using our medical education app, website, and associated services (collectively, the "Service").

We respect your privacy



MedScroll respects your right to privacy and is committed to safeguarding the privacy of our customers and website visitors. This policy sets out how we collect and treat your personal information.



We adhere to the Australian Privacy Principles contained in the Privacy Act 1988 (Cth) and to the extent applicable, the EU General Data Protection Regulation (GDPR).



"Personal information" is information we hold which is identifiable as being about you. This includes information such as your name, email address, identification number, or any other type of information that can reasonably identify an individual, either directly or indirectly.



You may contact us in writing at Level 1 390 St Kilda Road, Melbourne, Victoria, 3004 for further information about this Privacy Policy.


INFORMATION WE COLLECT

When you sign up for our Service or create a user profile, we may collect the following personal information:

- Name
- Email address
- Username
- Role (e.g., doctor, medical student)
- Specialty (if applicable)
- Location

We do not directly collect payment information, as all in-app purchases are handled through the respective app stores.

HOW WE USE YOUR INFORMATION

We use the information we collect for the following purposes:

- To provide, maintain, and improve our Service
- To personalize your experience and tailor content to your interests and preferences
- To send you updates, promotional offers, and other communications related to our Service
- To analyze usage trends and gather analytics data (in an anonymized and non-identifiable form)

We do not sell or rent your personal information to third parties.

MACHINE LEARNING AND ALGORITHM TRAINING

We reserve the right to use user-generated content and interactions within the Service in an anonymized and aggregated manner to train and optimize our algorithms and machine learning models for the purpose of enhancing the functionality, personalization, and user experience of the Service.

DISCLOSURE OF INFORMATION

We may disclose your personal information to third-party service providers who assist us in operating our Service, such as those providing authentication, payment processing, or other necessary services. These third parties are prohibited from using your personal information for any purpose other than facilitating the services they provide to us.

We may also disclose your information when required by law, to protect our rights or the rights of others, or in response to valid legal processes.

DATA SECURITY AND STORAGE

We implement industry-standard security measures to protect the personal information we collect. However, please be aware that no method of transmitting or storing data is entirely secure, and we cannot guarantee the absolute security of your information.

You are responsible for maintaining the confidentiality of your account credentials and for any activities that occur under your account.

USER RIGHTS AND CHOICES

Depending on your location and the applicable privacy laws, you may have certain rights regarding your personal information, such as the right to access, correct, delete, or object to the processing of your data. We will facilitate the exercise of these rights in accordance with applicable laws.

If you are a resident of the European Economic Area (EEA), we will process your personal information in compliance with the General Data Protection Regulation (GDPR). For more information on your rights and how we process your data under the GDPR, please refer to the GDPR section below.

If you are a resident of California, USA, we will process your personal information in compliance with the California Consumer Privacy Act (CCPA). For more information on your rights and how we process your data under the CCPA, please refer to the CCPA section below.

DATA RETENTION

We will retain your personal information for as long as you remain a registered user of our Service. After you delete your account, we may retain certain information for up to 90 days before permanently deleting it from our systems.

MINORS' PRIVACY

Our Service is not intended for children under the age of 16. We do not knowingly collect personal information from minors without parental consent. If you become aware that a minor has provided us with personal information without parental consent, please contact us, and we will take appropriate steps to remove that information from our systems.

INTERNATIONAL DATA TRANSFERS

Your personal information may be transferred to and processed in countries other than the country in which you reside. We will ensure that any such transfers are conducted in compliance with applicable data protection laws and regulations, and that appropriate safeguards are in place to protect your personal information.

COOKIES AND TRACKING TECHNOLOGIES

We use cookies and similar tracking technologies to enhance your experience, analyze trends and usage patterns, and serve targeted advertisements. You can control the use of cookies through your browser settings.

CONTACT INFORMATION

If you have any questions, concerns, or requests regarding this Privacy Policy or the handling of your personal information, please contact us at hello@loopscribe.com.

GDPR COMPLIANCE

If you are a resident of the European Economic Area (EEA), we process your personal information in accordance with the General Data Protection Regulation (GDPR). Under the GDPR, you have the following rights:

- Right to Access: You have the right to request access to the personal information we hold about you.
- Right to Rectification: You have the right to request that we correct any inaccurate or incomplete personal information we hold about you.
- Right to Erasure: You have the right to request that we erase your personal information in certain circumstances.
- Right to Restrict Processing: You have the right to request that we restrict the processing of your personal information in certain circumstances.
- Right to Data Portability: You have the right to receive your personal information in a structured, commonly used, and machine-readable format.
- Right to Object: You have the right to object to the processing of your personal information for certain purposes, including direct marketing.

To exercise any of these rights, please contact us at hello@loopscribe.com.

CCPA COMPLIANCE

If you are a resident of California, USA, we process your personal information in accordance with the California Consumer Privacy Act (CCPA). The CCPA grants you the following rights:

- Right to Know: You have the right to request information about the categories of personal information we collect, use, disclose, and sell, as well as the specific pieces of personal information we have collected about you.
- Right to Delete: You have the right to request that we delete the personal information we have collected about you, subject to certain exceptions.
- Right to Opt-Out of Sale: We do not sell your personal information, but if we ever do, you have the right to opt-out of the sale of your personal information.
- Right to Non-Discrimination: You have the right not to be discriminated against for exercising your rights under the CCPA.

To exercise any of these rights, please contact us at hello@loopscribe.com.

UPDATES TO THIS PRIVACY POLICY

We may update this Privacy Policy from time to time to reflect changes in our practices or applicable laws. The updated version will be posted on our website, and we encourage you to review it periodically.

By continuing to use our Service after any changes to this Privacy Policy, you agree to be bound by the updated version.

Last updated: 5th April, 2024

`;
