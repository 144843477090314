import { gql } from '@apollo/client';

export const GET_SUB_CATEGORY = gql`
  query GetSubategory($category: String) {
    getSubategory(category: $category) {
      coverImage
      subcategory {
        id
        subcat
      }
      totalNumber
    }
  }
`;
