import React, { useEffect, useState } from 'react';
import './MultiplayerQuizResult.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../state';
import { UserI } from '../../types/AuthResponse';
import UserProfilePic from '../UserProfilePic/UserProfilePic';
import { CloseSvg, crownIconSvg, homeIconSvg } from '../Svgs/svgsComponents';
import { PlayerScoreI, ReplayI } from '../../types/socket';
import { sortLeadderBoard } from '../../Utils/sortLeaderBoard';
import { useNavigate } from 'react-router-dom';
import { useSocketContext } from '../../context/socketContext';
import { QuestionReviewI } from '../../types/Questions';
import Modal from '../Modal/Modal';
import QuizzReview from '../QuizzReview/QuizReview';
import { toast } from 'react-toastify';

const TABLEHEAD = ['Position', 'Username', 'Scores'];

const MultiplayerQuizResult = ({
  usersDetails,
  totalQuestion,
  setCanClickReply,
  canClickReply,
  replyData,
}: {
  usersDetails: PlayerScoreI[] | [];
  totalQuestion: number;
  setCanClickReply: React.Dispatch<React.SetStateAction<boolean>>;
  canClickReply: boolean;
  replyData: ReplayI;
}) => {
  const navigate = useNavigate();

  const { emit, disConnectSocket } = useSocketContext();

  const user = useSelector((state: RootState) => state.user) as UserI;

  const [singleUserScoreDetail, setSingleUserScoreDetail] =
    useState<PlayerScoreI | null>(null);

  const [leaderBoardData, setLeaderBoardData] = useState<PlayerScoreI[] | []>(
    []
  );

  const [showReplyPrompt, setShowReplayPrompt] = useState(true);

  const [topThreeUsers, setTopThreeUsers] = useState<PlayerScoreI[] | []>([]);

  const [isShowSingleDetail, setIsShowSingleDetail] = useState(true);

  const [isOpenQuizReview, setIsOpenQuizReview] = useState(false);

  const [questions, setQuestions] = useState<QuestionReviewI[]>();

  const handleReplay = () => {
    if (!canClickReply) {
      toast.warning('replay has being clicked already');
      return;
    }

    emit('replay-game', {}, (ackGameCode: any) => {
      navigate(`/multiplayer-generate-code/${ackGameCode}`);
    });
  };

  useEffect(() => {
    if (usersDetails.length > 0) {
      // Get position of result outcome
      const sortedUserData = sortLeadderBoard(usersDetails);

      // Top three users score result
      if (sortedUserData.length >= 3) {
        const toppers: PlayerScoreI[] = sortedUserData.slice(0, 3);

        const lastTopper = toppers.pop();

        if (lastTopper !== undefined) {
          toppers.unshift(lastTopper);
        }

        setTopThreeUsers(toppers);
      } else {
        setTopThreeUsers(sortedUserData);
      }

      // other user expect top three on the table
      const leaderBoardUsers = sortedUserData.slice(3);

      setLeaderBoardData(leaderBoardUsers);

      // For single player result view
      const ownUser = sortedUserData
        .filter((userItem) => userItem?.userUUID === user?.userUUID)
        .map((userData) => userData);

      setSingleUserScoreDetail({ ...ownUser[0] });
    }

    // Check if localStorage Review Question exist
    if (localStorage.getItem('questionReview')) {
      const reviewQuestion = localStorage.getItem('questionReview');

      const parsedReviewQuestion =
        reviewQuestion !== null && JSON.parse(reviewQuestion);

      setQuestions(parsedReviewQuestion);
    }
  }, [user?.userUUID, usersDetails]);

  return (
    <div className="multiplayer-result-con">
      <div className="multiplayer-result-main">
        <div className="  multiplayer-result-main__top">
          <div className="container multiplayer-result-main__top-header">
            <div
              onClick={() => {
                setIsShowSingleDetail(true);
              }}
            >
              {homeIconSvg()}
            </div>

            <div
              onClick={() => {
                disConnectSocket();
                navigate('/dashboard');
              }}
            >
              {CloseSvg()}
            </div>
          </div>
          {isShowSingleDetail ? (
            <div className="multiplayer-result-profile">
              <span className="multiplayer-result-profile__crown">
                {crownIconSvg()}
              </span>
              <div className="multiplayer-result-profile__item">
                <UserProfilePic userImage={singleUserScoreDetail?.url} />
                <p className="multiplayer-result-profile__position">
                  {singleUserScoreDetail?.position}
                </p>
                <div>
                  <p>
                    {singleUserScoreDetail?.userUUID === user?.userUUID
                      ? 'You'
                      : singleUserScoreDetail?.firstName}
                  </p>
                  <p className="multiplayer-result-profile__totalpoints">
                    {singleUserScoreDetail?.score || 0}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="multiplayer-result__top-three">
              {topThreeUsers.map(
                ({ position, url, score, firstName, userUUID }) => (
                  <div className="multiplayer-result-profile" key={userUUID}>
                    {position === 1 && (
                      <span className="multiplayer-result-profile__crown">
                        {crownIconSvg()}
                      </span>
                    )}
                    <div
                      className={`multiplayer-result-profile__topper-${position}   multiplayer-result-profile__item`}
                    >
                      <div className={`topper-${position}`}>
                        <UserProfilePic userImage={url} />
                      </div>
                      <p className="multiplayer-result-profile__position">
                        {position}
                      </p>
                      <div
                        className={`multiplayer-result-profile__topper-details-${position} `}
                      >
                        <p>{userUUID === user?.userUUID ? 'You' : firstName}</p>
                        <p
                          className={`multiplayer-result-profile__totalpoints-${position}  multiplayer-result-profile__totalpoints`}
                        >
                          {score}
                        </p>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          )}
        </div>

        {/* Score section */}
        {isShowSingleDetail && (
          <div className="multiplayer-result__user-score">
            <div className="multiplayer-result__user-score__item">
              <div className="multiplayer-result__user-score__item-img-con">
                <img src="/images/trophy.png" alt="trophy" />
              </div>
              <div className="multiplayer-result__user-score__item-content">
                <p className="multiplayer-result__user-score__item-number">
                  {totalQuestion}
                </p>
                <p className="multiplayer-result__user-score__item-question-text">
                  Total questions
                </p>
              </div>
            </div>
            <div className="multiplayer-result__user-score-pair">
              <div className="multiplayer-result__user-score__item">
                <div className="multiplayer-result__user-score__item-img-con">
                  <img src="/images/green-add.png" alt="trophy" />
                </div>
                <div className="multiplayer-result__user-score__item-content">
                  <p className="multiplayer-result__user-score__item-number">
                    {singleUserScoreDetail?.correct}
                  </p>
                  <p className="multiplayer-result__user-score__item-correct-text">
                    Correct
                  </p>
                </div>
              </div>

              <div className="multiplayer-result__user-score__item">
                <div className="multiplayer-result__user-score__item-img-con">
                  <img src="/images/red-minus.png" alt="trophy" />
                </div>
                <div className="multiplayer-result__user-score__item-content">
                  <p className="multiplayer-result__user-score__item-number">
                    {singleUserScoreDetail?.incorrect}
                  </p>
                  <p className="multiplayer-result__user-score__item-incorrect-text">
                    Incorrect
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Leader Board */}

        {!isShowSingleDetail && (
          <div className="muliplayer-table-main">
            <div className="muliplayer-table-head">
              {TABLEHEAD.map((head, index) => (
                <div className="muliplayer-table-head__item" key={index}>
                  {head}
                </div>
              ))}
            </div>

            {leaderBoardData.length > 0 ? (
              leaderBoardData.map(
                ({ userUUID, url, position, firstName, score }) => (
                  <div
                    className={`${
                      userUUID !== user?.userUUID
                        ? 'others-table-row'
                        : 'own-table-row'
                    }  muliplayer-table-row`}
                    key={userUUID}
                  >
                    <div className="muliplayer-table-row__item">
                      <div className="muliplayer-table-row__item-position">
                        <div>
                          {userUUID !== user?.userUUID ? (
                            <img
                              src="/images/position-star.png"
                              alt="position view"
                            />
                          ) : (
                            <img
                              src="/images/blue-start-position.png"
                              alt="star"
                            />
                          )}
                        </div>

                        <span>{position}</span>
                      </div>

                      <div className="muliplayer-table-row__item-img-con">
                        <img src={url || '/images/guest.svg'} alt="user" />
                      </div>
                    </div>

                    <div className="muliplayer-table-row__item">
                      {firstName}
                    </div>

                    <div className="muliplayer-table-row__item">{score}</div>
                  </div>
                )
              )
            ) : (
              <h3>No data</h3>
            )}
          </div>
        )}

        <div className="multiplayer-result__options">
          <div
            className="multiplayer-result__options-item"
            onClick={() => {
              setIsShowSingleDetail(false);
            }}
          >
            <div>
              <img src="/images/trophy.png" alt="trophy" />
            </div>
            <p>Leaderboard</p>
          </div>

          <div
            className="multiplayer-result__options-item"
            onClick={() => setIsOpenQuizReview(true)}
          >
            <div>
              <img src="/images/message-question.png" alt="trophy" />
            </div>
            <p>Review Answer</p>
          </div>

          {canClickReply && (
            <div
              className="multiplayer-result__options-item"
              onClick={() => handleReplay()}
            >
              <div>
                <img src="/images/arrow-row.png" alt="trophy" />
              </div>
              <p>Play Again</p>
            </div>
          )}
        </div>
      </div>

      {isOpenQuizReview && (
        <Modal
          extraFunc={() => {
            setIsOpenQuizReview(false);
          }}
        >
          <QuizzReview
            questions={questions || []}
            setIsOpenQuizReview={setIsOpenQuizReview}
          />
        </Modal>
      )}

      {showReplyPrompt && !canClickReply && replyData !== null && (
        <Modal
          extraFunc={() => {
            // setIsOpenQuizReview(false);
          }}
        >
          <div className="reply-game-con">
            <h3>Play Again</h3>

            <p>{replyData?.message}</p>

            <div className="reply-game-btns">
              <button
                className="accept"
                onClick={() => {
                  // Route to waiting with code
                  navigate(`/dashboard/multiplyer-invited/${replyData?.code}`);
                  disConnectSocket();
                }}
              >
                Accept
              </button>
              <button
                className="decline"
                onClick={() => {
                  // Close modal
                  setShowReplayPrompt(false);
                }}
              >
                Decline
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default MultiplayerQuizResult;
