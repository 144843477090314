import { useEffect, useState } from 'react';

const GradientPosition = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  // const windowWidth = window.innerHeight;

  useEffect(() => {
    const updateMousePosition = (ev: any) => {
      setMousePosition({ x: ev.pageX, y: ev.pageY });
    };

    window.addEventListener('mousemove', updateMousePosition);

    //  Clean up
    return () => {
      window.removeEventListener('mousemove', updateMousePosition);
    };
  }, []);

  return mousePosition;
};

export default GradientPosition;
