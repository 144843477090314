export const footerSocials = [
  {
    link: 'https://www.facebook.com/medscroll',
    img: '/images/facebook-logo.png',
  },
  {
    link: 'https://www.linkedin.com/medscroll',
    img: '/images/linkedin-icon.png',
  },
  {
    link: 'https://www.instagram.com/medscroll',
    img: '/images/instagram.png',
  },
  {
    link: 'https://www.x.com/medscroll',
    img: '/images/x-icon.png',
  },
  {
    link: 'https://www.tiktok.com/@themedscroll',
    img: '/images/tictok-icon.png',
  },
  {
    link: 'https://www.youtube.com/channel/UCQM4Jeqoi5EcS8sbO7jyBdA',
    img: '/images/youtube-icon.png',
  },
];
