import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

import './ImageDragDrop.css';
import { imageUploadIconSvg } from '../Svgs/svgsComponents';
import { FileWithPreviewI } from '../../types/GlobalType';

interface ImageDragDropProps {
  multiple?: boolean;
  setFileUpload: React.Dispatch<
    React.SetStateAction<FileWithPreviewI | File | undefined | null | FormData>
  >;
  imageUrl?: string | null;
}

const ImageDragDrop: React.FC<ImageDragDropProps> = ({
  multiple = false,
  setFileUpload,
  imageUrl,
}) => {
  const [files, setFiles] = useState<FileWithPreviewI[]>([]);

  const imageData = imageUrl !== null ? `${imageUrl}` : null;

  const [isDropImage, setIsDropImage] = useState<string | null>(imageData);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      // Reset drop image to null
      // incase of updating cover image
      setIsDropImage(null);

      if (multiple) {
        const newFiles = acceptedFiles.map((file) =>
          Object.assign(file, { preview: URL.createObjectURL(file) })
        );
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);
      } else {
        const file = acceptedFiles[0];

        const formData = new FormData();
        formData.append('file', file);

        setFileUpload(file);

        setFiles([{ ...file, preview: URL.createObjectURL(file) }]);
        acceptedFiles = [];
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [multiple]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'image/*': [] },
    multiple,
  });

  const thumbs =
    isDropImage === null ? (
      files.map((file) => (
        <div key={file.name} className="question-add-thumbs-view">
          <div className="question-add-thumbs-view__item">
            <img src={file.preview} alt={file.name} />
          </div>
        </div>
      ))
    ) : (
      <div className="question-add-thumbs-view">
        <div className="question-add-thumbs-view__item">
          <img src={isDropImage ?? ''} alt="dissplay cover" />
        </div>
      </div>
    );

  useEffect(() => {
    // Revoke the data URIs to avoid memory leaks
    // files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <div key={files.map((file) => file.name).join(',')}>
      <div {...getRootProps()} className="drop-zone">
        <input {...getInputProps()} />
        <div className="drop-zone__context">
          {imageUploadIconSvg()}
          <p>Drag and drop your cover image.</p>
        </div>
      </div>
      <div className="thumb-parent">{thumbs}</div>
    </div>
  );
};

export default ImageDragDrop;
