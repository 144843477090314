import React, { useState } from 'react';

import './QuestionBankMenu.css';
import Modal from '../Modal/Modal';

const QuestionBankMenu = () => {
  const [isMenuOPen, setIsMenuOpen] = useState(false);

  const handleClosePopup = () => {
    setIsMenuOpen(false);
  };
  return (
    <div className="question-bank__menu">
      <img
        src="/images/menu-icon.png"
        alt="menu"
        onClick={() => setIsMenuOpen(true)}
      />

      {isMenuOPen && (
        <Modal
          extraFunc={() => {
            setIsMenuOpen(false);
          }}
        >
          <ul className="question-bank__menu-popup-menu">
            <li
              onClick={() => {
                handleClosePopup();
              }}
            >
              Draft
            </li>
            <li
              onClick={() => {
                handleClosePopup();
              }}
            >
              My Drive
            </li>
            <li
              onClick={() => {
                handleClosePopup();
              }}
            >
              Categories
            </li>
          </ul>
        </Modal>
      )}
    </div>
  );
};

export default QuestionBankMenu;
