import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import PrivateRoute from '../../Utils/PrivateRoute/PrivateRoute';

import './DashboardLayout.css';
import { sideBarData } from './DashboardLayoutData';
import PointsIndicator from '../../components/PointsIndicator/PointsIndicator';
import { useSelector } from 'react-redux';
import { RootState } from '../../state';
import { UserI } from '../../types/AuthResponse';
import LazyLoad from 'react-lazyload';
import { IoCloseOutline } from 'react-icons/io5';
import { RxHamburgerMenu } from 'react-icons/rx';
import Modal from '../../components/Modal/Modal';
import { useAuth } from '../../context/AuthContext';
import { GET_USER_PROFILE } from '../../graphql/queries/getUser';
import { useQuery } from '@apollo/client';
import { setUser } from '../../state/userSlice';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import SpinnerLoader from '../../components/SpinnerLoader/SpinnerLoader';

const DashboardLayout = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user) as UserI;
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();
  const [activePath, setActivePath] = useState(location.pathname);

  // Fetch user data update as we enter Dashboard
  const {
    loading: userLoading,
    error: userError,
    data: userData,
  } = useQuery(GET_USER_PROFILE);

  const handleSideBarNav = (index: number, link: string) => {
    setIsMobileMenuOpen(false);

    if (index === sideBarData.length - 1) {
      // Call logout
      logout();
      navigate('/');
    }

    if (index !== sideBarData.length - 1) {
      navigate(link);
      setActivePath(link);
    }
  };

  useEffect(() => {
    if (userData) {
      dispatch(setUser(userData.getProfile));
    }

    if (userError) {
      toast.error(userError?.message || 'Unexpected errorr occured');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setActivePath(location.pathname);
  }, [location]);

  // Spinner Loads
  if (userLoading) return <SpinnerLoader loading={true} />;

  return (
    <PrivateRoute>
      <div className="dashboard">
        <div
          className={` ${
            isMobileMenuOpen ? 'position-show' : 'position-hide'
          }  sidebar `}
        >
          <div
            className="dashboard-navbar__logo"
            onClick={() => {
              setIsMobileMenuOpen(false);
              navigate('/');
            }}
          >
            <LazyLoad height={200} offset={100}>
              <img src="/images/Logo.png" alt="Logo" />
            </LazyLoad>
          </div>

          <>
            {sideBarData.map(({ title, icon, link }, index) => (
              <div
                className={` ${
                  index === sideBarData.length - 1 ? 'show-logout' : null
                } dashboard-menu`}
                key={index}
                onClick={() => {
                  handleSideBarNav(index, link);
                }}
              >
                <div>{icon(activePath === link ? '#349AFF' : 'none')}</div>
                <p
                  className={`${
                    activePath === link ? 'activeColor' : 'inactiveColor'
                  }`}
                >
                  {title}
                </p>
              </div>
            ))}
          </>
        </div>

        <div className="outlet">
          <div className="outlet__header">
            <div className="outlet__header-left">
              <div className="outlet__header-left__toggle-menu">
                {isMobileMenuOpen ? (
                  <IoCloseOutline
                    onClick={() => setIsMobileMenuOpen(false)}
                    className="outlet__header-left__toggle-menu-item-close "
                  />
                ) : (
                  <RxHamburgerMenu
                    onClick={() => setIsMobileMenuOpen(true)}
                    className="outlet__header-left__toggle-menu-item-open "
                  />
                )}
              </div>
              <div className="outlet__header-left__indicators">
                <PointsIndicator
                  title="Ranking"
                  image="/images/trophy.png"
                  point={user?.quizzer?.ranking}
                />

                <PointsIndicator
                  title="Points"
                  image="/images/coins.png"
                  point={user?.quizzer?.totalPoints}
                />
              </div>
            </div>

            <div className="outlet__header-right">
              <div
                className="dashboard__joinGame"
                onClick={() => navigate('/dashboard/multiplyer-invited')}
              >
                <p className="gradiat-blue-border">Join game</p>
              </div>
              <div className="dashboard__profile">
                <div className="dashboard__profile-image">
                  <img
                    src={user?.profileImage || '/images/guest.svg'}
                    alt="profile "
                  />
                </div>

                <div className="dashboard__profile-name">
                  <p>{user?.username}</p>

                  {/* <FaChevronDown /> */}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="main-outlet"> */}
          <Outlet />
          {/* </div> */}
        </div>
      </div>

      {/* Show only on small screen and when mobile menu is open */}

      <div
        className={`${
          isMobileMenuOpen ? 'show-modal' : 'remove-from-display'
        } `}
      >
        <Modal
          extraFunc={() => {
            setIsMobileMenuOpen(false);
          }}
        >
          <span></span>
        </Modal>
      </div>
    </PrivateRoute>
  );
};

export default DashboardLayout;
