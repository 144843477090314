import React from 'react';
import { PrivacyData } from './PrivacyData';
import NavBar from '../../components/NavBar/NavBar';
import Pitch from '../../components/Pitch/Pitch';
import Footer from '../../components/Footer/Footer';

const Privacy = () => {
  return (
    <div>
      <NavBar />

      <div className="terms__con">
        <div className="container terms__main">
          <div className="terms__main-header">
            <h3>PRIVACY POLICY</h3>
          </div>

          <div className="terms__main-body">
            {PrivacyData.split('\n').map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
          </div>
        </div>
      </div>

      <Pitch />
      <Footer />
    </div>
  );
};

export default Privacy;
