import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const PrivateRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();

  const location = useLocation();

  if (!isAuthenticated) {
    return (
      <Navigate
        to={`/login?${location?.pathname}`}
        state={{ from: location }}
        replace
      />
    );
  }

  return children;
};

export default PrivateRoute;
