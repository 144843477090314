import React, { useEffect, useState } from 'react';
import PrivateRoute from '../../Utils/PrivateRoute/PrivateRoute';
import './Trivia.css';
import { BackArrowSvg, CloseSvg } from '../../components/Svgs/svgsComponents';
import PointsIndicator from '../../components/PointsIndicator/PointsIndicator';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import QuizzTrivia from '../../components/QuizzTrivia/QuizzTrivia';
import { useQuery } from '@apollo/client';
import { GET_QUESTIONS } from '../../graphql/queries/getQuestions';
import { GetQuestionsData, Question } from '../../types/Questions';
import SpinnerLoader from '../../components/SpinnerLoader/SpinnerLoader';
import { useSelector } from 'react-redux';
import { RootState } from '../../state';
import { UserI } from '../../types/AuthResponse';
import { useDispatch } from 'react-redux';
import { updateQuizzer } from '../../state/userSlice';
import { toast } from 'react-toastify';

const Trivia = () => {
  const [iscanRefecth, setIsCanRefecth] = useState(false);

  const navigate = useNavigate();
  const { quizUUID, id, triviaType } = useParams();

  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state.user) as UserI;

  const location = useLocation();

  const { pathname, search } = location;

  // To set sub id to null if not pressent
  const subcatId = id === 'null' || id === undefined ? null : id;

  const backLink =
    subcatId === null ? '/dashboard' : `/dashboard/${triviaType}/${quizUUID}`;

  const [questions, setQuestions] = useState<Question[] | []>([]);

  const { error, loading, data, refetch } = useQuery<GetQuestionsData>(
    GET_QUESTIONS,
    {
      variables: { quizUuid: quizUUID, subcatId: subcatId },
    }
  );

  const handleUpdatePoint = (score: number) => {
    if (score > 0) {
      const newScore = user.quizzer.totalPoints + score;

      dispatch(updateQuizzer({ totalPoints: newScore }));
    }
  };

  useEffect(() => {
    if (data) {
      setQuestions(data?.getQuestions);
      setIsCanRefecth(true);
    }

    if (error) {
      toast.error(error?.message || 'Unexpected error occured');
    }

    if (iscanRefecth) {
      refetch();

      // Change back tto false refetch to avoid  multiple fetch
      setIsCanRefecth(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, pathname, data]);

  // Spinner Loads
  if (loading) return <SpinnerLoader loading={loading} />;

  return (
    <PrivateRoute>
      <div className="container trivia">
        <div className="trivia-points">
          <div onClick={() => navigate(backLink)} className="trivia-back-arrow">
            {BackArrowSvg()}
          </div>

          <PointsIndicator
            title="Points"
            image="/images/coins.png"
            point={user?.quizzer?.totalPoints}
          />
        </div>

        <div className="trivia-quiz">
          <QuizzTrivia
            questions={questions}
            updatePointUI={handleUpdatePoint}
            backLink={backLink}
          />
        </div>

        <div className="trivia-close" onClick={() => navigate('/dashboard')}>
          {CloseSvg()}
        </div>

        {error && <p>{error.message}</p>}
      </div>
    </PrivateRoute>
  );
};

export default Trivia;
