export const TermsData = `
Welcome to MedScroll, a medical education app designed for healthcare professionals, medical students, and individuals interested in medical trivia. MedScroll is owned and operated by Loopscribe Pty Ltd (ABN: 29 664555588), an Australian company ("Loopscribe" or "we"). These Terms of Use govern your use of the MedScroll app, website, and any associated services (collectively, the "Service"). By accessing or using the Service, you agree to be bound by these Terms of Use and our Privacy Policy.


1. GENERAL USE
The Service is intended for general informational and educational purposes only. The content provided through the Service is not a substitute for professional medical advice, diagnosis, or treatment. Always seek the advice of a qualified healthcare provider with any questions or concerns about your health or a medical condition.
You agree to use the Service only for lawful purposes and in a manner that does not infringe upon the rights of others or restrict or inhibit their use and enjoyment of the Service. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.


ELIGIBILITY
The Service is available to users who are at least 16 years of age or have obtained parental or legal guardian consent. By using the Service, you represent and warrant that you meet these eligibility requirements.

2. USER ACCOUNTS AND REGISTRATION
To access certain features of the Service, you may be required to create an account and provide certain personal information. This includes but is not limited to: Name, Email address, username, role, specialty and location. You agree to provide accurate, current, and complete information during the registration process and to update it as necessary to maintain its accuracy. MedScroll reserves the right to suspend or terminate your account if any information provided is inaccurate, not current, or incomplete. All the personal data we collect are managed in accordance with our privacy policy.


3. INTELLECTUAL PROPERTY
The Service, including all content, features, and functionality, is owned by MedScroll and is protected by intellectual property laws. You may not modify, reproduce, distribute, create derivative works, or publicly display any part of the Service without MedScroll's prior written consent.
User-generated content, such as quizzes or educational resources created by users, remains the property of the respective user. By submitting content to the Service, you grant MedScroll a non-exclusive, transferable, sub-licensable, royalty-free, and worldwide license to use, reproduce, modify, adapt, publish, translate, and distribute your content in connection with the Service.

4. THIRD-PARTY LINKS AND SERVICES
The Service may contain links to third-party websites or services that are not owned or controlled by MedScroll. MedScroll has no control over and assumes no responsibility for the content, privacy policies, or practices of any third-party websites or services. You acknowledge and agree that MedScroll shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such third-party websites or services.

5. SUBSCRIPTION, PAYMENT AND REFUND
Certain features of the Service are available through a paid subscription. If you choose to subscribe, you agree to pay the applicable fees and any taxes associated with your subscription. MedScroll reserves the right to modify or discontinue subscription plans at any time. Varied subscription will come into effect following the conclusion of the existing subscription period. You acknowledge and agree that where a request for the payment of the Subscription Fee is returned or denied, for whatever reason, by your financial institution or is unpaid for any other reason, then you are liable for any costs, including banking fees and charges, associated with the Subscription Fee.


MedScroll will only provide you with a refund of the Subscription Fee in the event they are unable to continue to provide the services or if the manager of MedScroll makes a decision, at its absolute discretion, that it is reasonable to do so under the circumstances. Where  this occurs, the refund will be in the proportional amount of the Subscription Fee that remains unused by the member.

6. USER CONDUCT
You agree to use the Service in compliance with all applicable laws and regulations. You further agree not to:
Use the Service for any illegal or unauthorized purpose.
Violate any intellectual property rights, including copyrights, patents, trademarks, or trade secrets.
Engage in any activity that could disrupt, negatively impact, or compromise the security or stability of the Service.
Upload, post, or transmit any content that is harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, or otherwise objectionable.
Impersonate any person or entity or misrepresent your affiliation with a person or entity.

7. DISCLAIMERS AND LIMITATION OF LIABILITY
The Service is provided "as is" and "as available" without warranties of any kind, whether express or implied. MedScroll disclaims all warranties, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement.
To the maximum extent permitted by law, MedScroll shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages arising from your use of the Service or any content therein.

8. TERMINATION
MedScroll reserves the right to terminate or suspend your access to the Service at any time, with or without cause, and without prior notice. Upon termination, your right to use the Service will immediately cease.

9. GOVERNING LAW AND DISPUTE RESOLUTION
These Terms of Use shall be governed by and construed in accordance with the laws of Queensland, Australia, without giving effect to any principles of conflicts of law. Any dispute arising out of or related to these Terms of Use or the Service shall be resolved exclusively in the courts of Queensland, Australia.

10. MODIFICATIONS
MedScroll reserves the right to modify or update these Terms of Use at any time. Your continued use of the Service after any such modifications constitutes your acceptance of the updated Terms of Use.


If you have any questions or concerns regarding these Terms of Use, please contact us at hello@loopscribe.com.



Last updated: 5th of April, 2024
`;
