import { gql } from '@apollo/client';

export const AI_GENERATE_QUIZ = gql`
  mutation GenerateAIquiz($quizPromptInput: QuizPromptInput!, $file: Upload) {
    generateAIquiz(quizPromptInput: $quizPromptInput, file: $file) {
      subtopic
      reference
      question
      options
      answer_details
      answer
    }
  }
`;
