import { gql } from '@apollo/client';

export const GET_USER_PROFILE = gql`
  query GetProfile {
    getProfile {
      country {
        code
        country
      }
      username
      userUUID
      specialty
      role
      quizzer {
        totalPoints
        ranking
        dailyStreak
      }
      subscription {
        credits
        generalTrivia
        identifier
        imageUploadPerQues
        isActive
        isTrialPeriod
        maxNumQuestion
        medicalTrivia
        multiplayerCapacity
        plan
        productId
        storage
        tokenBalance
        topUpCredits
        txtLimitPerOption
        txtLimitPerQuestion
      }
      stripeCustomer {
        stripeCustomerId
        stripeSub {
          stripeSubId
          stripeSubItemId
          stripeSubPlanName
          stripeSubStatus
        }
      }
      lastName
      isVerified
      firstName
      email
      profileImage
      phoneNumber
      state_city
      subspecialty
    }
  }
`;
