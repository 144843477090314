import React from 'react';

import './QuizBars.css';

const QuizzBars = ({
  bars,
}: {
  bars: { state: string; curIndex: number }[];
}) => {
  return (
    <div className="bars-container">
      {bars.length !== 0 &&
        bars.map((el, index) => (
          <div key={index} className="bar-item">
            {/* Large screen */}
            <div className="bar-large-screen">
              <p data-state={el.state} className={'bar-content'} />
            </div>
            {/* Mobile screen */}
            <div className="bar-mobile-screen">
              <p data-state={el.state} className={'bar-content'} />
            </div>
          </div>
        ))}
    </div>
  );
};

export default QuizzBars;
