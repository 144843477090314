import React, { Dispatch, useEffect, useState } from 'react';
import './QuizResult.css';
import {
  SubmitResponseData,
  SubmitResponseI,
} from '../../types/SubmitResponse';
import { QuestionReviewI } from '../../types/Questions';
import { SUBMIT_RESPONSE_MUTATION } from '../../graphql/mutatons/submitResponse';
import { useMutation } from '@apollo/client';
import UserProfilePic from '../UserProfilePic/UserProfilePic';
import { useLocation, useNavigate } from 'react-router-dom';
import { CircularCloseSvg } from '../Svgs/svgsComponents';
import Modal from '../Modal/Modal';
import QuizReview from '../QuizzReview/QuizReview';

import SpinnerLoader from '../SpinnerLoader/SpinnerLoader';
import { useDispatch } from 'react-redux';
import { updateQuizzer } from '../../state/userSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../state';
import { UserI } from '../../types/AuthResponse';

const QuizResult = ({
  setModalClickable,
  backLink,
}: {
  setModalClickable: Dispatch<React.SetStateAction<boolean>>;
  backLink: string;
}) => {
  const navigate = useNavigate();

  const location = useLocation();

  const user = useSelector((state: RootState) => state.user) as UserI;

  const dispatch = useDispatch();

  const [isOpenQuizReview, setIsOpenQuizReview] = useState(false);
  const [questions, setQuestions] = useState<QuestionReviewI[]>();

  // For changing the url and making the game start allover
  // on click of continue button
  const [t, setT] = useState(Date.now());

  const [submitResInput, setSubmitResInput] = useState<SubmitResponseI>();

  // Call api with subnitResponse
  const [
    submitResponse,
    { data: resData, loading: resLoading, error: resError },
  ] = useMutation<SubmitResponseData | undefined>(SUBMIT_RESPONSE_MUTATION);

  const convertReviewQuestionToSubmitResponse = (
    parsedReviewQuestion: QuestionReviewI[],
    totalScore: number
  ) => {
    const responses = parsedReviewQuestion.map((question) => {
      return {
        answer: question.answer,
        isCorrect: question.answer === question.optionSelected,
        isMissed: question.optionSelected === '',
        questionUUID: question.questionUUID.toString(),
        timeTaken: question.speed,
      };
    });

    setSubmitResInput({
      isQbank: false,
      point: totalScore,
      responses,
    });

    setT(Date.now());
  };

  const submit = async () => {
    try {
      await submitResponse({ variables: { submitResInput } });

      // To avoid clicking the modal background
      // and going back to previous page while result still loading
      setModalClickable(false);
    } catch (e) {
      console.error('Error submitting response:', e);

      // To avoid clicking the modal background
      // and going back to previous page while result still loading
      setModalClickable(false);
    }
  };

  useEffect(() => {
    // Check if localStorage Review Question exist
    if (localStorage.getItem('questionReview') && !submitResInput) {
      const reviewQuestion = localStorage.getItem('questionReview');

      const parsedReviewQuestion =
        reviewQuestion !== null && JSON.parse(reviewQuestion);

      const totalScore = parsedReviewQuestion.reduce(
        (sum: any, current: { score: number }) => sum + current?.score,
        0
      );

      convertReviewQuestionToSubmitResponse(parsedReviewQuestion, totalScore);

      setQuestions(parsedReviewQuestion);
    }

    if (submitResInput) {
      // Submit endpoint to ge result
      submit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitResInput]);

  useEffect(() => {
    if (resData) {
      // Update Ranking
      dispatch(
        updateQuizzer({ ranking: resData?.submitResponse?.userRanking })
      );
    }
  }, [dispatch, resData]);

  if (resLoading) return <SpinnerLoader loading={resLoading} />;
  return (
    <>
      <div className="quiz-result" onClick={(e) => e.stopPropagation()}>
        <div className="quiz-result__header">
          <div
            className="quiz-result-close-modal"
            onClick={(e) => {
              e.stopPropagation();
              navigate(backLink);
            }}
          >
            {CircularCloseSvg()}
          </div>
          <h3>Your score</h3>
          <UserProfilePic userImage={user?.profileImage} />

          <div className="quiz-result__header-contents">
            <p>{user?.username}</p>
            <p className="quiz-result__score">
              {resData?.submitResponse?.totalPoints}
            </p>
          </div>
        </div>

        <div className="quizz-result-btns">
          <button
            className="btn continue-btn"
            onClick={(e) => {
              e.stopPropagation();

              navigate(`${location.pathname}?t=${t}`);
            }}
          >
            Continue
          </button>

          <button
            className="btn review-btn"
            onClick={(e) => {
              e.stopPropagation();

              setIsOpenQuizReview(true);
            }}
          >
            Review question
          </button>
        </div>
      </div>
      {isOpenQuizReview && !resLoading && (
        <Modal
          extraFunc={() => {
            setIsOpenQuizReview(false);
          }}
        >
          <QuizReview
            questions={questions || []}
            setIsOpenQuizReview={setIsOpenQuizReview}
          />
        </Modal>
      )}

      {resError && <p>{resError.message}</p>}
    </>
  );
};

export default QuizResult;
