import React, { useEffect, useRef, useState } from 'react';

import './MultiplayerWaiting.css';
import { BackArrowSvg } from '../../components/Svgs/svgsComponents';
import PrivateRoute from '../../Utils/PrivateRoute/PrivateRoute';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { RootState } from '../../state';
import { UserI } from '../../types/AuthResponse';
import SpinnerLoader from '../../components/SpinnerLoader/SpinnerLoader';
import { PlayerScoreI } from '../../types/socket';
import { useSocketContext } from '../../context/socketContext';

const MultiplayerWaiting = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const user = useSelector((state: RootState) => state.user) as UserI;

  const [isGameStarted, setIsGameStarted] = useState('');

  const { code } = location.state;

  const { emit, on, off, handleSetCode, isSocketConnected, disConnectSocket } =
    useSocketContext();

  const displayedMessages = useRef<string[]>([]);

  const toastControlMessages = displayedMessages.current;

  const joinedMessage: string = 'joined the game';

  const leaveMessage: string = 'left the game';

  const handleLeaveGame = () => {
    disConnectSocket();
  };

  const [usersJoined, setUsersJoined] = useState<
    { name: string; image: string }[] | []
  >([]);

  // critical functions

  const handleListenLeaveGame = (message: string) => {
    const userName = message.replace(`${leaveMessage}`, '').trim();

    // Check if the message has already been displayed
    if (toastControlMessages.includes(userName)) {
      toast.warning(message);

      // Remove the message to the Set to avoid duplication
      const index = toastControlMessages.indexOf(userName);

      if (index !== -1) {
        // Remove the message from the array to avoid duplication
        toastControlMessages.splice(index, 1);
      }

      // trigger/call fetch-game to get update on game status
      emit('fetch-game', {});
    }
  };

  const handleListenJoinGame = (message: string) => {
    const userName = message.replace(`${joinedMessage}`, '').trim();

    // Check if the message has already been displayed
    if (!toastControlMessages.includes(userName)) {
      // Display the toast if the message is new
      toast.success(message);

      // Add the message to the Set to avoid duplication
      toastControlMessages.push(userName);

      // Trigger/call fetch-game to get update on game status
      emit('fetch-game', {});
    }
  };

  const handleListenFetchGame = (data: any) => {
    // Set Game state
    setIsGameStarted(data?.gameState);

    // Convert object to array
    const resultArray: PlayerScoreI[] = Object.values(data?.playerScores);

    const usersData = resultArray
      .filter((playerScore) => playerScore.userUUID !== user?.userUUID)
      .map((playerScore) => ({
        name: playerScore.firstName,
        image:
          playerScore.url === null ? '/images/Avatar.png' : playerScore.url,
      }));

    setUsersJoined(usersData);
  };

  const handleListenStartGame = () => {
    navigate('/multiplayer-quiz');
  };

  useEffect(() => {
    // const toastControlMessages = displayedMessages.current;

    // call to recieve/listen on who joined game
    on('joinGame', (message) => handleListenJoinGame(message));

    on('leaveGame', (message: any) => handleListenLeaveGame(message));

    // call to recieve/listen on game status changes
    on('fetchGame', (data) => handleListenFetchGame(data));

    // Hwne questtion is  satrted
    on('startGame', () => handleListenStartGame());

    return () => {
      off('joinGame', handleListenJoinGame);
      off('leaveGame', handleListenLeaveGame);
      off('fetchGame', handleListenFetchGame);
      off('startGame', handleListenStartGame);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [on]);

  useEffect(() => {
    // Stop user from joining game wwhen game already staarted
    if (isGameStarted === 'STARTED' || isGameStarted === 'ONGOING') {
      handleLeaveGame();

      toast.success('You cannot join,  game has already started');

      navigate('/dashboard');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGameStarted]);

  useEffect(() => {
    if (code) {
      //  function from sockect context
      handleSetCode(code);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  if (!isSocketConnected) return <SpinnerLoader loading={true} />;

  return (
    <PrivateRoute>
      <div className="container multiplayer-waiting-con">
        <div
          className="multiplayer-waiting__back"
          onClick={() => {
            handleLeaveGame();
            navigate(-1);
          }}
        >
          {BackArrowSvg()}
        </div>

        <div className="multiplayer-waiting-players-con">
          <p>Waiting for Players</p>

          <div className="multiplayer-waiting-players-con-item">
            {usersJoined.map(({ name, image }, index) => (
              <div className="user-joined-con-item" key={index}>
                <div className="user-joined-con-item__img-con ">
                  <img src={image} alt={name} />
                </div>

                <p>{name}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="multiplayer-waiting-content-host">
          <p>Waiting for Host...</p>
        </div>
      </div>
    </PrivateRoute>
  );
};

export default MultiplayerWaiting;
